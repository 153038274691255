import {
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
} from "./types";

export const showError = (message) => (dispatch) => {
  dispatch({
    type: SHOW_ERROR_MESSAGE,
    payload: { show: true, message },
  });
};

export const hideError = () => (dispatch) => {
  dispatch({
    type: HIDE_ERROR_MESSAGE,
    payload: { show: false },
  });
};

export const showMessage = (options) => (dispatch) => {
  const {
    type,
    message,
    duration = 6000,
    variant = "snackbar",
    redirect = null,
  } = options;
  dispatch({
    type: SHOW_MESSAGE,
    payload: { type, message, duration, variant, redirect },
  });
};

export const hideMessage = () => (dispatch) => dispatch({ type: HIDE_MESSAGE });
