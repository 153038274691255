import { createAsyncAction } from 'redux-promise-middleware-actions';
import settings from '../settings';

export const getBriefsNewsAPI = createAsyncAction('GET_NEWS', async () => {
    const headers = {
        'Content-Type': 'application/json'
    };
    const res = await fetch(`${settings.quotationUrlApi}new/brief`, { headers })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    return res;
});

export const getNewBySlugAPI = createAsyncAction('GET_NEWS_BY_SLUG', async (slug) => {
    const headers = {
        'Content-Type': 'application/json'
    };
    const res = await fetch(`${settings.quotationUrlApi}new/slug/${slug}`, { headers })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    return res;
});

const validateResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        throw exeptionCodeResponse();
    }
};

const exeptionCodeResponse = (error = 'error') => {
    return error;
};
