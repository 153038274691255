import { createStore, applyMiddleware, compose, } from 'redux';
import { persistStore, persistReducer, } from 'redux-persist';
//import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import rootReducer from '../reducers';
import { attachRiskTypeToAction } from '../utils/middlewares';

const initialState = {};

const middleware = [thunk, attachRiskTypeToAction];

// if (process.env.NODE_ENV !== 'production') middleware.push(createLogger());

const persistConfig = {
  key: 'root_cotizador_client',
  storage,
  whitelist: ['quote', 'lead', 'quoteHome']
};

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    }) : compose;

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(...middleware, promise),
  ),
);

const persistor = persistStore(store);

export { store, persistor };
