import React from 'react';
import { List, ListItem, ListSubheader, ListItemText, TextField } from '@material-ui/core';
import { useInputValue } from '../../hooks/useInputValue';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  listItem: {
    color: theme.palette.primary.dark,
    '&:hover': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main
    }
  },
  search: {
    marginBottom: '35px'
  }
}));

const QuoteList = ({ data, handleSelect }) => {
  const classes = useStyles();
  const lists = useInputValue('');
  const value = lists.value.toUpperCase();
  const dataList = lists.value !== '' ?
    data.content.filter(item => {
      if (item.name) {
        return item.name.toString().toUpperCase().indexOf(value) > -1;
      }
      return item.toString().toUpperCase().indexOf(value) > -1;
    }) : data.content;
  
  return (
    <List
      component="nav"
      className="quoteList"
      subheader={
        <ListSubheader className={classes.search} component="h2">
          <TextField
            label= {data.title}
            fullWidth
            color="primary"
            {...lists}
            />
        </ListSubheader>
      }
    >
      {
        dataList && dataList.length > 0 &&
        dataList.map((item, index) => {
          return (
            <ListItem className={classes.listItem} button key={index} onClick={() => handleSelect(data.val, item, data.type)}>
              <ListItemText primary={item.name ? item.name : item} />
            </ListItem>
          )
        })
      }
    </List>
  );
};

export default QuoteList;
