import React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const SnackMessage = ({ type, message, duration, open, handleClose }) => {
  return (
    <>
      {message && (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={open}
          onClose={handleClose}
          autoHideDuration={duration}
        >
          <Alert onClose={handleClose} severity={type}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SnackMessage;
