import {
    sendLinkQuotationMailAPI,
    getQuotationByIdAPI,
} from "../sevices/quoteService";
import {
    ADD_TAG_CAR,
    ADD_TAG_CONTACT,
    ADD_TAG_ACC,
    REMOVE_TAG_CAR,
    REMOVE_TAG_CONTACT,
    REMOVE_TAG_ACC,
    PATCH_MAIL,
    PATCH_DATA_LIST,
    PATCH_PLAN,
    REMOVE_ALL_QUOTE,
    SUCCESS_QUOTE,
    RECOVER_QUOTATION,
    RESET_QUOTATION,
    UPDATE_QUOTATION_SYNC,
    REMOVE_PLAN_DETAIL,
} from "../actions/types";

export const addTagCar = (tagCar) => (dispatch) => {
    dispatch({ type: ADD_TAG_CAR, payload: tagCar });
};

export const addTagContact = (tagContact) => (dispatch) => {
    dispatch({ type: ADD_TAG_CONTACT, payload: tagContact });
};

export const addTagAcc = (tagAcc) => (dispatch) => {
    dispatch({ type: ADD_TAG_ACC, payload: tagAcc });
};

export const removeTagCar = (val) => (dispatch) => {
    dispatch({ type: REMOVE_TAG_CAR, payload: val });
};

export const removeTagContact = (val) => (dispatch) => {
    dispatch({ type: REMOVE_TAG_CONTACT, payload: val });
};

export const removeTagAcc = (val) => (dispatch) => {
    dispatch({ type: REMOVE_TAG_ACC, payload: val });
};

export const patchMail = (mail) => (dispatch) => {
    dispatch({ type: PATCH_MAIL, payload: mail });
};

export const patchDataList = (dataList) => (dispatch) => {
    dispatch({ type: PATCH_DATA_LIST, payload: dataList });
};

export const patchPlan = (plan) => (dispatch) => {
    dispatch({ type: PATCH_PLAN, payload: plan });
};

export const cleanPlan = () => (dispatch) => {
    dispatch({ type: REMOVE_PLAN_DETAIL });
};

export const removeQuote = () => (dispatch) => {
    dispatch({ type: REMOVE_ALL_QUOTE });
};

export const successQuote = (value) => (dispatch) => {
    dispatch({ type: SUCCESS_QUOTE, payload: value });
};

export const sendLinkQuotationMail = (quotationId, mail, form) => {
    sendLinkQuotationMailAPI(quotationId, mail, form);
};

export const getQuote = (quotationId) => async (dispatch) => {
    await dispatch(getQuotationByIdAPI(quotationId));
};

export const recoverQuotation = (quotation) => ({
    type: RECOVER_QUOTATION,
    payload: quotation,
});

export const resetQuotation = () => (dispatch) => {
    dispatch({ type: RESET_QUOTATION });
};

export const updateQuotation = (quotation) => (dispatch) => {
    dispatch({ type: UPDATE_QUOTATION_SYNC, payload: quotation });
};
