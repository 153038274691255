import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { renderTextField } from './renderInputsForm';
import QuoteBottomButtons from '../quote/quoteBottomButtons';

const validate = values => {
    const errors = {};
    const requiredFields = ['homeAddress', 'postalCode'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });
    return errors;
};

const FormHome = ({ handleSubmit, pristine, submitting, handleContinue }) => {
    return (
        <form onSubmit={handleSubmit(handleContinue)}>
            <Field name="homeAddress" label="Dirección" component={renderTextField} />
            <Field name="postalCode" label="Código postal" component={renderTextField} />
            <p className="form__description">La dirección del hogar será validada durante la inspección previa.</p>
            <QuoteBottomButtons label="Siguiente" type="submit" disabled={pristine || submitting} />
        </form>
    );
};

export default reduxForm({ form: 'HomeForm', destroyOnUnmount: false, validate })(FormHome);
