import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: "uppercase",
    fontSize: 28,
    lineHeight: 1.2,
    letterSpacing: "0.5px",
    fontWeight: 600,
    marginBottom: "1.4rem",
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
      fontSize: 22,
    }
  },
  subtitle: {
    textTransform: "uppercase",
    fontSize: 24,
    lineHeight: 1.2,
    letterSpacing: "0.5px",
    fontWeight: 500,
    marginBottom: "1.4rem",
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    }
  },
  paragraph: {
    fontSize: 18,
    lineHeight: "25px",
    letterSpacing: "0.5px",
    fontWeight: 300,
    marginBottom: "2rem",
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    }
  },
  button: {
    width: "100%",
    padding: "19px 15px",
    maxWidth: 231,
  },

}));

const Terms = () => {
  const classes = useStyles();
  return (
    <>
      <>
        <Typography className={classes.title} variant="h1">
          Términos y condiciones
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          El presente documento contiene los Términos y Condiciones de Uso para
          acceder al sitio web propiedad de{" "}
          <strong>ASEGURADORA ABANK, S.A., SEGUROS DE PERSONAS</strong> en
          adelante<strong> Aseguradora ABANK </strong>y cualquiera de sus
          páginas en adelante, el “Sitio”, por lo cual se solicita que lea
          cuidadosamente los términos y condiciones detallados a continuación
          que le permitirán tener una mejor experiencia de compra de su seguro.
          Por medio de este documento se compromete a aceptar y respetar los
          siguientes términos, condiciones y políticas de uso por el simple
          acceso a cualquiera de las páginas que integran este Sitio.
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          La <strong>POLITICA DE PRIVACIDAD</strong> del Sitio hace parte
          integral de estos Términos y Condiciones, por lo tanto, siempre que se
          haga referencia al texto anterior se esta haciendo también referencia
          a la Política de Privacidad de Aseguradora ABANK y se aplica a la
          recolección y tratamiento de datos personales suministrado por los
          usuarios que acceden a este sitio, en particular los recopilados a
          través de formularios u otros documentos destinados a permitir el
          contacto con Aseguradora ABANK. El término información personal
          privada se refiere a la información acerca de usted que nosotros
          colectamos en conexión a los servicios y productos financieros que le
          proveemos. La información personal privada no incluye información que
          esté disponible a través de fuentes públicas tales como directorios
          telefónicos o archivos gubernamentales.
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          Los derechos de autor en las páginas y en las pantallas que muestran
          este Sitio, y en la información y material en su interior y en su
          disposición, es propiedad de Aseguradora ABANK, salvo que se indique
          lo contrario. Aseguradora ABANK se reserva el derecho de modificar
          discrecionalmente la información y los materiales contenidos en el
          Sitio al igual que los Términos y Condiciones, sin necesidad de previo
          aviso.
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          La cotización y contratación de los servicios que se ofrecen en este
          Sitio requiere que lea y acepte los siguientes Términos y Condiciones
          que a continuación se redactan. El servicio de contratación de pólizas
          de seguros que son ofrecidos en este sitio, están debidamente
          autorizadas por la Superintendencia del Sistema Financiero. Cuando le
          proporcionamos una cotización, confiamos en la exactitud de sus
          declaraciones, no obstante Aseguradora ABANK, tiene la facultad de
          verificar la información mediante el uso de recursos de terceros como
          por ejemplo informes médicos si son requeridos para adquirir una
          póliza. Esta Política es de carácter general, por lo que la
          información que en ella se proporciona puede ser complementada, total
          o parcialmente, por otras políticas, avisos o informaciones de
          carácter más específico que eventualmente se pongan a disposición en
          el contexto de ciertos tipos de tratamiento de datos personales.
        </Typography>
      </>
      <>
        <Typography className={classes.subtitle} variant="h2">
          USO Y RESTRICCIONES
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          El acceso o utilización del Sitio expresan la adhesión plena y sin
          reservas del usuario a los presentes Términos y Condiciones de Uso. A
          través del Sitio, el usuario se servirá y/o utilizará diversos
          servicios y contenidos (en lo sucesivo, los \"Servicios y
          Contenidos\", puestos a disposición de los Usuarios por Aseguradora
          ABANK, y/o por terceros proveedores de Servicios y Contenidos.
          Aseguradora ABANK tendrá el derecho modificar los Servicios y
          Contenidos del Sitio en cualquier momento y sin necesidad de previo
          aviso. En todo caso, las modificaciones o actualizaciones serán
          notificadas a través de este para que los usuarios estén plenamente
          informados de los cambios efectuados.
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          El Usuario admite que ningún tercero es responsable por perjuicios que
          se deriven directa o indirectamente de la existencia, uso, acceso,
          imposibilidad de uso o imposibilidad de acceso al presente Sitio o a
          cualquiera de sus vínculos o enlaces.
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          El usuario acepta que él es el único responsable por las decisiones
          que adopte con base en la información o en los materiales de esta
          página o de sus vínculos o enlaces.
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          El usuario reconoce que el contenido (incluidos entre otros: texto,
          software, música, sonido, fotografías, vídeo, gráficos u otro
          material) ubicado bien sea en la publicidad de los anunciantes o en la
          información producida comercialmente y distribuida de forma
          electrónica y presentada al usuario por Aseguradora ABANK, por sí
          mismo o por un tercero autorizado, está protegido por derechos de
          autor, marcas, patentes u otros bienes mercantiles o formas diferentes
          del derecho de propiedad.
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          El Usuario reconoce que su elegibilidad para ciertos productos y
          servicios está sujeta a la determinación final por parte de
          Aseguradora ABANK. El Usuario reconoce que algunos Servicios y
          Contenidos pueden ser utilizados solamente con posterioridad a su
          contratación, activación o registro previo por el usuario y/o mediante
          el pago de la prima, según se indique en las condiciones de
          contratación que se establezcan en la documentación respectiva.
          Aseguradora ABANK no garantiza la disponibilidad, continuidad y
          utilidad de la operación del Sitio y de los servicios y contenidos, en
          relación con cualquier actividad específica, independientemente del
          medio de acceso que utilice el usuario incluido la telefonía móvil.
          Aseguradora ABANK no será responsable por daño o pérdida alguna de
          cualquier naturaleza que pueda ser causado debido a la falta de
          disponibilidad o continuidad de operación del Sitio y/o de los
          servicios y contenidos.
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          El usuario es personalmente responsable de todo pedido que realice o
          de los cargos en los que incurra en las transacciones que realice en
          el Sitio. Al ingresar a este portal, el usuario adquiere el compromiso
          de suministrar información personal correcta y verdadera, así como
          proceder a la actualización de sus datos cada vez que se requiera. De
          igual forma, el usuario se obliga a notificar oportunamente a sus
          correspondientes entidades del sector financiero o cooperativo, la
          pérdida o robo de su tarjeta de crédito o de los demás instrumentos
          facilitados por las mismas para realizar transacciones, tales como
          tarjetas débito, claves personales, etc., con el fin de que tales
          instrumentos sean inhabilitados por las mismas. Se le prohíbe al
          usuario poner en, o transmitir a, o desde este portal cualquier
          material ilegal, amenazador, calumniador, difamatorio, obsceno,
          escandaloso, pornográfico o profano, o cualquier otro material que
          pudiera dar lugar a cualquier responsabilidad civil o penal en los
          términos de la ley.
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          Aseguradora ABANK no asume responsabilidad alguna por los daños y
          perjuicios de toda naturaleza que pudieran derivarse de la utilización
          inadecuada o negligente de los servicios y de los contenidos por parte
          del usuario, ni por los que puedan derivarse de la falta de veracidad,
          vigencia, exhaustividad y/o autenticidad de la información que el
          usuario proporciona a otros usuarios acerca de sí mismos y, en
          particular, aunque no de forma exclusiva, por los daños y perjuicios
          de toda naturaleza que puedan derivarse de la suplantación de la
          personalidad de un tercero efectuada por un usuario en cualquier clase
          de comunicación realizada a través de éste Sitio web.
        </Typography>
      </>
      <>
        <Typography className={classes.subtitle} variant="h2">
          PROPIEDAD INTELECTUAL
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          El usuario reconoce que los derechos de propiedad intelectual respecto
          de los Contenidos y los signos distintivos y dominios de las Páginas o
          el Sitio, que de manera enunciativa incluyen, los textos, el software,
          el sonido, las fotografías, enseñas, nombres comerciales, los videos,
          entre otros, así como los derechos de uso y explotación de estos,
          incluyendo su divulgación, publicación, reproducción, distribución y
          transformación, son propiedad exclusiva de Aseguradora ABANK. El
          Usuario no adquiere ningún derecho de propiedad intelectual por el
          simple uso o acceso de los Contenidos del Sitio y en ningún momento
          dicho uso será considerado como una autorización o licencia para
          utilizar los Contenidos con fines distintos a los que se contemplan en
          los presentes Términos y Condiciones de Uso y a los contratos
          respectivos.
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          Por lo antes expuesto, el Usuario deberá abstenerse, sin la previa
          autorización escrita de Aseguradora ABANK, de copiar, publicar,
          retransmitir o comercializar por cualquier medio, total o
          parcialmente, la información contenida en el Sitio Web, so pena de
          incurrir en las responsabilidades por violación de los derechos de
          autor, conforme a la legislación.
        </Typography>
      </>
      <>
        <Typography className={classes.subtitle} variant="h2">
          POLITICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          prioridades mediante el ingreso y uso al Sitio se esta autorizando el
          tratamiento de datos recolectados a Aseguradora ABANK, para que sus
          datos puedan ser almacenados y usados con el fin de lograr una
          eficiente comunicación durante el presente tramite y autoriza en los
          mismos términos, que dicha información pueda ser tratada con el fin de
          recibir información para responder de forma personalizada sus
          necesidades, inquietudes o solicitudes respecto de los productos y
          servicios cotizados o contratados. Las respuestas a datos sensibles:
          información de salud y financiera son facultativas y como propietario
          de la información el Usuario tiene derecho a conocer, actualizar,
          rectificar, o eliminar sus datos personales de forma gratuita. Así
          mismo tiene la obligación de corregir la información no actualizada o
          inexacta. La aceptación se dará específicamente cuando marque o señale
          la casilla de verificación. En el caso de algunas coberturas la
          celebración o la ejecución del contrato de seguro involucra o podrá
          involucrar el tratamiento de datos relativos a la salud del titular,
          tanto en el ámbito de la relación precontractual, para análisis del
          riesgo propuesto y fijación de las condiciones contractuales, como en
          el ámbito de la gestión de la relación contractual, utilización de la
          cobertura, y gestión de siniestros, para lo cual Aseguradora ABANK
          procederá al tratamiento de los datos personales para las finalidades
          indicadas mediante su consentimiento. Al registrar su correo al Sitio
          el Usuario acepta que desea recibir información relativa a la
          transacción de que se trate, es decir cotización o contratación de
          póliza de seguro solicitado, y a que nos comuniquemos y/o enviamos
          información a través de los medios de contacto que nos proporcione y/o
          tengamos registros ya sea, direcciones físicas, teléfono celular,
          fijo, o correo electrónico.
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          Por medio de la presente el Usuario otorga el consentimiento libre y
          expreso a Aseguradora ABANK, para realizar el tratamiento de datos
          personales y compartir información no confidencial con personas
          naturales o jurídicas que actúen como Intermediarios de Seguros,
          debidamente autorizados, con el fin de ser contactado para recibir
          información, ofertas comerciales, publicitarias, ofrecimiento de
          productos o ampliación integral de los servicios y productos cotizados
          y/o contratados. Aseguradora ABANK, está consciente de la importancia
          de proteger la privacidad de los menores de edad, por lo tanto, este
          Sitio no está diseñado ni está intencionalmente dirigido a menores de
          dieciocho años, no recolectará o mantendrá datos personales de
          cualquier persona menor de dieciocho años.
        </Typography>
      </>
      <>
        <Typography className={classes.subtitle} variant="h2">
          CONFIDENCIALIDAD
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          Toda la información que Aseguradora ABANK, recopile del Usuario será
          tratada con absoluta confidencialidad conforme las disposiciones
          legales aplicables. Aseguradora ABANK se obliga a mantener
          confidencial la información que reciba del Usuario conforme a las
          disposiciones legales aplicables en La República de El Salvador.
          Aseguradora ABANK no asume ninguna obligación de mantener confidencial
          de cualquier otra información que el Usuario le proporcione, que no
          ostente tal carácter. No será suministrada información a ninguna
          organización externa, a menos que contemos con su consentimiento, lo
          requiera la ley o le hayamos informado con anterioridad. En caso de
          que Aseguradora ABANK reciba el requerimiento de la información
          personal por parte de organismos gubernamentales, policiales,
          judiciales o nuestro ente regulador, Aseguradora ABANK estará
          facultado a compartirla sin autorización según lo exigido o permitido
          por la ley.
        </Typography>
      </>
      <>
        <Typography className={classes.subtitle} variant="h2">
          MODIFICACION DE LAS CONDICIONES DE USO
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          Aseguradora ABANK se reserva el derecho de modificar, cambiar o
          finalizar estos Términos y Condiciones en cualquier momento y bajo su
          discreción sin necesidad de notificar a los usuarios previamente, los
          cambios pueden incluir declaraciones o notificaciones que sustituyan a
          las vigentes. En consecuencia, debe leer atentamente los Términos y
          Condiciones de uso cada vez que pretenda utilizar el Sitio para
          mantenerse informado sobre cualquier cambio que se realice.
        </Typography>
      </>
      <>
        <Typography className={classes.subtitle} variant="h2">
          METODOS DE PAGO
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          Las formas de pago autorizadas desde el Sitio son las siguientes: a)
          Tarjetas de Crédito y Débito (Visa y MasterCard b) Bitcoin. Todas las
          formas de pago están sujetas a verificación y autorización por parte
          del proveedor de servicios de pago. Para el pago con tarjeta de
          crédito o débito el usuario deberá proporcionar los datos de su
          tarjeta autorizando que las primas que corresponden sean cargadas en
          forma automática. La transacción únicamente deberá ser realizada por
          el titular del medio de pago, en cumplimiento al Artículo 13-A de la
          Ley de protección al consumidor. Aseguradora ABANK, no se
          responsabiliza del manejo y uso de las tarjetas de crédito extraviadas
          o robadas que no hayan sido reportadas por el titular ya que no se
          puede negar la operación cuando el Banco emisor ha autorizado la
          transacción de pago. Todo cliente que desee realizar su pago por medio
          de Bitcoin puede seleccionar la billetera virtual de su preferencia.
          Aseguradora ABANK, no cobra ningún tipo de comisión por realizar la
          transacción por medio de Bitcoin, esta dependerá del tipo de billetera
          virtual y red que se utilice para realizar el pago. Las primas de
          seguros que este obligado a pagar el usuario al realizar la
          contratación le serán cargadas automáticamente en la tarjeta de débito
          o crédito que este disponga y será su responsabilidad mantener la
          disponibilidad de fondos a efecto de que puedan realizarse los cargos
          correspondientes.
        </Typography>
      </>
      <>
        <Typography className={classes.subtitle} variant="h2">
          PRODUCTO QUE PUEDE SER CONTRATADO Y ACREDITACION DE IDENTIDAD
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          Las operaciones que el Usuario podrá realizar incluyen, pero no están
          limitadas a la cotización y contratación de pólizas de seguros,
          emitidas por Aseguradora Abank. Para tramitar la solicitud de póliza
          digital o para ejercer alguno de los derechos especificados en la
          presente Política, el usuario deberá acreditar su identidad y
          titularidad, por lo tanto, acepta que Aseguradora ABANK solicite
          documentos y/o información que nos permitan validar dicha titularidad,
          evitando la pérdida, consulta, uso y/o acceso no autorizado o
          fraudulento por personas no autorizadas o que no tengan mandato legal
          para actuar en su nombre a la información personal de la que somos
          Responsables. Para poder atender su solicitud deberá facilitarnos en
          todo caso la información pertinente que nos permita acreditar la
          identidad y sea considerada legalmente valida, con el fin de evitar un
          escenario de homonimia y/o de duda razonable en relación con la
          identidad del Titular. Para acceder a las condiciones y coberturas de
          nuestro portafolio de productos puede acceder a través del siguiente
          enlace. Es entendido que el carácter electrónico de este servicio es
          personal, intransmisible, e intransferible y sustituye a la firma
          autógrafa de el cliente, por lo tanto, toda la información obtenida y
          toda operación realizada se entenderá que ha sido firmada, consentida
          y autorizada por el cliente, siendo la constancia emitida por
          Aseguradora ABANK, prueba absoluta y suficiente para demostrar la
          existencia de toda consulta, transacción u operación derivada del
          respectivo contrato.
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          La entrega de documentación del contrato de póliza será enviada única
          y exclusivamente como PDFs al correo electrónico que el cliente
          suministre al momento de la compra. Será responsabilidad del cliente
          agregar a su libreta de direcciones para garantizar la entrega de los
          documentos a la bandeja de entrada.
        </Typography>
      </>
      <>
        <Typography className={classes.subtitle} variant="h2">
          LEY Y JURISDICCIÓN APLICABLES
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          Las políticas y condiciones de acceso y uso aquí establecidas y los
          conflictos que puedan surgir respecto de su interpretación, uso,
          alcance y terminación se rigen por las leyes de la República de El
          Salvador y se someten a la jurisdicción de los tribunales de San
          Salvador.
        </Typography>
      </>
      <>
        <Typography className={classes.subtitle} variant="h2">
          DECLARACIONES
        </Typography>
        <Typography className={classes.paragraph} variant="body1">
          El Usuario declara y acepta expresamente lo siguiente: a{")"} Actúa de
          manera personal y no a nombre de terceros. b{")"} Se compromete a
          proporcionar cualquier información adicional y/o registros requeridos
          por Aseguradora ABANK o legislación aplicable. c{")"} Los datos
          suministrados en la solicitud son correctos y autoriza su comprobación
          y verificación d{")"} Que los ingresos declarados provienen de
          actividades enteramente licitas, por consiguiente, dichos fondos no se
          encuentran relacionados en ninguna circunstancia con hechos o
          actividades criminales relacionados con el narcotráfico, financiación
          del terrorismo ni delitos conexos con el lavado de dinero y activos.
          Por último, declara que estos Términos y Condiciones, y formulario
          electrónico ha sido completado por su persona y/o en su presencia y
          que la información provista es exacta y verdadera.
        </Typography>
      </>
    </>
  );
};

export default Terms;
