export const EN_INGRESO = "En Ingreso";
export const COTIZADA = "Cotizada";
export const SOLICITUD = "Solicitud";
export const DOCUMENT_EXPIRED = "Documento Vencido";
export const SCAN_FAIL = "Error de Escaner Facial";
export const DUI_FAIL = "Error de DUI";
export const PENDIENTE_DE_PAGO = "Pendiente De Pago";
export const PAGADA = "Pagada";
export const RESUMEN = "Resumen";
export const EMITIDA = "Emitida";
export const PENDIENTE_DE_APROBACION = "Pendiente De Aprobacion";
