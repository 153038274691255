import { useState, useEffect } from 'react';

export default function (groups) {
  const [tags, setTags] = useState(false);

  useEffect(() => {
    if (!tags && groups) {
      const obje = {};

      groups.forEach((group, index) => {
        obje[group.groupName] = {
          label: group.groupLabel,
          tagsArray: [],
          index
        };
      });

      setTags(obje);
    }
  }, [groups]);

  const handleChangeTag = (tag, groupName, groupLabel, fieldIndex, groupIndex, fieldName) => {
    let tagsArray = [
      ...tags[groupName].tagsArray,
      { label: tag, fieldIndex, groupIndex, group: groupLabel, name: groupName, fieldName},
    ];

    if (fieldName === 'mail') {
      tagsArray = [
        ...tags[groupName].tagsArray,
      ];      
    }

    setTags({
      ...tags,
      [groupName]: {
        ...tags[groupName],
        tagsArray
      }
    });
  }

  const handleChangeTagCancelled = (fieldIndexTag, tagName) => {
    const obje = {};
    let remove = false;

    groups.forEach((group, index) => {
      if (!!group.groupName) {
        let tagsArray = remove ? [] : tags[group.groupName].tagsArray;

        if (tagName === group.groupName) {
          tagsArray = tags[tagName].tagsArray.slice(0, fieldIndexTag);
          remove = true;
        }

        obje[group.groupName] = {
          label: group.groupLabel,
          tagsArray,
          index
        };
      }
    });

    setTags(obje);
  }

  return ({
    handleChangeTag,
    handleChangeTagCancelled,
    tags
  })
};
