import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import FormCar from '../../../components/form/formCar';
import FormHome from '../../../components/form/formHome';
import QuoteProgress from '../../../components/layout/lineProgress';
import SubTitle from '../../../components/layout/subTitle';
import { AUTOS, HOGAR, SALUD, VIDA, GASTOS_MEDICOS } from '../../../utils/risksTypes';
import { SOLICITUD } from '../../../utils/scores';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import { useQuote } from '../../../hooks/useQuote';
import { useHandleLeadSource } from '../../../hooks/useHandleLeadSource';
import LayoutLoading from '../../../components/layout/layoutLoading';
import LayoutError from '../../../components/layout/layoutError';

import '../../../styles/form.css';

const useStyles = makeStyles((theme) => ({
    appContainer: {
        paddingLeft: 8,
        paddingRight: 8,
        width: '100%'
    }
}));

const ProductDetailsComponent = ({ riskType }) => {
    riskType = riskType && riskType.toUpperCase();
    const classes = useStyles();
    const quote = useQuote(riskType);

    useHandleLeadSource({
        isNew: false,
        status: SOLICITUD,
        trackingStrings: ['Ingreso datos adicionales producto'],
        riskType
    });

    const handleContinue = () => {
        navigate('sumary');
    };

    const getProductDetailsForm = () => {
        switch (riskType) {
            case AUTOS:
                return (
          <>
            <SubTitle label="Sobre tu auto" />
            <FormCar handleContinue={handleContinue}/>
          </>
                );
            case HOGAR:
                return (
          <>
            <SubTitle label="Sobre tu hogar" />
            <FormHome handleContinue={handleContinue}/>
          </>
                );
            default:
                break;
        }
    };

    return (
        <Grid container justify="center" className={`${classes.appContainer}`}>
            { quote.loading && <LayoutLoading /> }
            { quote.error && <LayoutError /> }
            {!quote.loading && quote.quotation && quote.quotation.products &&
          <Grid item xs={12} sm={9} md={7} lg={6} className="cardAppContainer">
              <QuoteProgress width="50%" />
              {getProductDetailsForm()}
          </Grid>
            }
        </Grid>
    );
};

export default ProductDetailsComponent;
