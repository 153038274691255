import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    icon: {
        display: 'flex',
        cursor: 'pointer',
        paddingLeft: '10px'
    },
    iconContainer: {
    backgroundColor: props => props.type === 'primary' ? theme.palette.primary.main : '#4B4B4B',
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    cursor: 'pointer',
    opacity: '0.3'
  },
  iconContent: {
    cursor: 'pointer',
    marginLeft: '9px',
    position: 'absolute',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    color: props => props.type === 'primary' ? theme.palette.primary.main : '#4B4B4B'
  }
}));
const Ask = (props) => {

    const classes = useStyles(props);
    return (
        <div className={classes.icon} onClick={props.onClick}>
            <div className={classes.iconContainer}></div>
            <span className={classes.iconContent}>?</span>
        </div>
        
    );
}

export default Ask;