import React, { useEffect, useState } from 'react';
import { List, ListItem, ListSubheader, ListItemText, TextField, InputAdornment, Tooltip, IconButton, InputLabel } from '@material-ui/core';
import { useInputValue } from '../../../hooks/useInputValue';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForwardIos, Input } from '@material-ui/icons';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  listItem: {
    color: theme.palette.primary.dark,
    '&:hover': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down("sm")]: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark
      }
    }
  },
  search: {
    margin: '0px 16px 35px 16px'
  },
  input: {
    marginTop: '2rem !important'
  },
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: 16,
    marginBottom: 18,
    '& svg': {
      marginRight: 6,
      fontSize: 16
    },
    color: theme.palette.typography.main,
    '& label': {
      overflow: 'unset',
      maxWidth: '100%',
      whiteSpace: 'break-spaces'
    }
  }
}));

const QuoteListNumeric = ({
  data,
  handleSelect,
  label,
  isOptional,
  isSearchable,
  isGrid,
  quotationScreenOptions,
  handlePreSelect,
  preSelectedValue,
  handleNextButtonValid
}) => {
  const classes = useStyles();
  const [value, setValue] = useState('')
  const config = useSelector(state => state.config.data);

  useEffect(() => {
    if (isOptional) {
      if (quotationScreenOptions.nextButtonType === 'ext') {
        handleNextButtonValid(true);
      }
    }
  }, []);

  const dataList = () => {
    let index = data[0];
    const dataArray = [];

    while (index <= data[1]) {
      if (!!data[2] && (data[0] === index)) {
        dataArray.push(`${index}${data[2]}`)
      } else if (!!data[3] && (data[1] === index)) {
        dataArray.push(`${index}${data[3]}`)
      } else {
        dataArray.push(index);
      }

      index++;
    }

    if (value !== '') {
      return dataArray.reverse().filter(item => {
        if (item.name) {
          return item.name.toString().toUpperCase().indexOf(value.toUpperCase()) > -1;
        }
        return item.toString().toUpperCase().indexOf(value.toUpperCase()) > -1;
      })
    }

    return dataArray.reverse();
  }

  const handleChange = (value) => {
    quotationScreenOptions.nextButtonType === 'int' ? handleSelect(value) : handlePreSelect(value);
    setValue('');
    if (quotationScreenOptions.nextButtonType === 'ext') {
      handleNextButtonValid(true);
    }
  }

  const handleChangeValue = (evt) => {
    setValue(evt.target.value);
  }
  console.log('datalist', dataList());
  return (
    <div>
      <ListSubheader className={classes.search} component="h2">
        {(config.theme && !config.theme.labelPosition) && (
          <div className={classes.label}>
            <ArrowForwardIos color="primary" />
            <InputLabel id="external-input-label">{label}</InputLabel>
          </div>)}
        <TextField
          label={(config.theme && config.theme.labelPosition) ? label : null}
          fullWidth
          color="primary"
          value={value}
          onChange={handleChangeValue}
          InputProps={{
            endAdornment: (isOptional && quotationScreenOptions.nextButtonType === 'int') && (
              <InputAdornment position="end">
                <Tooltip title={'Siguiente'} arrow open placement="top">
                  <IconButton
                    aria-label="check input value"
                    onClick={() => handleChange(null)}
                    style={{
                      color: config.theme.primary.main
                    }}
                  >
                    <Input />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
            readOnly: !isSearchable,
            disabled: !isSearchable,
            classes: {
              root: (config.theme && config.theme.labelPosition) ? classes.input : null
            },
            margin: 'dense'
          }}
        />
      </ListSubheader>
      <List
        component="nav"
        className={`quoteList ${isGrid ? 'is__grid' : ''}`}
      >
        {
          dataList().map((item, index) => {
            return (
              <ListItem selected={item === preSelectedValue} className={classes.listItem} button key={index} onClick={() => handleChange(item)}>
                <ListItemText primary={item} />
              </ListItem>
            )
          })
        }
      </List>
    </div>
  );
};

export default QuoteListNumeric;
