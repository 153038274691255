import {
    GET_PAYMENT_STATUS_PENDING,
    GET_PAYMENT_STATUS_FULFILLED,
    GET_PAYMENT_STATUS_REJECTED,
    RESET_PAYMENT_METHODS
} from '../actions/types';

const initialState = {
    data: {},
    filteredData: {},
    loading: false,
    error: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PAYMENT_STATUS_PENDING:
            return {
                ...state,
                loading: true,
                error: false
            };
        case GET_PAYMENT_STATUS_FULFILLED:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false
            };
        case GET_PAYMENT_STATUS_REJECTED:
            return {
                ...state,
                data: {},
                loading: false,
                error: true
            };
        case RESET_PAYMENT_METHODS:
            return {
                ...state,
                data: {}
            };
        default:
            return state;
    }
}
