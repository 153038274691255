import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './stores/store';

import './App.css';
import './styles/components.css';
import './styles/quote.css';

import { globalHistory, Location } from '@reach/router';
import Routes from './routes';

const App = () => {
    const history = globalHistory;
    useEffect(() => {
        if (!window.location.pathname.includes('quote')) {
            Object.keys(sessionStorage)
                .filter(quote => quote.includes('quotation'))
                .forEach(quote => {
                    sessionStorage.removeItem(quote);
                });

            Object.keys(localStorage)
                .filter(quote => quote.includes('quotation'))
                .forEach(quote => {
                    localStorage.removeItem(quote);
                });

            Object.keys(localStorage)
                .filter(quote => quote.includes('cotizador'))
                .forEach(quote => {
                    localStorage.removeItem(quote);
                });
        }
    }, []);

    useEffect(() => {
        return history.listen((route) => {
            if (!route.location.pathname.includes('quote')) {
                Object.keys(sessionStorage)
                    .filter(quote => quote.includes('quotation'))
                    .forEach(quote => {
                        sessionStorage.removeItem(quote);
                    });

                Object.keys(localStorage)
                    .filter(quote => quote.includes('quotation'))
                    .forEach(quote => {
                        localStorage.removeItem(quote);
                    });

                Object.keys(localStorage)
                    .filter(quote => quote.includes('cotizador'))
                    .forEach(quote => {
                        localStorage.removeItem(quote);
                    });

            }

            if (
                (route.location.pathname.includes('quote/emitted') || route.location.pathname.includes('quote/completed')) &&
                route.action === 'POP'
            ) {
                window.history.go(1);
            }
        });
    }, [history]);

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Location>
                    <Routes />
                </Location>
            </PersistGate>
        </Provider>
    );
};

export default App;
