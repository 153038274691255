import {
  getPaymentMethodsAPI,
} from "../sevices/paymentMethodsService";
import { FILTER_PAYMENT_METHODS, RESET_PAYMENT_METHODS } from "../actions/types";

export const getPaymentMethods = () => async (dispatch) => {
  await dispatch(getPaymentMethodsAPI());
};

export const filterPaymentMethods = (filters, rules) => (dispatch) => {
  dispatch({ type: FILTER_PAYMENT_METHODS, payload: { filters, rules } });
};

export const resetPaymentMethods = () => (dispatch) => {
  dispatch({ type: RESET_PAYMENT_METHODS });
};
