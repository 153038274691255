import React, { Fragment, useEffect } from 'react';
import { Grid, Chip } from '@material-ui/core';
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import LayoutFooter from '../../components/layout/layoutFooter';
import '@brainhubeu/react-carousel/lib/style.css';
import '../../styles/home.css';
import ReactHtmlParser from "react-html-parser";
import { useStyles } from '../home'
import { getBriefsNews, getNewBySlug } from '../../actions/newsActions'
import LayoutLoading from '../../components/layout/layoutLoading';
import News from './news'

const NewOverview = ({ slug }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const news = useSelector(state => state.news);

    useEffect(() => {
        executeInitnew();

        if (!news.data.length) {
            executeInitnews();
        }
        window.scrollTo(0, 0)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const executeInitnew = async () => {
        if (slug) {
            await dispatch(getNewBySlug(slug));
        }
    };

    const executeInitnews = async () => {
        await dispatch(getBriefsNews());
    };

    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }

    return (
        <Fragment>
            <Grid container justify="space-around" alignItems="center" className={classes.containerCardsSection1}>
                <Grid item xs={12} sm={12} className={classes.mainOverview}>
                    {
                        !news.loading ? ReactHtmlParser(news.singleNew.content) : <LayoutLoading />
                    }
                    <br />
                    <span><strong>Publicado:</strong> {moment(news.singleNew.updatedAt).format('YYYY-MM-DD')}</span>
                    <Grid container className={classes.containerChips}>
                        {
                            !isEmpty(news.singleNew) && news.singleNew.tags && news.singleNew.tags.split(",").map((item, index) => <Chip
                                key={`${item}-${index}`}
                                label={item}
                                style={{ marginRight: 4 }}
                                color="primary"
                            />)
                        }
                    </Grid>

                </Grid>

            </Grid>
            <h3 className={classes.homeSectionTitle} id="newsletter" name="newsletter" >Contenido relacionado</h3>
            <News />
            <br />
            <LayoutFooter />
        </Fragment>
    )
}

export default NewOverview