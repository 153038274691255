import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import yearReducer from './yearReducer';
import brandReducer from './brandReducer';
import modelReducer from './modelReducer';
import versionReducer from './versionReducer';
import accesorieReducer from './accesorieReducer';
import leadReducer from './leadReducer';
import dynamicReducer from './dynamicReducer';
import quoteReducer from './quoteReducer';
import insuranceReducer from './insuranceReducer';
import { AUTOS, GASTOS_MEDICOS, HOGAR, SALUD, VIDA } from '../utils/risksTypes';
import { createNamedWrapperQuoteReducer } from '../utils/reducerWrappers';
import textCoverageReducer from './textCoverageReducer';
import newsReducer from './newsReducer';
import notificationReducer from './notificationReducer';
import clientInfoReducer from './clientInfoReducer';
import paymentMethodsReducer from './paymentMethodsReducer';
import payment from './paymentReducer';
import configReducer from './configReducer';
import templateReducer from './templateReducer';
import paymentLinksReducer from './paymentLinksReducer';
import siseAuthReducer from './siseAuthReducer';

export default combineReducers({
    siseAuth: siseAuthReducer,
    uiData: combineReducers({
        year: yearReducer,
        brand: brandReducer,
        model: modelReducer,
        version: versionReducer,
        accesorie: accesorieReducer
    }),
    dynamicReducer,
    quoteAllPlans: quoteReducer,/* 
    quote: createNamedWrapperQuoteReducer(quoteReducer, AUTOS),
    quoteHome: createNamedWrapperQuoteReducer(quoteReducer, HOGAR),
    quoteHealth: createNamedWrapperQuoteReducer(quoteReducer, SALUD),
    quoteLife: createNamedWrapperQuoteReducer(quoteReducer, VIDA),
    quoteMedicalExpenses: createNamedWrapperQuoteReducer(quoteReducer, GASTOS_MEDICOS), */
    lead: leadReducer,
    insurance: insuranceReducer,
    notification: notificationReducer,
    textCoverage: textCoverageReducer,
    news: newsReducer,
    form: formReducer,
    clientInfo: clientInfoReducer,
    paymentMethods: paymentMethodsReducer,
    config: configReducer,
    template: templateReducer,
    paymentLinks: paymentLinksReducer,
    payment
});
