import React from 'react';
import { List, ListItem, ListSubheader, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  listItem: {
    color: theme.palette.primary.dark,
    '&:hover': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const QuoteSelect = ({ item, handleSelect }) => {
  const classes = useStyles();
  return (
    <List
      component="nav"
      className="quoteList"
      subheader={
        <ListSubheader component="h2">
          {`El vehiculo posee ${item.code}?`}
        </ListSubheader>
      }
    >
      <ListItem className={classes.listItem} button onClick={() => handleSelect(true, item)}>
        <ListItemText primary="SI" />
      </ListItem>
      <ListItem button onClick={() => handleSelect(false, item)}>
        <ListItemText primary="NO" />
      </ListItem>
    </List>
  );
};

export default QuoteSelect;
