import {
  getPaymentStatusAPI,
} from "../sevices/pagaditoService";
import { RESET_PAYMENT_STATUS } from "../actions/types";

export const getPaymentStatus = (ern) => async (dispatch) => {
  //this is only for pagadito
  await dispatch(getPaymentStatusAPI(ern));
};

export const resetPaymentStatus = () => (dispatch) => {
  dispatch({ type: RESET_PAYMENT_STATUS });
};
