import React, { useEffect } from "react";
import {
    Button,
    Container,
    Grid,
    Step,
    StepLabel,
    Stepper,
    SvgIcon,
    Typography,
} from "@material-ui/core";
import { navigate } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import "../../../styles/done.css";
import {
    DotConnector,
    DotStepIcon,
} from "../../../components/styled-stepper/styleDotStepper";
import { generalsIcons } from "../../../utils/icons";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: theme.palette.primary.main,
        height: "calc(100vh - 57px)",
    },
    container: {
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
        justifyContent: "center",
        textAlign: "center",
    },
    icon: {
        marginRight: 16,
    },
    iconContainer: {
        width: "100%",
    },
    title: {
        fontSize: 34,
        lineHeight: "36px",
        color: "#02183A",
        fontWeight: 400,
        marginBottom: 32,
    },
    description: {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: "24px",
        color: "#02183A",
        marginBottom: 28,
    },
    button: {
        width: "100%",
        padding: "19px 15px",
        maxWidth: 231,
    },
    box: {
        borderRadius: 20,
        backgroundColor: "white",
        padding: "78px 47px",
        marginBottom: 50,
        [theme.breakpoints.down("xs")]: {
            padding: "30px 20px",
        },
    },
    icon: {
        width: 73,
        height: 73,
        fill: "transparent",
        marginBottom: 26,
    },
}));

const CongratsComponentNotApproved = () => {
    const classes = useStyles();
    const steps = 7;

    useEffect(() => {
        // clean all
        return () => {
            localStorage.clear();
            sessionStorage.clear();
        };
    }, []);

    return (
        <Container>
            <Stepper
                style={{ backgroundColor: "transparent" }}
                alternativeLabel
                activeStep={6}
                connector={<DotConnector />}
            >
                {Array(steps)
                    .fill("")
                    .map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={DotStepIcon} />
                        </Step>
                    ))}
            </Stepper>
            <Grid className={classes.box}>
                <SvgIcon className={classes.icon} viewBox="0 0 73 73">
                    {generalsIcons["congratulation"]}
                </SvgIcon>
                <Typography variant="h4" className={classes.title}>
                    ¡Hemos recibido tu información!
                </Typography>
                <Typography variant="h6" className={classes.description}>
                    Después de su revisión y análisis, nos comunicaremos
                    contigo.
                </Typography>
                <Typography variant="h6" className={classes.contact}>
                    Estamos a tu servicio. No dudes en contactarnos por los
                    siguientes medios de Servicio al Cliente:
                    <br />
                    <strong>WA:</strong> 7841-3519
                    <br />
                    <strong> Tel:</strong> 25218300 ext. 153/160
                    <br />
                    <strong>Correo:</strong> digital@aseguradoraabank.com
                    <br />
                    <strong>Emergencias y Autorizaciones 24/7:</strong>{" "}
                    2521-8304
                    <br /> Boulevard Merliot, Urbanización Jardines de la
                    Hacienda, Lote 5 y 6, Zona Comercial Z.C 5, Antiguo
                    Cuscatlán. La Libertad, El Salvador.
                </Typography>
                <Button
                    style={{ marginTop: 103 }}
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => navigate("/")}
                >
                    Entendido
                </Button>
            </Grid>
        </Container>
    );
};

export default CongratsComponentNotApproved;
