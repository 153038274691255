import React from 'react';
import { Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import { useStyles } from '../home';
import Utils from '../../utils/utils';

const CardNew = ({ data }) => {
    const classes = useStyles();

    return (
        <Card className={classes.cardNews}>
            <CardContent className={classes.cardNewsContent}>
                <Typography variant="h5" className={classes.cardTitle}>
                    {`${data.title.substring(0, 50)}...`}
                </Typography>
                <p className={classes.cardText}>
                    {Utils.getBriefText(data.content)}
                </p>
            </CardContent>
            <CardActions>
                <div className={classes.containerCardButton}>
                    <Link to={`/news/${data.slug}`}>
                        <Button variant="contained" color="primary" className="cardButton">
                            ver más
                        </Button>
                    </Link>
                </div>
            </CardActions>
        </Card>
    );
};

export default CardNew;
