import { createAsyncAction } from 'redux-promise-middleware-actions';
import { validateResponse, exeptionCodeResponse } from '../utils/index';
import settings from '../settings';

export const getTextCoverageAPI = createAsyncAction(
    'GET_TEXT_COVERAGE',
    async (coverageId) => {
        const headers = {
            'Content-Type': 'application/json'
        };
        const res = await fetch(`${settings.quotationUrlApi}textCoverage/${coverageId}`, {
            headers
        })
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

export const getAllTextCoverageAPI = createAsyncAction(
    'GET_TEXT_COVERAGE',
    async () => {
        const headers = {
            'Content-Type': 'application/json'
        };
        const res = await fetch(`${settings.quotationUrlApi}textCoverage`, {
            headers
        })
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

export const addTextCoverageAPI = createAsyncAction(
    'ADD_TEXT_COVERAGE',
    async (token, bodyReq, dispatch) => {
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const body = JSON.stringify(bodyReq);
        const res = await fetch(`${settings.quotationUrlApi}textCoverages`, {
            method: 'POST',
            headers,
            body
        })
            .then((response) => {
                return validateResponse(response);
            })
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

export const updateTextCoverageAPI = createAsyncAction(
    'UPDATE_TEXT_COVERAGE',
    async (token, bodyReq, dispatch) => {
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const body = JSON.stringify(bodyReq);
        const res = await fetch(`${settings.quotationUrlApi}textCoverages/update`, {
            method: 'PUT',
            headers,
            body
        })
            .then((response) => {
                return validateResponse(response);
            })
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

export const deleteTextCoverageAPI = createAsyncAction(
    'DELETE_TEXT_COVERAGE',
    async (token, textCoverageId) => {
        const headers = { Authorization: `Bearer ${token}` };
        const res = await fetch(
            `${settings.quotationUrlApi}textCoverages/${textCoverageId}`,
            {
                method: 'DELETE',
                headers
            }
        )
            .then((response) => ({
                status: validateResponse(response),
                id: textCoverageId
            }))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);
