import React, { useState } from 'react';
import { Grid, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { patchMail } from '../../../actions/quoteActions';
import { postSaveQuotation } from '../../../actions/serviceActions';
// import { postLead } from '../../../actions/leadActions';
import { useInputValue } from '../../../hooks/useInputValue';
import { navigate } from '@reach/router';
import { HOGAR } from '../../../utils/risksTypes';

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(2)
    },
    card: {
        backgroundColor: '#FFFFFF',
        borderRadius: 4,
        color: 'rgba(0, 0, 0, 0.87)',
        marginBottom: '32px !important',
        overflow: 'hidden',
        padding: 16,
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        [theme.breakpoints.up('sm')]: {
            marginLeft: 8,
            marginRight: 8
        }
    },
    appContainer: {
        paddingLeft: 8,
        paddingRight: 8,
        width: '100%'
    }
}));

const HomeInfoComponent = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [mailError, setMailerror] = useState({ helperText: '', error: false });
    const { loading, error, mail } = useSelector(state => state.quoteHome);
    const mailInput = useInputValue(mail);

    const goToNextPage = async () => {
        dispatch(patchMail(mailInput.value));

        const quoteBody = {
            person: {
                mail: mailInput.value
            },
            riskType: {
                name: HOGAR
            }
        };
        await dispatch(postSaveQuotation(quoteBody, HOGAR));
        await navigate('proposal');
    };

    const changeValidFormat = (e) => {
        if (!e.target.value) {
            setMailerror({ helperText: 'El campo no puede estar vacio', error: true });
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
            setMailerror({ helperText: 'Correo no valido', error: true });
        } else {
            setMailerror({ helperText: '', error: false });
        }
    };

    return (
        <Grid container justify="center" className={`${classes.appContainer}`}>
            <Grid item xs={12} sm={9} md={7} className={classes.card}>
                <h2 className="titleSection">Hogar</h2>
                <Grid container justify="center">
                    <TextField
                        label="Email"
                        type="email"
                        placeholder=""
                        style={{ margin: 8 }}
                        fullWidth
                        error={mailError.error}
                        helperText={mailError.helperText}
                        value={mailInput.value}
                        onChange={(e) => { mailInput.onChange(e); changeValidFormat(e); }}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => goToNextPage()}
                        disabled={!mailInput.value || mailError.error || loading || error}
                    >
            Siguiente
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HomeInfoComponent;
