import React, { useState } from 'react';
import { navigate } from "@reach/router";
import { useSelector, useDispatch } from 'react-redux';
import { patchMail } from '../../actions/quoteActions';
import { postSaveQuotation } from '../../actions/serviceActions';
import { Grid, TextField, Button } from '@material-ui/core';
import { useInputValue } from '../../hooks/useInputValue';
import LayoutError from '../../components/layout/layoutError';
import { postLead } from '../../actions/leadActions';
import { AUTOS } from '../../utils/risksTypes';
import { EN_INGRESO } from '../../utils/scores';
import { toTitleCase } from '../../utils';

const QuoteInputs = () => {
  const dispatch = useDispatch();
  const quoteRedux = useSelector(state => state.quote);
  const mail = useInputValue(quoteRedux.mail);
  const [mailError, setMailerror] = useState({ helperText: '', error: false });
  
  const changeValidFormat = (e) => {
    if (!e.target.value)
      setMailerror({ helperText: 'El campo no puede estar vacio', error: true })
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value))
      setMailerror({ helperText: 'Correo no valido', error: true });
    else
      setMailerror({ helperText: '', error: false });
  }
  
  const goToNextPage = async () => {
    dispatch(patchMail(mail.value));

    const accesories = quoteRedux.tagsAcc ? 
            quoteRedux.tagsAcc.map((acc) => {
              return { "name": acc.label };
            }) : [];

    const quoteBody = {
      "person": {
        "mail": mail.value,
        "age": quoteRedux.tagsContact[0].label,
        "zone": quoteRedux.tagsContact[1].label
      },
      accesories,
      "brand": {
        "name": quoteRedux.tagsCar[1].label
      },
      "model": {
        "name": quoteRedux.tagsCar[2].label
      },
      "version": {
        "name": quoteRedux.tagsCar[3].label
      },
      "riskType": {
        "name": AUTOS
      },
      "year": quoteRedux.tagsCar[0].label
    }
    
    await dispatch(postSaveQuotation(quoteBody, AUTOS));
    await navigate('proposal');
  };

  return (
    <Grid container style={{ justifyContent: 'center'}} className="contentForm">
      { quoteRedux.error && <LayoutError /> }
      <TextField
        label="Email"
        placholder=""
        type="email"
        className="quoteInput"
        fullWidth
        error={mailError.error}
        helperText={mailError.helperText}
        value={mail.value}
        onChange={(e) => { mail.onChange(e); changeValidFormat(e)}}
      />
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => goToNextPage()}
        disabled={mail.value && !mailError.error ? false : true} 
        >
          COTIZAR
        </Button>
    </Grid>
  );
};

export default QuoteInputs;
