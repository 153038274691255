import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  makeStyles,
  Typography,
  CircularProgress,
  Snackbar,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  Menu,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  Paper,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Check,
  Close,
  ExpandMore,
  GetApp,
  Help,
  Info,
  Mail,
  MoreVert,
  Send,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import axios from "axios";
import React, { useState, Fragment } from "react";
import settings from "../../settings";
import ModalDetails from "../modal/modalDetails";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Handlebars from "handlebars";
import { downloadPDF } from "../../sevices/quoteService";
import Utils from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
  planContainer: {
    width: '100%',
    borderRadius: 20,
    backgroundColor: "#fff",
    "& hr": {
      border: "0.5px solid #E0E0E0",
      margin: "32px 8px 0px 8px",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  optionsContainer: {
    maxWidth: 160,
    margin: "1rem 0",
    '&>div': {
      color: 'rgba(114, 102, 88)'
    }
  },
  planHeader: {
    position: "relative",
    padding: "22px 0px",
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    fontSize: 20,
    lineHeight: "24px",
    letterSpacing: 0.15,
    textAlign: "center",
    borderRadius: "20px 20px 0px 0px",
    borderBottom: "0.5px solid #E0E0E0",
    "&.card-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      "& button": {
        color: "#fff",
      },
    },
  },
  icon: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  planBody: {
    textAlign: "center",
    "& h3": {
      fontWeight: 300,
      fontSize: 48,
      lineHeight: "24px",
      letterSpacing: 0.15,
      color: theme.palette.secondary.main,
      margin: "26px 0px 0px 0px",
      "& sub": {
        fontSize: 16,
      },
    },
    "& small": {
      fontWeight: 300,
      fontSize: 16,
      lineHeight: "24px",
      letterSpacing: 0.15,
      color: "#4B4B4B",
      marginTop: 28,
    },
  },
  planCoverages: {
    display: "flex",
    flexDirection: "column",
    width: "100%",

    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "8px 0px",
      fontSize: 13,
      lineHeight: "15px",
      letterSpacing: 0.15,
      color: "#4B4B4B",
      "&.tax-group": {
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        paddingBottom: "8px",
        "&:last-child > p": {
          backgroundColor: theme.palette.primary.main,
          color: "#fff",
        },
      },
      "& span": {
        display: "inline-flex",
        alignItems: "center",
        maxWidth: 210,
        textAlign: "left",
        "& i": {
          marginRight: 4,
          "& svg.available": {
            color: "green",
          },
          "& svg.not-available": {
            color: "red",
          },
        },
      },
      "& p": {
        display: "flex",
        alignItems: "center",
        "& button": {
          textTransform: 'capitalize',
          fontWeight: 600,
          textDecoration: 'underline',
          color: theme.palette.primary.main
        },
        "& i": {
          marginLeft: 5,
          cursor: "pointer",
          width: 16,
          height: 16,
          backgroundColor: theme.palette.primary.main,
          borderRadius: "50%",
          "& svg": {
            width: 16,
            height: 16,
            fill: "#fff",
          },
        },
      },
      "&.tax-group p": {
        margin: 0,
        borderRadius: 16,
        padding: "5px 10px",
      },
      "& b": {
        fontWeight: 600,
        fontSize: 12,
        maxWidth: 125,
        textAlign: "right",
      },
    },
  },
  planFooter: {
    padding: "32px 24px",
    "& button": {
      width: "100%",
      height: 54,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const StyledAccordion = withStyles({
  root: {
    margin: "0px !important",
    boxShadow: "none !important",
    "& .MuiAccordionDetails-root": {
      padding: "0px 16px",
    },
  },
})(Accordion);

const StyledAccordionSummary = withStyles((theme) => ({
  root: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: 0.15,
    color: theme.palette.secondary.main,
    textAlign: "left",
    flexDirection: "row-reverse",
  },
  content: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  expanded: {
    margin: "0px !important",
    minHeight: "54px !important",
  },
  expandIcon: {
    color: theme.palette.secondary.main,
    margin: 0,
    padding: "0px 12px",
  },
}))(AccordionSummary);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledMenuList = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MenuList);

const ProposalCard = ({
  product,
  index,
  isSelected,
  handleNext,
  products,
  quotation,
  optionsByQuote,
  insurance,
  handleSelect
}) => {
  const classes = useStyles();
  let coverageGroups = [];
  const coverages = [];
  const template = useSelector((state) => state.template);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedCoverage, setSelectedCoverage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const updatePlanAndhandleNext = () => {
    handleSelect(index, product);
    handleNext(product._id);
  }

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowSuccess = () => setShowSuccess(false);

  const handleCloseDetails = () => {
    setShowDetails(false);
    setSelectedCoverage({});
  };

  const handleSelectCoverage = (coverage) => {
    setSelectedCoverage(coverage);
    setShowDetails(true);
  };

  if (products) {
    products.forEach((product) => {
      product.coverages.forEach((coverage) => {
        const alreadyAdded = coverages.find(
          (cv) => cv.coverageId === coverage.coverageId
        );
        if (!alreadyAdded) {
          coverages.push(coverage);
        }
      });
    });
  }
  // console.log(quotation);
  coverages.forEach((cv, i) => {
    // console.log(cv);
    const groupIndex = coverageGroups.findIndex(
      (_cv) => _cv.id === cv.coverageGroupId
    );
    const productCoverage = product.coverages.find(
      (coverage) => coverage.coverageId === cv.coverageId
    );
    if (groupIndex !== -1) {
      if (cv.coverageGroupName === "Coberturas Adicionales") {
        const hasField = coverageGroups[groupIndex].coverages.findIndex(
          (c) => c.id === "rcExceso"
        );
        if (hasField !== -1) {
          coverageGroups[groupIndex].coverages = coverageGroups[
            groupIndex
          ].coverages.concat({
            id: "rcExceso",
            name: "Responsabilidad Civil en Exceso",
            value: `$${new Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(quotation.quotationScreenValues.rcExceso)}`,
            available: true,
            content: null,
          });
        }
      }

      coverageGroups[groupIndex].coverages = coverageGroups[
        groupIndex
      ].coverages.concat({
        id: cv.coverageId,
        name: cv.name,
        value: productCoverage && productCoverage.value,
        available: !!productCoverage,
        content: cv.content,
      });
    } else {
      coverageGroups = coverageGroups.concat({
        id: cv.coverageGroupId,
        name: cv.coverageGroupName,
        coverages: [
          {
            id: cv.coverageId,
            name: cv.name,
            value: productCoverage && productCoverage.value,
            available: !!productCoverage,
            content: cv.content,
          },
        ],
      });
    }
  });

  const taxGroup = {
    name: "Prima",
    id: "tax",
    coverages: quotation.quotationScreenValues.calculationType === 'libra' ? [
      {
        name: "Prima Neta",
        value: `$${new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(product.netPremium)}`,
      }
    ].concat(product.tax).concat({
      name: "Prima total anual",
      value: `$${new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(product.premium)}`,
    }) : [
      {
        name: "Tasa",
        value: `${product.averageRate}%`
      },
      {
        name: "Prima Neta",
        value: `$${new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(product.netPremium)}`,
      },
      {
        name: "S.C.V.S",
        value: `$${new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(product.SCVS)}`,
      },
      {
        name: "S.S.C",
        value: `$${new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(product.SSC)}`,
      },
      {
        name: "Derechos de emisión",
        value: `$${new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(product.taxeEmission)}`,
      },
      {
        name: "I.V.A",
        value: `$${new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(
          parseFloat(
            product.IVA && product.IVA2
              ? product.IVA + product.IVA2
              : product.IVA
          )
        )}`,
      },
      {
        name: "Prima total anual",
        value: `$${new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(product.totalPremiumWithTaxes)}`,
      },
    ],
  };

  const arraySort = (a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    // a must be equal to b
    return 0;
  };

  const sendProposalByEmail = async () => {
    const quotationData = { ...quotation };
    quotationData.creationDate = new Date(
      quotation.creationDate
    ).toLocaleDateString();

    const dataToPdf = {
      name: "Cotizador Oriente",
      templateData: {
        quotation: quotationData,
        plan: product,
        person: {},
        validity: {},
      },
    };
    const templates = [].concat(dataToPdf);
    const headers = { "Content-Type": "application/json" };

    const emailTemplate = Handlebars.compile(template.data.newTemplate);
    const dataEmail = { name: quotationData.quotationScreenValues.nombreAsegurado };

    setLoading(true);
    await axios({
      method: "post",
      headers,
      url: `${settings.quotationUrlApi}contact/quotation/send`,
      data: {
        templates,
        emailDataToSend: {
          subject: "Plan de cotizacion generado",
          message: emailTemplate(dataEmail),
          email: quotation.lead,
          filename: "plan.pdf",
        },
      },
    });
    setLoading(false);
    setShowSuccess(true);
  };

  const downloadProposal = async () => {
    const quotationData = { ...quotation };
    quotationData.creationDate = new Date(
      quotation.creationDate
    ).toLocaleDateString();

    const dataToPdf = {
      name: "Cotizador Oriente",
      templateData: {
        quotation: quotationData,
        plan: product,
        person: {},
        validity: {},
      },
    };
    setLoading(true);
    const fileInfo = await downloadPDF(dataToPdf);
    console.log(fileInfo);
    setLoading(false);
    Utils.createAndDownload(fileInfo.blob, fileInfo.data.name);
  }

  const removeTags = (str) => {
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  const price =
    quotation.quotationScreenValues.calculationType === "directRatePremium" || quotation.quotationScreenValues.calculationType === 'libra'
      ? product.premium
      : product.totalPremiumWithTaxes;

  console.log(insurance);
  return (
    <div className={classes.planContainer}>
      <div>
        <div
          className={`${classes.planHeader} ${isSelected ? "card-selected" : ""
            }`}
        >
          <span>{product.name}</span>
          {!insurance?.businessName?.includes("Libra") &&
            <IconButton
              className={classes.icon}
              color="primary"
              onClick={handleClick}
            >
              <MoreVert />
            </IconButton>}
          <Popper open={open} anchorEl={anchorEl} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <StyledMenuList
                      autoFocusItem={open}
                      id={`proposal_card_menu_${product._id}`}
                    >
                      <MenuItem
                        onClick={() => {
                          sendProposalByEmail();
                          handleClose();
                        }}
                      >
                        {loading ? (
                          <CircularProgress color="primary" />
                        ) : (
                          <Send
                            style={{
                              transform: "rotate(-45deg)",
                              marginRight: 10,
                            }}
                          />
                        )}{" "}
                        Enviar
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          downloadProposal();
                          handleClose();
                        }}
                      >
                        {loading ? (
                          <CircularProgress color="primary" />
                        ) : (
                          <GetApp style={{ marginRight: 10 }} />
                        )}
                        Descargar
                      </MenuItem>
                    </StyledMenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          {/* <StyledMenu
                        id={`proposal_card_menu_${product._id}`}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        keepMounted={false}
                    >
                        <MenuItem onClick={() => {
                            sendProposalByEmail();
                            handleClose()
                        }}>{loading ? <CircularProgress color="primary" /> : <Send style={{ transform: 'rotate(-45deg)', marginRight: 10 }} />} Enviar</MenuItem>
                    </StyledMenu> */}
          {/* <Tooltip title="Enviar por correo esta cotizacion" placement="top">
                        <IconButton className={classes.icon} onClick={sendProposalByEmail} color="primary">{loading ? <CircularProgress color="primary" /> : <Mail />}</IconButton>
                    </Tooltip> */}
        </div>
        <div className={classes.planBody}>
          <p>
            {optionsByQuote && optionsByQuote.whatValueFirst == "year" ? (
              <Fragment>
                <h3>
                  $
                  <b>
                    {new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(price)}
                  </b>
                  <sub>/año</sub>
                </h3>
                {/* <small>${new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((price / 12))}/mes</small> */}
              </Fragment>
            ) : (
              <Fragment>
                {/* <h3>$<b>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((price / 12))}</b><sub>/mes</sub></h3> */}
                <small>
                  $
                  {new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(price)}
                  /año
                </small>
              </Fragment>
            )}
          </p>
          {product.quotas && (
            <FormControl className={classes.optionsContainer}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={3}
              >
                {Object.keys(product.quotas).map((quota, index) => (
                  <MenuItem
                    value={quota}
                  >{`${quota} cuotas $${new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(product.quotas[quota])}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {(quotation.quotationScreenValues.calculationType === "libra" ? [
            { name: 'General', coverages: product.coverages.map(cv => ({ ...cv, value: cv.Prima, available: true })) }
          ] : coverageGroups)
            .sort(arraySort)
            .concat(taxGroup)
            .filter((g) => {
              if (g.id === "tax" && !product.netPremium) {
                return false;
              }
              return true;
            })
            .map((coverageGroup, index) => (
              <StyledAccordion defaultExpanded={coverageGroup.name === "Prima"}>
                <StyledAccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls={`proposal_panel_${index}`}
                  id={`proposal_panel_${index}`}
                >
                  <span>{coverageGroup.name}</span>
                </StyledAccordionSummary>
                <AccordionDetails>
                  <div className={classes.planCoverages}>
                    {coverageGroup.coverages.map((cv) => {
                      return coverageGroup.id === "tax" ? (
                        <div className="tax-group">
                          <span>{cv.name}</span>
                          <p>
                            <b>{cv.value}</b>
                          </p>
                        </div>
                      ) : (
                        <div>
                          <span>
                            <i>
                              {cv.available ? (
                                <Check style={{ color: "rgba(87, 182, 178)" }} />
                              ) : (
                                <Close style={{ color: "#EB5757" }} />
                              )}
                            </i>
                            {cv.name}
                          </span>
                          <p>
                            {(cv.content && removeTags(cv.content) !== '') ? (
                              <Button onClick={() => handleSelectCoverage(cv)}>Ver detalle</Button>
                            ) : (
                              <b>{cv.available ? `${cv.value}` : "-"}</b>
                            )}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </StyledAccordion>
            ))}
          {/* <div className={classes.planCoverages}>
            {coverages.map(cv => (
                <div>
                <span><i>{cv.available ? <Check style={{ color: '#6FCF97' }} /> : <Close style={{ color: '#EB5757' }} />}</i>{cv.name}</span>
                <b>{cv.available ? `${cv.value}` : '-'}</b>
                </div>
            ))}
            </div> */}
        </div>
      </div>
      <div>
        <hr />
        <div className={classes.planFooter}>
          {insurance &&
            insurance.businessTitle &&
            !insurance.businessTitle.includes("Oriente") && (
              <Button variant="contained" onClick={updatePlanAndhandleNext} color="primary">
                Siguiente
              </Button>
            )}
        </div>
      </div>
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={handleShowSuccess}
      >
        <Alert onClose={handleShowSuccess} severity="success">
          ¡Plan enviado con exito!
        </Alert>
      </Snackbar>
      <ModalDetails
        open={showDetails}
        handleClose={handleCloseDetails}
        coverage={selectedCoverage}
      />
    </div>
  );
};

export default ProposalCard;
