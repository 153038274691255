import React from "react";
import { Grid } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import ModalCoverage from "../modal/modelCoverage";

const useStyles = makeStyles((theme) => ({
  iconCheck: {
    color: theme.palette.primary.main,
    fontSize: "32px",
  },
  iconClose: {
    color: theme.palette.primary.dark,
    fontSize: "32px",
  },
  containerTitle: {
    display: "flex",
  },
  containerOption: {
    margin: "10px 0px",
  },
}));

const CheckCoverage = ({ coverages }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className="proposalConditionContainer">
      {coverages.length > 0 &&
        coverages.map((cover, index) => {
          return (
            <div key={index}>
              <div className={classes.containerTitle}>
                <h4 className="proposalCondition">{cover.name}</h4>
                <ModalCoverage coverage={cover} />
              </div>
              <Grid
                className={classes.containerOption}
                container
                justify="center"
              >
                {cover.options &&
                  cover.options.length > 0 &&
                  cover.options.map((option, i) => {
                    return (
                      <Grid
                        item
                        xs={4}
                        key={i}
                        className="proposalConditionIconContainer"
                      >
                        {option.avaliable ? (
                          <Check className={classes.iconCheck} />
                        ) : (
                          <Close className={classes.iconClose} />
                        )}
                      </Grid>
                    );
                  })}
              </Grid>
            </div>
          );
        })}
    </Grid>
  );
};

export default CheckCoverage;
