import {
    ADD_TAG_CAR,
    ADD_TAG_CONTACT,
    ADD_TAG_ACC,
    REMOVE_TAG_CAR,
    REMOVE_TAG_CONTACT,
    REMOVE_TAG_ACC,
    PATCH_MAIL,
    PATCH_DATA_LIST,
    PATCH_PLAN,
    POST_SAVE_QUOTATION_PENDING,
    POST_SAVE_QUOTATION_FULFILLED,
    POST_SAVE_QUOTATION_REJECTED,
    POST_UPDATE_QUOTATION_PENDING,
    POST_UPDATE_QUOTATION_FULFILLED,
    POST_UPDATE_QUOTATION_REJECTED,
    POST_UPDATE_QUOTATION_SCREEN_VALUES_FULFILLED,
    POST_UPDATE_QUOTATION_SCREEN_VALUES_REJECTED,
    PATCH_QUOTATION_ERROR,
    REMOVE_ALL_QUOTE,
    SUCCESS_QUOTE,
    GET_LEAD_AND_QUOTATION_FULFILLED,
    GET_LEAD_AND_QUOTATION_PENDING,
    GET_LEAD_AND_QUOTATION_REJECTED,
    RECOVER_QUOTATION,
    GET_QUOTATION_BY_ID_PENDING,
    GET_QUOTATION_BY_ID_REJECTED,
    GET_QUOTATION_BY_ID_FULFILLED,
    RESET_QUOTATION,
    UPDATE_QUOTATION_SYNC,
    REMOVE_PLAN_DETAIL,
} from "../actions/types";

const initialState = {
    tagsCar: [],
    tagsContact: [],
    tagsAcc: [],
    dataList: {},
    mail: "",
    quotation: {},
    plan: {},
    error: false,
    loading: false,
};

// TODO este reducer se tiene que refactorizar
export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_TAG_CAR:
            return {
                ...state,
                tagsCar: [...state.tagsCar, action.payload],
            };
        case ADD_TAG_CONTACT:
            return {
                ...state,
                tagsContact: [...state.tagsContact, action.payload],
            };
        case ADD_TAG_ACC:
            return {
                ...state,
                tagsAcc: [...state.tagsAcc, action.payload],
            };
        case REMOVE_TAG_CAR:
            const newTagsCar = state.tagsCar;
            newTagsCar.splice(
                newTagsCar.findIndex((tag) => tag.method === action.payload)
            );
            return {
                ...state,
                tagsCar: newTagsCar,
                tagsContact: [],
                tagsAcc: [],
            };
        case REMOVE_ALL_QUOTE:
            return {
                ...state,
                tagsCar: [],
                tagsContact: [],
                tagsAcc: [],
                quotation: {},
            };
        case REMOVE_TAG_CONTACT:
            const newTagsContact = state.tagsContact;
            newTagsContact.splice(
                newTagsContact.findIndex((tag) => tag.method === action.payload)
            );
            return {
                ...state,
                tagsContact: newTagsContact,
            };
        case REMOVE_TAG_ACC:
            const newTagsAcc = state.tagsAcc;
            newTagsAcc.splice(
                newTagsAcc.findIndex((tag) => tag.qtyAcc === action.payload)
            );
            return {
                ...state,
                tagsAcc: newTagsAcc,
                tagsContact: [],
            };
        case PATCH_MAIL:
            return {
                ...state,
                mail: action.payload,
            };
        case PATCH_DATA_LIST:
            return {
                ...state,
                dataList: action.payload,
            };
        case PATCH_PLAN:
            return {
                ...state,
                plan: action.payload,
            };
        case REMOVE_PLAN_DETAIL:
            return {
                ...state,
                plan: null,
            };
        case POST_UPDATE_QUOTATION_FULFILLED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case POST_UPDATE_QUOTATION_SCREEN_VALUES_FULFILLED:
            return {
                ...state,
                quotation: action.payload,
                loading: false,
                error: false,
            };
        case GET_LEAD_AND_QUOTATION_FULFILLED:
            const { quotation } = action.payload;
            return {
                ...state,
                quotation: quotation,
                loading: false,
                error: false,
                success: true,
            };
        case GET_LEAD_AND_QUOTATION_PENDING:
        case POST_SAVE_QUOTATION_PENDING:
        case POST_UPDATE_QUOTATION_PENDING:
        case GET_QUOTATION_BY_ID_PENDING:
            return {
                ...state,
                loading: true,
            };
        case POST_SAVE_QUOTATION_FULFILLED:
            return {
                ...state,
                quotation: action.payload,
                loading: false,
                error: false,
                success: true,
            };
        case GET_LEAD_AND_QUOTATION_REJECTED:
        case POST_SAVE_QUOTATION_REJECTED:
        case POST_UPDATE_QUOTATION_REJECTED:
        case GET_QUOTATION_BY_ID_REJECTED:
        case POST_UPDATE_QUOTATION_SCREEN_VALUES_REJECTED:
            return {
                ...state,
                quotation: {},
                loading: false,
                error: true,
            };
        case PATCH_QUOTATION_ERROR:
            return {
                ...state,
                error: false,
            };
        case SUCCESS_QUOTE:
            return {
                ...state,
                success: action.payload,
            };
        case RECOVER_QUOTATION:
            return {
                ...state,
                quotation: action.payload,
            };
        case GET_QUOTATION_BY_ID_FULFILLED:
            return {
                ...state,
                loading: false,
                quotation: action.payload,
            };
        case RESET_QUOTATION:
            return {
                ...state,
                tagsCar: [],
                tagsContact: [],
                tagsAcc: [],
                dataList: {},
                quotation: {},
                plan: {},
                mail: "",
            };
        case UPDATE_QUOTATION_SYNC:
            return {
                ...state,
                loading: false,
                quotation: action.payload,
            };
        default:
            return state;
    }
}
