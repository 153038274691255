import {
  GET_MODELS_PENDING,
  GET_MODELS_FULFILLED,
  GET_MODELS_REJECTED,
  PATCH_MODELS_ERROR,
} from '../actions/types';
import { dataModel } from '../utils/dataSet';

const initialState = {
  data: dataModel,
  loading: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MODELS_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_MODELS_FULFILLED:
      return {
        ...state,
        data: { ...state.data, content: [...action.payload] },
        loading: false,
        error: false,
      };
    case GET_MODELS_REJECTED:
      return {
        ...state,
        data: dataModel,
        loading: false,
        error: true,
      };
    case PATCH_MODELS_ERROR:
      return {
        ...state,
        error: false,
      };
    default:
      return state;
  }
}
