import {
    getBriefsNewsAPI, getNewBySlugAPI
} from "../sevices/newsService";
import { FILTER_NEWS, RESET_NEWS } from "../actions/types";

// Get all Public news with limited data
export const getBriefsNews = () => async (dispatch) => {
    await dispatch(getBriefsNewsAPI());
};

export const getNewBySlug = (slug) => async (dispatch) => {
    await dispatch(getNewBySlugAPI(slug));
};

export const filterNews = (filters, rules) => (dispatch) => {
    dispatch({ type: FILTER_NEWS, payload: { filters, rules } });
};

export const resetNews = () => (dispatch) => {
    dispatch({ type: RESET_NEWS });
};
