import React from 'react';
import { Modal, DialogContent, Grid, CircularProgress } from '@material-ui/core';

const LoadingModal = () => {
  return (
    <Modal open={true} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <DialogContent style={{outline: 'none'}}>
        <Grid item xs={12} className="loadingContainer">
          <CircularProgress />
        </Grid>
      </DialogContent>
    </Modal>
  );
};

export default LoadingModal;
