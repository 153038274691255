import { Button, Container, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { v4 as uuidv4 } from "uuid";
import { useQuote } from '../../../hooks/useQuote';
import DropzoneField from '../../../components/form/dropzoneField';
import settings from '../../../settings';
import { navigate } from '@reach/router';

const id = uuidv4();

const InspectionComponent = (props) => {
  const riskType = props.riskType && props.riskType.toUpperCase();
  const quote = useQuote(riskType);
  const { quotation } = quote;
  console.log('quotation', quotation);

  const [files, setFiles] = useState([
    { name: 'greenIDFront', placeholder: 'Cedula verde - Frente', file: undefined },
    { name: 'greenIDBack', placeholder: 'Cedula verde - Dorso', file: undefined },
    { name: 'front', placeholder: 'Vehículo - Frente', file: undefined },
    { name: 'back', placeholder: 'Vehículo - Atras', file: undefined },
    { name: 'rightSide', placeholder: 'Vehículo - Lateral derecho', file: undefined },
    { name: 'leftSide', placeholder: 'Vehículo - Lateral izquierdo', file: undefined },
  ]);

  const uploadSettings = {
    requestID: id,
    insuredName: quotation.quotationNumber,
    repositoryCode: '10',
    permanentlySaved: true
  };

  const handleFiles = (name, file) => {
    const formattedFiles = files.map(f => {
      if (f.name === name) {
        return {
          ...f,
          file: file
        }
      }

      return f;
    });

    setFiles(formattedFiles);
  };

  const handleDelete = (name) => {
    const formattedFiles = files.map(f => {
      if (f.name === name) {
        return {
          ...f,
          file: undefined
        };
      };

      return f;
    });

    setFiles(formattedFiles);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    console.log('submit');
    const headers = {
      "Content-Type": "application/json",
    };
    const filesBody = files.map((f) => {
      const formData = new FormData();
      formData.append('file', f.file);
      formData.append('fileName', f.placeholder);
      formData.append('originalName', f.file.name);
      formData.append('requestID', uploadSettings.requestID);
      formData.append('insuredName', uploadSettings.insuredName);
      formData.append('repositoryCode', uploadSettings.repositoryCode);
      formData.append('permanentlySave', uploadSettings.permanentlySaved);

      return axios.post(`${settings.quotationUrlApi}file/upload`, formData, { headers: headers });
    });
    console.log(filesBody);
    const results = await Promise.all(filesBody);
    console.log(results);
    navigate('payment');
  };

  const isValid = () => {
    return files.every(f => f.file);
  };

  console.log('Files', files);
  return (
    <Container>

      <form className="cardAppContainerFormBuilder" onSubmit={handleSubmit}>
        <h1>Inspeccion</h1>
        <legend>Carga las imágenes indicadas en las áreas grises. Recordá que el vehículo debe poder visualizarse en su totalidad</legend>
        <legend style={{ color: 'rgba(0, 0, 0, 0.68)', fontSize: 12, marginTop: 8 }}>Peso Máximo: <b>1.5MB</b></legend>
        <Grid container direction="row" style={{ marginTop: 20 }}>
          {files.map((f, i) => ((
            <Grid item style={{ marginBottom: 20 }} xs={12} md={6}>
              <DropzoneField placeholder={f.placeholder} name={f.name} getFiles={handleFiles} deleteFile={handleDelete} fileList={files} />
            </Grid>
          )))}
        </Grid>
        <Grid item style={{ marginBottom: 20, textAlign: 'right' }}>
          <Button
            disabled={!isValid()}
            variant="contained" color="primary" type="submit">Siguiente</Button>
        </Grid>
      </form>
    </Container>
  )
}

export default InspectionComponent