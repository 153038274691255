import React, { useEffect, Fragment } from "react";
import { Card, CardContent } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import { AUTOS, HOGAR } from "../../utils/risksTypes";
import {
  getInsuranceById,
  resetInsurance,
} from "../../actions/insuranceActions";
import { Check, Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: (props) =>
      props.isSelected ? theme.palette.secondary.main : "#ffffff",
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)",
    height: "100%",
    cursor: "pointer",
    transition: "450ms",
    width: '100%'
  },
  cardContainer: {
    textAlign: "center",
    padding: "5px",
  },
  cardName: {
    fontSize: "15px",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: (props) =>
      props.isSelected ? "#ffffff" : theme.palette.primary.dark,
  },
  cardPrice: {
    fontSize: "18px",
    lineHeight: "24px",
    margin: "0px",
    fontWeight: "500",
    color: (props) => (props.isSelected ? "#ffffff" : "#4C1DB7"),
  },

  cardPrice2: {
    fontSize: "15px",
    lineHeight: "24px",
    margin: "0px",
    fontWeight: "400",
    color: (props) => (props.isSelected ? "#ffffff" : "#4C1DB7"),
  },
  cardMonth: {
    fontSize: "10px",
    lineHeight: "20px",
    margin: "0px",
    color: (props) => (props.isSelected ? "#ffffff" : "#4B4B4B"),
  },

  cardDescription: {
    fontSize: "13px",
    lineHeight: "14px",
    color: (props) => (props.isSelected ? "#ffffff" : "#828282"),
  },

  cardContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardGrid: {
    display: 'inline-flex',
    justifyContent: 'center'
  },
  planCoverages: {
    '&.is__first': {
      position: 'absolute',
      left: -187,
      width: 'calc(100% + 187px)',
    },
    '&.is__first > div': {
      textAlign: 'left',
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    '& > div': {
      fontSize: 13,
      lineHeight: "15px",
      letterSpacing: 0.15,
      color: "#4B4B4B",
      height: 76,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    '& span': {
      display: "inline-flex",
      alignItems: "center",
      maxWidth: 210,
      textAlign: "left",
      "& i": {
        marginRight: 4,
        "& svg.available": {
          color: "green",
        },
        "& svg.not-available": {
          color: "red",
        },
      },
    }
  }
}));

const CardPlan = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const riskType = props.quotation.riskType;
  const insurances = useSelector((state) => state.insurance);
  const quotation = props.quotation;
  const params = useParams();
  const products = props.products;
  const product = props.plan;

  const currentInsurance = insurances.data.find(i => i._id === props.quotation.insuranceId);

  const showDailyCharge = currentInsurance
    ? currentInsurance.options.showDailyCharge
    : false;

  let coverageGroups = [];
  const coverages = [];

  if (products) {
    products.forEach((product) => {
      product.coverages.forEach((coverage) => {
        const alreadyAdded = coverages.find(
          (cv) => cv.coverageId === coverage.coverageId
        );
        if (!alreadyAdded) {
          coverages.push(coverage);
        }
      });
    });
  }

  coverages.forEach((cv, i) => {
    // console.log(cv);
    const groupIndex = coverageGroups.findIndex(
      (_cv) => _cv.id === cv.coverageGroupId
    );
    const productCoverage = product.coverages.find(
      (coverage) => coverage.coverageId === cv.coverageId
    );
    if (groupIndex !== -1) {
      if (cv.coverageGroupName === "Coberturas Adicionales") {
        const hasField = coverageGroups[groupIndex].findIndex(
          (c) => c.id === "rcExceso"
        );
        if (hasField === -1) {
          coverageGroups[groupIndex] = coverageGroups[
            groupIndex
          ].concat({
            id: "rcExceso",
            name: "Responsabilidad Civil en Exceso",
            value: `$${new Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(quotation.quotationScreenValues.rcExceso)}`,
            available: true,
            content: null,
          });
        }
      }

      coverageGroups[groupIndex] = coverageGroups[
        groupIndex
      ].concat({
        id: cv.coverageId,
        name: cv.name,
        value: productCoverage && productCoverage.value,
        available: !!productCoverage,
        content: cv.content,
      });
    } else {
      coverageGroups = coverageGroups.concat({
        id: cv.coverageId,
        name: cv.name,
        value: productCoverage && productCoverage.value,
        available: !!productCoverage,
        content: cv.content,
      });
    }
  });

  const taxGroup = {
    name: "Prima",
    id: "tax",
    coverages: [
      {
        name: "Prima Neta",
        value: `$${new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(product.netPremium)}`,
      },
      {
        name: "S.C.V.S",
        value: `$${new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(product.SCVS)}`,
      },
      {
        name: "S.S.C",
        value: `$${new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(product.SSC)}`,
      },
      {
        name: "Derechos de emisión",
        value: `$${new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(product.taxeEmission)}`,
      },
      {
        name: "I.V.A",
        value: `$${new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(
          parseFloat(
            product.IVA && product.IVA2
              ? product.IVA + product.IVA2
              : product.IVA
          )
        )}`,
      },
      {
        name: "Prima total anual",
        value: `$${new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(product.totalPremiumWithTaxes)}`,
      },
    ],
  };

  const arraySort = (a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    // a must be equal to b
    return 0;
  };

  const getDescription = () => {
    switch (riskType) {
      case AUTOS:
        return props.plan.businessName;
      case HOGAR:
        return `$ ${(props.plan.price / 30).toFixed(2)} por día`;
      default:
        break;
    }
  };

  const handleClick = () => {
    props.handleSelect(props.index, product);
  };

  return (
    <div className={classes.cardContent}>
      <Card className={`${classes.card} ${props.className}`} onClick={handleClick}>
        <CardContent className={classes.cardContainer}>
          <p className={classes.cardName}>{props.plan.name}</p>
          <p className={classes.cardPrice}>{`$${props.plan.price.toLocaleString(
            "en-AR"
          )}`}</p>

          {showDailyCharge && (
            <Fragment>
              <span className={classes.cardPrice2}>{`$${(props.plan.price / 30)
                .toFixed(1)
                .toLocaleString("en-AR")}`}</span>{" "}
              <span className={classes.cardMonth}>POR DIA</span>
            </Fragment>
          )}

          <p className={classes.cardDescription}>{props.plan.businessName}</p>
        </CardContent>
      </Card>
      <div className={classes.cardGrid}>
        {props.isFirst && <div className={`${classes.planCoverages} is__first`}>
          {coverageGroups
            .sort(arraySort)
            .map((coverageGroup) => (
              <div>
                <span>
                  <i>
                    {coverageGroup.name}
                  </i>
                </span>
              </div>
            ))}
        </div>}
        <div className={classes.planCoverages}>
          {coverageGroups
            .sort(arraySort)
            .map((coverageGroup, index) => (
              <div>
                <span>
                  <i>
                    {coverageGroup.available ? (
                      <Check style={{ color: "#6FCF97" }} />
                    ) : (
                      <Close style={{ color: "#EB5757" }} />
                    )}
                  </i>
                </span>
              </div>
            ))}
        </div>
        {/* {coverageGroups
          .sort(arraySort)
          .map((coverageGroup, index) => (
            <div className={classes.planCoverages}>
              {coverageGroup.map((cv) => {
                return (
                  <div>
                    <span>
                      <i>
                        {cv.available ? (
                          <Check style={{ color: "#6FCF97" }} />
                        ) : (
                          <Close style={{ color: "#EB5757" }} />
                        )}
                      </i>
                    </span>
                  </div>
                )
              })}
            </div>
          ))} */}
      </div>
    </div>
  );
};

export default CardPlan;
