import React from "react";

export const generalsIcons = {
  congratulation: (
    <>
      <circle cx="36.5" cy="36.5" r="36.5" fill="#6FCF97" />
      <path
        d="M24 40.2759L33.28 49L53 26"
        stroke="white"
        strokeWidth="2"
        strokeLineCap="round"
        strokeLineJoin="round"
      />
    </>
  ),
  quoteCompleted: (
    <>
      <path
        d="M90.331 44.857h-30.2c-3.346 0-3.972-2.65-2.776-6.178 1.178-3.479 2.42-7.799 2.42-13.215C59.774 18.68 55.81 10 49.134 10c-4.14 0-2.677 11.844-8.468 24.545-3.225 7.075-11.624 19.06-15.118 22.218v35.544c1.701 2.724 6.363 8.809 14.072 8.809 7.103 0 39.281-.024 39.281-.024 10.227 0 10.341-12.865 3.58-13.972l1.834.064c6.473-.697 9.189-12.195.911-14.288l1.544-.028c6.184-.65 9.023-11.656.409-14.11l2.045-.207c9.186-.13 9.793-13.67 1.106-13.694Z"
        fill="#CADDE9"
      />
      <path
        d="M25.55 53.166v43.491c0 2.464-2.001 4.459-4.46 4.459H4.46A4.457 4.457 0 0 1 0 96.657V53.166a4.461 4.461 0 0 1 4.46-4.46h16.63c2.459 0 4.46 2 4.46 4.46Z"
        fill="#2373A3"
      />
      <path
        d="M17.24 60.704a3.413 3.413 0 1 0 0-6.826 3.413 3.413 0 0 0 0 6.826Z"
        fill="#fff"
      />
      <circle cx={91} cy={17} r={17} fill="#6FCF97" />
      <path
        d="m85.178 18.758 4.322 4.064 9.185-10.713"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
};
