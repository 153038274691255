import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: '34px',
    lineHeight: '36px',
    fontWeight: 400
  },
}));

const Title = ({ label }) => {
    const classes = useStyles();
    return (
        <h2 className={classes.title} >{label}</h2>
    );
}

export default Title;