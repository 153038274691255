import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LayoutFooter from '../components/layout/layoutFooter';
import { ReactComponent as NumberOne } from '../assets/images/numbers/number_one.svg';
import { ReactComponent as NumberTwo } from '../assets/images/numbers/number_two.svg';
import { ReactComponent as NumberThree } from '../assets/images/numbers/number_three.svg';
import { ReactComponent as NumberFour } from '../assets/images/numbers/number_four.svg';
import { ReferImage } from '../assets/customIcons';
import '../styles/refer.css';

const useStyles = makeStyles(theme => ({
    containerPage: {
        top: 500,
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            top: 250
        }
    },
    headerPage: {
        backgroundColor: '#F6F6F6',
        display: 'flex',
        height: 450,
        justifyContent: 'flex-end',
        // top: '150px',
        width: '95%',
        zIndex: -1,
        [theme.breakpoints.down('xs')]: {
            height: 280
        },
        maxWidth: '1440px',
        paddingTop: '20px'
    },
    headerImagePage: {
        backgroundImage: `url(${ReferImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%',
        width: '60%',
        [theme.breakpoints.down('lg')]: {
            width: '60%'
        },
        [theme.breakpoints.down('md')]: {
            height: '90%',
            width: '60%'
        },
        [theme.breakpoints.down('sm')]: {
            height: '80%',
            width: '70%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '55%'
        }
    },
    number: {
        padding: 8
    },
    numberContainer: {
        alignItems: 'center',
        display: 'flex'
    },
    pageSection: {
        padding: 8,
        marginBottom: 64
    },
    pageSectionTitle: {
        color: '#4B4B4B',
        fontSize: 30,
        paddingTop: 32,
        paddingBottom: 32,
        textAlign: 'center'
    },
    titlePage: {
        color: '#141B6A',
        fontSize: 50,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontFamily: 'Museo Sans , Fallback, sans-serif',
        width: 'auto',
        letterSpacing: '-0.5px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
            top: 170,
            width: 330
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 25,
            left: 24,
            top: 80,
            width: 260
        }
    },
    subtitlePage: {
        fontSize: 18,
        fontFamily: 'Museo Sans , Fallback, sans-serif',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            left: 30,
            top: 255,
            width: 315
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
            left: 24,
            top: 150,
            width: 270
        }
    },
    subtitlePageTwo: {
        fontSize: 18,
        left: 35,
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            left: 30,
            top: 360,
            width: 300
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
            left: 24,
            top: 245,
            width: 270
        }
    },
    svgIcon: {
        width: 'auto',
        height: 'auto'
    }
}));

const Refer = () => {
    const classes = useStyles();

    const { data } = useSelector((state) => state.config);
    let colorPrimary = '';
    let colorSecondary = '';
    let colorSecondaryVariant = '';

    if (data.theme) {
        colorPrimary = data.theme.primary.main;
        colorSecondary = data.theme.secondary.main;
        colorSecondaryVariant = data.theme.secondary.variant5;
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={6} >
                <Grid container className="titleContainer">
                    <div >
                        <div className={classes.titlePage}>Referí a un amigo y te regalamos una cuota.</div>
                        <div className={classes.subtitlePage}>
                Los referidos son amigos que recomiendas para asegurar con nosotros.
                Tanto los referidos como tú tendrán una cuota bonificada del seguro por estar asegurados con nosotros.
                        </div>
                        <div className={classes.subtitlePageTwo}>
                ¡No te pierdas esta oportunidad!
                        </div>

                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid container>

                    <div className={classes.headerPage}>
                        <ReferImage
                            colorPrimary={colorPrimary}
                            colorSecondary={colorSecondary}
                            colorSecondaryVariant={colorSecondaryVariant}
                            classes={{ root: classes.svgIcon }}
                        />
                    </div>
                </Grid>
            </Grid>

            <div className={classes.containerPage}>
                <h3 className={classes.pageSectionTitle}>Solo tienen que seguir los siguientes pasos:</h3>
                <Grid container justify="center" className={classes.pageSection}>
                    <Grid item xs={12} sm={8} md={6}>
                        <Grid item xs={12} className={classes.numberContainer}>
                            <NumberOne className={classes.number}/>
                            <p>Entra al portal de cliente y buscá en el menú – Referir amigos -.</p>
                        </Grid>
                        <Grid item xs={12} className={classes.numberContainer}>
                            <NumberTwo className={classes.number}/>
                            <p>Compartí el link que te dará por el medío que prefieras.</p>
                        </Grid>
                        <Grid item xs={12} className={classes.numberContainer}>
                            <NumberThree className={classes.number}/>
                            <p>Tu amigo referido debe ingresar con el link que le compartirte para comprar su seguro.</p>
                        </Grid>
                        <Grid item xs={12} className={classes.numberContainer}>
                            <NumberFour className={classes.number}/>
                            <p>Una vez que compró su seguro, les vamos a bonificar una cuota de sus seguros.</p>
                        </Grid>
                    </Grid>
                </Grid>
                <LayoutFooter />
            </div>
        </Grid>
    );
};

export default Refer;
