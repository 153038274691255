import { SISE_AUTH_FULFILLED, SISE_AUTH_PENDING, SISE_AUTH_REJECTED } from "../actions/types";

const initialState = {
  token: null,
  loading: false,
  error: false
};

export default function (state = initialState, action) {
  switch(action.type) {
    case SISE_AUTH_PENDING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SISE_AUTH_FULFILLED:
      return {
        ...state,
        token: action.payload,
        loading: false,
        error: false
      };
    case SISE_AUTH_REJECTED:
      return {
        ...state,
        token: null,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};