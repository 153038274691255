import React from 'react';
import {
    Grid,
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from '@reach/router';

const useStyles = makeStyles(() => ({
    button: {
        color: '#FFFFFF',
        width: '100%',
        fontSize: 14
    },
    grid: {
        marginBottom: 20
    },
    paddingX: {
        paddingLeft: 25,
        paddingRight: 25
    }
}));
const EmitButton = ({ type, disabled, onClick, label, showBtnNext = true }) => {
    const classes = useStyles();
    const urlParams = useParams();

    if (urlParams == null) {
        return null;
    }

    return (
        <Grid container justify="space-between" className={classes.paddingX}>
            {showBtnNext && (<Grid item xs={12} className={`resumePanelContract ${classes.grid}`}>
                <Button variant="contained" type={type} disabled={disabled} color="primary" className={classes.button} onClick={onClick}>
                    {label}
                </Button>
            </Grid>)}
        </Grid>
    );
};

export default EmitButton;
