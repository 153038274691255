import React, { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    List,
    ListItem,
    ListItemText,
    Button,
    CircularProgress
} from "@material-ui/core";
import { getPaymentLink } from "../../actions/paymentsLinksActions"  
import { navigate } from '@reach/router';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '30px',
        borderRadius: 16,
        borderStyle: 'dashed',
        fontSize: 12,
        marginRight: 5,
        marginLeft: 5,
        color: '#fff',
        backgroundColor: '#6718f5',
        '&:hover': {
            color: '#fff',
            border: 'unset',
            backgroundColor: '#6718f5',
        },
    },
    root2: {
        height: '30px',
        borderRadius: 16,
        borderStyle: 'dashed',
        fontSize: 12,
        marginRight: 5,
        display: 'block',
        marginBottom: 15,
    }
}));

function ShowPayments({ paymentId }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const paymentLink = useSelector((selector) => selector.paymentLinks.dataById);
    const currentPaymentDetail = paymentLink && paymentLink.length ? paymentLink[0] : undefined
    const today = moment(new Date())
    const isExpired = currentPaymentDetail && currentPaymentDetail.expiration_date_to ? moment(currentPaymentDetail.expiration_date_to).isSameOrBefore(today) : true
    const isAlreadyPayed = currentPaymentDetail && currentPaymentDetail.status === "pagado"
  
    useEffect(() => {
      executeInit();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const executeInit = async () => {
      dispatch(getPaymentLink(paymentId))
    };

    return ( 
        <Grid item xs={12}>
            {currentPaymentDetail ?
            <section style={{ padding: '1rem', marginBottom: '1rem' }} >
                <Grid style={{ textAlign: "center" }} > <h2>Resumen</h2> </Grid>               
                <p><strong>Importe a pagar:</strong>&nbsp;{currentPaymentDetail.items ? currentPaymentDetail.items.reduce((acumulador, actual) => acumulador + parseInt(actual.unit_price), 0) : 0}$</p>
                <p><strong>Cantidad de cuotas:</strong>&nbsp;{currentPaymentDetail.items ? currentPaymentDetail.items.length : 0}</p>
                <p><strong>Valido hasta:</strong>&nbsp;{moment(currentPaymentDetail.expiration_date_to).format("DD/MM/YYYY, h:mm a")}</p>
                <p><strong>Estado:</strong>&nbsp;{currentPaymentDetail.status}</p>
                <p><strong>Link numero:</strong>&nbsp;1</p>                       
  
                <Grid  style={{ textAlign: "center" }} >
                    <h3>Detalles</h3>
                </Grid>
                <PaymentItems data={currentPaymentDetail.items} />
                <Grid style={{ textAlign: "center" }} >
                    {isExpired && <p style={{ color: "red" }}><strong>Este link de pago ya expiró</strong></p>}

                    {!isExpired && !isAlreadyPayed &&
                        <Button
                            variant="contained" 
                            color="primary"
                            onClick={() => navigate(currentPaymentDetail.paymentLink)}
                            classes={{
                                root: classes.root,
                            }}
                        >
                            Pagar
                        </Button>
                    }

                    {
                        isAlreadyPayed && <p style={{ color: "green" }}><strong>Estas cuotas ya se encuentran pagadas.</strong></p>
                    }
                </Grid>
            </section>
                : <h2>Sin datos para mostrar</h2>
            }
        </Grid>
    )
}

const PaymentItems = ({ data, loading}) =>{
    if(!data || !data.length){
        return <label>Sin datos</label>
    }
    
    if(loading) {         
        return <Grid style={{ textAlign: "center" }} ><CircularProgress /></Grid>
    }         
   
    return(
        <section style={{ padding: '1rem', marginBottom: '1rem' }} >
            <List>
                {data.map((item, i) => (
                <ListItem style={{ borderBottom: '1px solid rgba(33, 33, 33, 0.08)' }}>
                    <ListItemText
                    primary={
                        <Grid container >
                            {
                            item.title && <label style={{ display: "block"}} >
                                {item.title} </label>
                            }
                        </Grid>
                    }                
                    />
                </ListItem>
                ))}
            </List>
        </section>
    )
}

export default ShowPayments;
