import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import parse from 'html-react-parser';

const useStyles = makeStyles(theme => ({
  panelStyle: {
    border: '1px solid',
    color: theme.palette.primary.dark,
    margin: 8,
  },
  panelIcon: {
    color: theme.palette.primary.dark
  },
  panelTitle: {
    fontSize: '1.2rem'
  },
  panelContent: {
    color: 'black'
  }
}));

const PanelFaq = ({ questions }) => {
  const classes = useStyles();

  return (questions.map((q, index) => {
    return (
      <ExpansionPanel key={index} className={classes.panelStyle}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className={classes.panelIcon} />}
          aria-controls={`panel${index+1}a-content`}
          id={`panel${index+1}a-header`}
        >
          <Typography className={classes.panelTitle}>{ q.question }</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.panelContent}>
            { parse(q.answer) }
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }));
};

export default PanelFaq;
