import {
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';
import { VerifiedUser } from '@material-ui/icons';
import { makeStyles, withStyles } from '@material-ui/styles';
import { navigate, useParams } from '@reach/router';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { resetClientInfo, saveClientRecognitionInfo } from '../../../actions/clientInfoActions';
import Title from '../../../components/layout/title';
import RecognitionModal from '../../../components/recognition/recognitionModal';
import { putUpdateQuotationScreenValues } from '../../../actions/serviceActions';
import { getQuote } from '../../../actions/quoteActions';
import { useQuote } from '../../../hooks/useQuote';
import {
    SOLICITUD,
    PAGADA,
    DOCUMENT_EXPIRED,
    SCAN_FAIL,
    DUI_FAIL
} from '../../../utils/scores';
import Utils from '../../../utils/utils';
import settings from '../../../settings';
import { addTrackingLead } from '../../../actions/leadActions';

const useStyles = makeStyles((theme) => ({
    verifiedStatusContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& button': {
            width: '100%'
        }
    },
    verifiedStatus: {
        color: '#6FCF97',
        fontSize: 132,
        marginBottom: '1rem'
    }
}));

const StyledDialog = withStyles(() => ({
    root: {
        zIndex: '9999 !important'
    }
}))(Dialog);

const RecognitionComponent = (props) => {
    const riskType = props.riskType && props.riskType.toUpperCase();
    const quote = useQuote(riskType);
    const { quotation } = quote;
    const { quotationScreenValues } = quotation;
    const template = useSelector((state) => state.template);
    const lead = useSelector((state) => state.lead.data);
    const config = useSelector((state) => state.config);
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [data] = useState(null);
    const [loadingIframe, setLoadingIframe] = useState(null);
    const [type, setType] = useState('doc');
    const [, setFaceScanQty] = useState(0);
    const [iframeKey, setIframeKey] = useState(100); // this is for rest the iframe when this change
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const isFaceRegognitionView =
        window.location.href.includes('face-recognition');
    const params = useParams();
    const queryString = window.location.search;
    const urlParamsQuery = new URLSearchParams(queryString);
    const isStoredLink = !!urlParamsQuery.get('lead');
    const now = moment().add(1, 'day');
    const end = now.clone().add(1, 'y');

    const updateTrackingCallback = () => {
        dispatch(
            addTrackingLead(
                'Documento Vencido',
                riskType,
                DOCUMENT_EXPIRED,
                lead
            )
        );
    };

    const attempsFailedTrackingCallback = () => {
        dispatch(
            addTrackingLead(
                'Escaneo Facial Finalizado por límite de intentos fallidos',
                riskType,
                SCAN_FAIL,
                lead
            )
        );
    };

    useEffect(() => {
        if (Utils.isEmptyObject(quotation)) {
            getQuoteInfo();
        }
    }, [quotation]);

    useEffect(() => {
        dispatch(
            addTrackingLead(
                isFaceRegognitionView
                    ? 'Reconociendo rostro del cliente con el documento'
                    : 'Reconocimiento de documento del cliente',
                riskType,
                isFaceRegognitionView ? PAGADA : SOLICITUD,
                lead
            )
        );
        if (!isFaceRegognitionView) {
            dispatch(resetClientInfo());
            resetClientInfoInQuotation();
        }
    }, []);

    useEffect(() => {
        if (isFaceRegognitionView) {
            if (
                isStoredLink &&
                quotationScreenValues &&
                quotationScreenValues.faceRecognition &&
                !Utils.isEmptyObject(quotationScreenValues.faceRecognition)
            ) {
                navigate('sumary');
            }
        } else {
            if (
                isStoredLink &&
                quotationScreenValues &&
                quotationScreenValues.document &&
                !Utils.isEmptyObject(quotationScreenValues.document)
            ) {
                navigate('client-info');
            }
        }
    }, [quotationScreenValues]);

    useEffect(() => {
        window.addEventListener('message', (evt) => {
            if (evt.data.message && evt.data.message === 'limitAttemps') {
                setOpenModal(true);
                attempsFailedTrackingCallback();
                return;
            }
            if (evt.data.message && evt.data.message === 'loadingRecognize') {
                setLoadingIframe(evt.data.value);
            }
            if (evt.data.message && evt.data.message === 'errorRecognition') {
                const statusOfLead =
                    evt.data.value.origin === 'DUI' ? DUI_FAIL : SCAN_FAIL;
                dispatch(
                    addTrackingLead(
                        evt.data.value.status,
                        riskType,
                        statusOfLead,
                        lead
                    )
                );
            }
            if (
                evt.data.message &&
                (evt.data.message === 'frontDUIBase64' ||
                    evt.data.message === 'backDUIBase64')
            ) {
                localStorage.setItem(
                    `recognition::${evt.data.message}`,
                    evt.data.value
                );
            }
            if (
                evt.data.message &&
                evt.data.message === 'documentExpiry' &&
                evt.data.value
            ) {
                updateTrackingCallback();
            }

            if (
                evt.data.message &&
                evt.data.message === 'refreshFrame' &&
                evt.data.value
            ) {
                setIframeKey((iframeKey) => iframeKey + 1);
            }
            if (
                (evt.data.message && evt.data.message === 'faceScanData') ||
                evt.data.message === 'getScanID'
            ) {
                console.log('Llego un mensaje', evt.data.value);
                next(evt.data.value);
            }
        });
        const isFaceType = window.location.href.includes('face');
        if (isFaceType) {
            setType('face');
        } else {
            setType('doc');
        }

        return () => {
            console.log('Closing listener');
            window.removeEventListener('message', (evt) => {
                if (evt.data.message) {
                    next(evt.data.value);
                }
            });
        };
    }, []);

    const sendMail = async () => {
        let quotationData = {};
        if (
            quotation &&
            quotation._id &&
            // !isSendMail &&
            quotationScreenValues &&
            !Utils.isEmptyObject(quotationScreenValues)
        ) {
            const firstPlan =
                quotation.products && quotation.products.length
                    ? quotation.products[0]
                    : {};
            const userName = quotationScreenValues.name
                ? quotationScreenValues.name
                : quotationScreenValues.nombreAsegurado;

            if (quotation && quotation.creationDate) {
                quotationData = { ...quotation };
                quotationData.creationDate = new Date(
                    quotation.creationDate
                ).toLocaleDateString();
            }

            const dataToPdf = {
                // name: isOrienteDomain ? 'Cotizador Oriente' : 'Caratula',
                name: template.data.name,
                templateData: {
                    quotation: quotationData,
                    plan: {
                        name: firstPlan.name,
                        price: (firstPlan.price / 12).toFixed(2),
                        frequency: 'mensual',
                        premium: firstPlan.premium
                    },
                    person: {
                        name: userName
                    },
                    validity: {
                        start: {
                            day: `${now.format('DD')}`,
                            month: now.get('month') + 1,
                            monthLabel: `${now.format('MMMM')}`,
                            year: now.get('y'),
                            time: `${now.format('hh:mm A')}`
                        },
                        end: {
                            day: `${end.format('DD')}`,
                            month: end.get('month') + 1,
                            monthLabel: `${end.format('MMMM')}`,
                            year: end.get('y'),
                            time: `${now.format('hh:mm A')}`
                        }
                    }
                }
            };

            const templates = [].concat(dataToPdf);

            const headers = { 'Content-Type': 'application/json' };

            await axios({
                method: 'post',
                headers,
                url: `${settings.quotationUrlApi}contact/quotation/send`,
                data: {
                    templates,
                    emailDataToSend: {
                        subject: 'Bienvenido!',
                        // message: isOrienteDomain ? orienteMail : abankMail,
                        message: template.data.newTemplate,
                        email: quotation.lead,
                        filename: 'plan.pdf'
                    }
                }
            });
        }
    };

    const resetClientInfoInQuotation = async () => {
        const quotationId = params.resultQuoteId;
        await dispatch(
            putUpdateQuotationScreenValues({
                quotationScreenValues: {
                    clientInfo: null
                },
                _id: quotationId
            })
        );
    };

    const getQuoteInfo = async () => {
        const quotationId = params.resultQuoteId;
        dispatch(getQuote(quotationId));
    };

    const saveValuesInQuotation = async (data) => {
        const quotationId = params.resultQuoteId;

        const propertyType = window.location.href.includes('face-recognition')
            ? 'face'
            : 'doc';

        const payload =
            propertyType === 'doc'
                ? { document: data.document, scanId: data.scanId }
                : {
                    faceRecognition: data,
                    scanId: data.scanId,
                    faceMatching: data.scores.faceMatching,
                    liveness: data.scores.liveness
                };

        await dispatch(
            putUpdateQuotationScreenValues({
                quotationScreenValues: payload,
                _id: quotationId
            })
        );
    };

    const next = (info) => {
        const isFaceType = window.location.href.includes('face');
        const isDocType = window.location.href.includes('recognition');

        const isSuccessRecognition = !isFaceType
            ? info &&
              info.code === 'OK' &&
              info.document.name &&
              info.document.documentNumber
            : info;

        if (isSuccessRecognition) {
            dispatch(saveClientRecognitionInfo(info));

            saveValuesInQuotation(info);

            if (isFaceType && info) {
                sessionStorage.setItem(
                    'quotation::faceScanInfo',
                    JSON.stringify(info)
                );
                navigate('sumary');
            } else {
                sessionStorage.setItem(
                    'quotation::documentScanInfo',
                    JSON.stringify(info)
                );
                navigate('client-info');
            }
        } else {
            if (isFaceType) {
                setFaceScanQty((faceScanQty) => faceScanQty + 1);
            } else {
                if (isDocType && info) {
                    navigate('recognition');
                }
            }
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        navigate('/');
    };

    return (
        <Grid container justify="center" className="appContainer">
            {quotationScreenValues && (
                <>
                    {data && data.code === 'OK' ? (
                        <Grid item xs={6} className="cardAppContainer centered">
                            <div className={classes.verifiedStatusContainer}>
                                <Title label="DUI verificado con exito" />
                                <VerifiedUser
                                    className={classes.verifiedStatus}
                                />
                                <Button
                                    variant="contained"
                                    onClick={next}
                                    color="primary"
                                >
                                    Continuar
                                </Button>
                            </div>
                        </Grid>
                    ) : null}
                </>
            )}
            <RecognitionModal
                open={open}
                riskType={riskType}
                handleClose={handleClose}
                handleOpen={handleOpen}
                type={type}
                iframeKey={iframeKey}
                isLoading={loadingIframe}
                appTheme={config.data.theme}
            />
            <StyledDialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle
                    id="alert-dialog-title"
                    style={{ textAlign: 'center', color: '#d94c39' }}
                >
                    {'¡Error!'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div
                            style={{ textAlign: 'center', alignSelf: 'center' }}
                        >
                            Tuviste inconvenientes en realizar la prueba de
                            vida.
                            <br />
                            Te pedimos que te pongas en contacto con nosotros.
                            <br />
                            <br />
                            <label style={{ color: '#4286af' }}>WA:</label>{' '}
                            <label style={{ fontWeight: 'bold' }}>
                                7841-3519 /
                            </label>{' '}
                            <label style={{ color: '#4286af' }}>Tel:</label>{' '}
                            <label style={{ fontWeight: 'bold' }}>
                                25218300 ext. 153/160
                            </label>
                            <br />
                            <label style={{ color: '#4286af' }}>
                                Correo:
                            </label>{' '}
                            <label style={{ fontWeight: 'bold' }}>
                                digital@aseguradoraabank.com
                            </label>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </StyledDialog>
        </Grid>
    );
};

export default RecognitionComponent;
