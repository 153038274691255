import React, { useState } from 'react';
import { Grid, Paper, Tabs, Tab, Fab } from '@material-ui/core';
import { Message } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import PanelFaq from '../components/faq/panelFaq';
import LayoutFooter from '../components/layout/layoutFooter';
import { brandQuestions, buyQuestions, coverQuestions, claimQuestions, userQuestions }
    from '../components/faq/faqs';
import '../styles/faq.css';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`faq-tabpanel-${index}`}
            aria-labelledby={`faq-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
};

const tabControl = (index) => {
    return {
        id: `faq-tab-${index}`,
        'aria-controls': `faq-tabpanel-${index}`
    };
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper
    },
    contentSection: {
        padding: 8
    },
    pageWrapper: {
        paddingTop: 16
    },
    pageSection: {
        paddingLeft: 12,
        paddingRight: 12
    },
    sectionContainer: {
        alignItems: 'center',
        marginBottom: 64
    },
    sectionContainerLast: {
        alignItems: 'center',
        marginBottom: 140,
        padding: 8
    },
    paneltabs: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    tabsContainer: {
        backgroundColor: '#F6F6F6',
        width: 'fit-content',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    fab: {
        backgroundColor: theme.palette.secondary.main,
        marginBottom: '20px'
    }
}));

const Faq = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={`page ${classes.pageWrapper}`}>
            <Grid container justify="center" className={classes.pageSection}>
                <Grid item xs={12} sm={10} md={8} className={classes.sectionContainer}>
                    <div className={classes.paneltabs}>
                        <Paper className={classes.tabsContainer}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                indicatorColor="primary"
                                aria-label="tabs-faqs"
                            >
                                <Tab label="Conocenos" {...tabControl(0)} />
                                <Tab label="Compra y Pago" {...tabControl(1)} />
                                <Tab label="Coberturas" {...tabControl(2)} />
                                <Tab label="Reclamos" {...tabControl(3)} />
                                <Tab label="Mi Sesión" {...tabControl(4)} />
                            </Tabs>
                        </Paper>
                    </div>
                    <TabPanel value={value} index={0} className={classes.contentSection}>
                        <PanelFaq questions={brandQuestions} />
                    </TabPanel>
                    <TabPanel value={value} index={1} className={classes.contentSection}>
                        <PanelFaq questions={buyQuestions} />
                    </TabPanel>
                    <TabPanel value={value} index={2} className={classes.contentSection}>
                        <PanelFaq questions={coverQuestions} />
                    </TabPanel>
                    <TabPanel value={value} index={3} className={classes.contentSection}>
                        <PanelFaq questions={claimQuestions} />
                    </TabPanel>
                    <TabPanel value={value} index={4} className={classes.sectionContainerLast}>
                        <PanelFaq questions={userQuestions} />
                    </TabPanel>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    style={{ paddingTop: 60, position: 'relative' }}
                >
                    <Fab color="primary" aria-label="comment" className={classes.fab}>
                        <Message />
                    </Fab>
                </Grid>
            </Grid>
            <LayoutFooter />
        </div>
    );
};

export default Faq;
