import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideMessage } from "../../actions/notificationActions";
import AlertMessage from "../sharing/AlertMessage";
import SnackMessage from "../sharing/SnackMessage";

const CustomSnackbar = () => {
  const messageSelector = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideMessage());
  };

  const renderTypeMessage = (theVariant) => {
    switch (theVariant) {
      case "alert":
        return <AlertMessage {...messageSelector} handleClose={handleClose} />;
      default:
        return <SnackMessage {...messageSelector} handleClose={handleClose} />;
    }
  };

  return <>{renderTypeMessage(messageSelector.variant)}</>;
};

export default CustomSnackbar;
