import { LocationProvider, navigate } from '@reach/router';
import { AUTOS, HOGAR, VIDA, SALUD, GASTOS_MEDICOS } from '../utils/risksTypes';

export const attachRiskTypeToAction = store => next => action => {
    try {
        const riskTypeString = getRiskTypeFromUrl();
        if (typeof riskTypeString !== 'undefined') {
            action.riskType = riskTypeString.includes('%') ? decodeURIComponent(riskTypeString).toUpperCase() : riskTypeString.toUpperCase();
        }
        next(action);
    } catch (error) {
        console.log(error);
        navigate('/');
    }
};

export const getRiskTypeFromUrl = () => {
    const pathname = LocationProvider.defaultProps.history.location.pathname;
    return pathname.split('/').find(element => checkIfIsRiskType(decodeURIComponent(element)));
};

const checkIfIsRiskType = (riskType) => {
    switch (riskType.toUpperCase()) {
        case AUTOS:
        case HOGAR:
        case VIDA:
        case SALUD:
        case GASTOS_MEDICOS:
            return true;
        default:
            return false;
    }
};
