import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  lineProgress: {
    background: '#D8D0F6',
    borderRadius: '30px',
    height: '10px',
    borderBottom: '35px',
  },
  container: {
    background: theme.palette.primary.main,
    borderRadius: '30px',
    height: '10px'
  },
}));

const QuoteProgress = ({ width }) => {
  const classes = useStyles();
  return (
    <div className={classes.lineProgress}>
      <div className={classes.container} style={{ width }}></div>
    </div>
  );
};

export default QuoteProgress;
