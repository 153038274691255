import React, { useState, useEffect, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import '../../styles/home.css';
import { useStyles } from '../home';
import { getBriefsNews } from '../../actions/newsActions';
import CardNew from './cardNew';
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';

const News = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const news = useSelector(state => state.news);

    useEffect(() => {
        if (!news.data.length) {
            executeInitnews();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const executeInitnews = async () => {
        await dispatch(getBriefsNews());
    };

    const qtyItemsByScreenWidth = () => {
        const width = window.innerWidth;
        let qty = 3;
        if (width < 1000 && width > 780) {
            qty = 2;
        }
        if (width < 779) {
            qty = 1;
        }
        return qty;
    };

    return (
        <Grid container justify="center" alignItems="center" className={classes.containerCards}>
            {news.data.length
                ? (
                    <Carousel addArrowClickHandler={true} arrowRight={(<div className="arrowRight"><ArrowForwardIos/></div>)} arrowLeft={(<div className="arrowLeft"><ArrowBackIos/></div>)} dots infinite animationSpeed={2000} slidesPerPage={qtyItemsByScreenWidth()} offset={10}>
                        {
                            news.data.map((item, index) => <CardNew data={item} key={`${item}-${index}`}/>)
                        }
                    </Carousel>
                ) : ''}
        </Grid >

    );
};

export default News;
