import {
    GET_NEWS_PENDING,
    GET_NEWS_FULFILLED,
    GET_NEWS_REJECTED,
    GET_NEWS_BY_SLUG_PENDING,
    GET_NEWS_BY_SLUG_FULFILLED,
    GET_NEWS_BY_SLUG_REJECTED,
    RESET_NEWS,
} from "../actions/types";

const initialState = {
    data: [],
    singleNew: {},
    filteredData: [],
    loading: false,
    error: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_NEWS_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_NEWS_FULFILLED:
            return {
                ...state,
                data: action.payload,
                filteredData: action.payload,
                loading: false,
                error: false,
            };
        case GET_NEWS_REJECTED:
            return {
                ...state,
                data: [],
                loading: false,
                error: true,
            };

        case GET_NEWS_BY_SLUG_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_NEWS_BY_SLUG_FULFILLED:
            return {
                ...state,
                singleNew: action.payload,
                loading: false,
                error: false,
            };
        case GET_NEWS_BY_SLUG_REJECTED:
            return {
                ...state,
                singleNew: {},
                loading: false,
                error: true,
            };







        case RESET_NEWS:
            return {
                ...state,
                filteredData: state.data,
            };
        default:
            return state;
    }
}
