import React, { useEffect, useRef, useState } from 'react';
import { List, ListItem, ListSubheader, ListItemText, TextField, InputAdornment, Tooltip, IconButton, InputLabel, ListItemIcon, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForwardIos, Input } from '@material-ui/icons';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  listItem: {
    color: theme.palette.primary.dark,
    '&:hover': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down("sm")]: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark
      }
    }
  },
  search: {
    marginBottom: '35px'
  },
  input: {
    marginTop: '2rem !important'
  },
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: 16,
    marginBottom: 18,
    '& svg': {
      marginRight: 6,
      fontSize: 16
    },
    color: theme.palette.typography.main,
    '& label': {
      overflow: 'unset',
      maxWidth: '100%',
      whiteSpace: 'break-spaces'
    }
  }
}));

const QuoteCheckList = ({
  data,
  handleSelect,
  label,
  isOptional,
  isSearchable,
  quotationScreenOptions,
  handleNextButtonValid
}) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [checked, setChecked] = useState([]);
  const [labelHeight, setLabelHeight] = useState(16);
  const labelRef = useRef();
  const config = useSelector(state => state.config.data);
  const dataList = data;

  useEffect(() => {
    if (quotationScreenOptions.nextButtonType === 'ext') {
      handleNextButtonValid(true);
    }
  }, []);

  useEffect(() => {
    if (labelRef.current) {
      setLabelHeight(labelRef.current.offsetHeight);
    }
  }, [labelRef.current]);

  const filterByValue = (item) => {
    return item.name.includes(value);
  };

  const handleChangeValue = (evt) => {
    setValue(evt.target.value);
  }

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const confirmSelection = () => {
    handleSelect(checked);
  };
  
  return (
    <List
      component="nav"
      className="quoteList"
      subheader={
        <ListSubheader className={classes.search} component="h2">
          {(config.theme && !config.theme.labelPosition) && (
            <div className={classes.label}>
              <ArrowForwardIos color="primary" />
              <InputLabel id="external-input-label">{label}</InputLabel>
            </div>)}
          <TextField
            label={(config.theme && config.theme.labelPosition) ? label : null}
            fullWidth
            color="primary"
            value={value}
            onChange={handleChangeValue}
            InputProps={{
              endAdornment: (quotationScreenOptions.nextButtonType === 'int') && (
                <InputAdornment position="end">
                  <Tooltip title={'Siguiente'} arrow open placement="top">
                    <IconButton
                      aria-label="check input value"
                      onClick={confirmSelection}
                      style={{
                        color: config.theme.primary.main
                      }}
                    >
                      <Input />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              readOnly: !isSearchable,
              disabled: !isSearchable,
              style: {
                marginTop: labelHeight
              },
              margin: 'dense'
            }}
          />
        </ListSubheader>
      }
    >
      {
        dataList.filter(filterByValue).map((item, index) => {
          return (
            <ListItem className={classes.listItem} button key={`check__item__${index}`} onClick={() => handleToggle(item._id)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(item._id) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={item.name ? item.name : item} />
            </ListItem>
          )
        })
      }
    </List>
  )
}

export default QuoteCheckList;