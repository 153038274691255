import React, { useState } from 'react';
import { Link } from '@reach/router';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as TwitterIcon } from '../../assets/images/icons/twitter_icon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/images/icons/facebook_icon.svg';
import { ReactComponent as InstagramIcon } from '../../assets/images/icons/instagram_icon.svg';
import ModalSchedule from '../modal/modalSchedule';
import { AUTOS, HOGAR } from '../../utils/risksTypes';

const useStyles = makeStyles(theme => ({
    footerColumn: {
        padding: 16,
        [theme.breakpoints.down('xs')]: {
            padding: '8px 16px 0px'
        }
    },
    footerContainer: {
        backgroundColor: 'rgba(75,75,75, 0.1)',
        paddingBottom: 32,
        paddingLeft: 64,
        paddingRight: 64,
        paddingTop: 32,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 32,
            paddingRight: 32
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 64,
            textAlign: 'center'
        }
    },
    icon: {
        marginRight: 16,
        '& circle': {
            fill: theme.palette.primary.main
        }
    },
    iconContainer: {
        marginTop: 8
    },
    title: {
        color: theme.palette.secondary.main,
        fontSize: '1.9rem',
        fontWeight: '600',
        marginBottom: 16,
        fontFamily: 'WorkSans'
    },
    titleFooter: {
        color: '#4B4B4B',
        fontSize: '1.5rem',
        fontWeight: '600',
        fontFamily: 'WorkSans'
    },
    subtitleFooter: {
        color: '#4B4B4B',
        fontSize: '1.1rem'
    },
    subtitleFooterSelected: {
        color: '#4B4B4B',
        cursor: 'pointer',
        fontSize: '1.1rem'
    }
}));

const LayoutFooter = () => {
    const classes = useStyles();
    const [openSchedule, setOpenSchedule] = useState(false);

    const hanldeSchedule = () => {
        setOpenSchedule(!openSchedule);
    };

    return (
        <Grid container justify="center" alignItems="center" className={classes.footerContainer}>
            <Grid container justify="center" alignItems="center" item xs={12} sm={6} md={3} className={classes.footerColumn}>
                <Link to="/"><div className={classes.title}>iConnectance</div></Link>
                <div className={classes.iconContainer}>
                    <a href="https://twitter.com/iconnectance" target="_blank" rel="noreferrer noopener">
                        <TwitterIcon className={classes.icon} />
                    </a>
                    <a href="https://www.facebook.com/iconnectance" target="_blank" rel="noreferrer noopener">
                        <FacebookIcon className={classes.icon} />
                    </a>
                    <a href="https://instagram.com/iconnectance" target="_blank" rel="noreferrer noopener">
                        <InstagramIcon className={classes.icon} />
                    </a>
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={classes.footerColumn}>
                <p className={classes.titleFooter}>iConnectance</p>
                <Link to="/refer"><p className={classes.subtitleFooter}>Conócenos</p></Link>
                <p className={classes.subtitleFooter}>Inicia sesión</p>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={classes.footerColumn}>
                <p className={classes.titleFooter}>Legales</p>
                <Link to={`/quote/${HOGAR.toLowerCase()}/null/null/info`}><p className={classes.subtitleFooter}>Términos y condiciones</p></Link>
                <Link to={`/quote/${AUTOS.toLowerCase()}/null/null/info`}><p className={classes.subtitleFooter}>Políticas de privacidad</p></Link>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={classes.footerColumn}>
                <p className={classes.titleFooter}>Te orientamos</p>
                <Link to="/faq"><p className={classes.subtitleFooter}>Respuestas a tus dudas</p></Link>
                <p onClick={hanldeSchedule} className={classes.subtitleFooterSelected}>Contáctanos</p>
            </Grid>
            <ModalSchedule open={openSchedule} handleSchedule={hanldeSchedule} />
        </Grid>
    );
};

export default LayoutFooter;
