import React, { useRef, useState } from "react";
import {
    Button,
    Stepper,
    Step,
    StepLabel,
    makeStyles,
    Grid,
    Typography,
    Card,
    CardContent,
    Container,
    useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import ModalMessage from "../../components/modal/generic";
import settings from "../../settings";
import LightPhoto from "./../../assets/images/quotation/light.png";
import ScanPhoto from "./../../assets/images/quotation/scandui.png";
import CleanPhoto from "./../../assets/images/quotation/spaceclean.png";
import RecognizePhoto from "./../../assets/images/quotation/recognize.png";
import AccesoryPhoto from "./../../assets/images/quotation/accesories.png";
import { DotConnector, DotStepIcon } from "../styled-stepper/styleDotStepper";
import { useQuote } from "../../hooks/useQuote";

const useStyle = makeStyles((theme) => ({
    box: {
        borderRadius: 20,
        backgroundColor: "white",
        padding: "78px 47px",
        marginBottom: 50,
        [theme.breakpoints.down("xs")]: {
            padding: "30px 20px",
        },
    },
    stepper: {
        backgroundColor: "#f4f4fa",
        [theme.breakpoints.up("md")]: {
            maxWidth: 1100,
        },
        margin: "0 auto",
        width: "90%",
        [theme.breakpoints.down("xs")]: {
            width: "90%",
            padding: 12,
        },
    },
    button: {
        marginTop: 52,
        width: "100%",
        padding: "19px 15px",
        maxWidth: 231,
    },
    title: {
        fontSize: 34,
        lineHeight: "36px",
        color: "#02183A",
        fontWeight: 400,
        marginBottom: 11,
    },
    description: {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: "24px",
        color: "#02183A",
        marginBottom: 28,
    },
    instruction: {
        color: "#2373A3",
    },
    listNumber: {
        display: "inline-block",
        textAlign: "center",
        marginRight: 15,
        borderRadius: 100,
        width: 25,
        height: 25,
        backgroundColor: "#CADDE9",
        color: "#2373A3",
        fontWeight: 800,
    },
    lista: {
        marginBottom: 20,
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "22px",
    },
}));

const cardStyle = makeStyles((theme) => ({
    scanPhoto: {
        backgroundImage: ({
            type,
        }) => `linear-gradient(185.94deg, rgba(255, 255, 255, 0) 35.76%, #EAE7E7 88.69%),
    url(${ScanPhoto})`,
    },
    cleanPhoto: {
        backgroundImage: ({
            type,
        }) => `linear-gradient(185.94deg, rgba(255, 255, 255, 0) 35.76%, #EAE7E7 88.69%),
  url(${CleanPhoto})`,
    },
    lightPhoto: {
        backgroundImage: ({
            type,
        }) => `linear-gradient(185.94deg, rgba(255, 255, 255, 0) 35.76%, #EAE7E7 88.69%),
url(${LightPhoto})`,
    },
    accessoryPhoto: {
        backgroundImage: ({
            type,
        }) => `linear-gradient(185.94deg, rgba(255, 255, 255, 0) 35.76%, #EAE7E7 88.69%),
url(${AccesoryPhoto})`,
    },
    recognizePhoto: {
        backgroundImage: ({
            type,
        }) => `linear-gradient(185.94deg, rgba(255, 255, 255, 0) 35.76%, #EAE7E7 88.69%),
url(${RecognizePhoto})`,
    },
    root: {
        display: "flex",
        width: 261,
        height: 293,
        position: "relative",
        borderRadius: "1rem",
        boxShadow: "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundBlendMode: "multiply",
        backgroundPosition: "top center",
        [theme.breakpoints.down("md")]: {
            width: 200,
        },
        [theme.breakpoints.down("sm")]: {
            width: 220,
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        paddingBottom: 32,
        paddingLeft: 31,
        paddingRight: 15,

        [theme.breakpoints.down("md")]: {
            width: "100%",
            paddingBottom: 15,
            paddingLeft: 15,
            paddingRight: 15,
        },
    },
    title: {
        textTransform: "uppercase",
        color: "#FFF",
        fontWeight: 900,
        lineHeight: "15px",
        fontSize: 13,
        letterSpacing: "1.25px",
        marginBottom: 6,
    },
    description: {
        color: "#FFF",
        fontWeight: 400,
        lineHeight: "18px",
        fontSize: 13,
        letterSpacing: "0.25px",
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
            lineHeight: "16px",
        },
    },
}));

export default function RecognitionModal({
    type,
    iframeKey,
    isLoading,
    riskType,
    appTheme
}) {
    const classes = useStyle();
    const classescard = cardStyle({ type });
    const { scanId } = useSelector((state) => state.clientInfo.recognitionData);
    const [openViewProblem, setOpenViewProblem] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const theme = useTheme();
    const iFrameRef = useRef(null);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const {
        quotation: { quotationScreenValues },
    } = useQuote(riskType.toUpperCase());
    // this is only for test in UAT liveness test
    const faceRecognitionURL = "scanFaceLiveness.html"; // 'scanFace.html'
    const steps = 7;
    const scanIdNumber =
        quotationScreenValues && quotationScreenValues.scanId
            ? quotationScreenValues.scanId
            : scanId;
    const url =
        type === "doc"
            ? `${settings.recognitionAPPUrlBase}/scanDocument.html?redirect=${settings.recognitionAPPUrlBase}/scanDocument.html`
            : `${
                  settings.recognitionAPPUrlBase
              }/${faceRecognitionURL}?redirect=${
                  settings.recognitionAPPUrlBase
              }/${faceRecognitionURL}&scanid=${scanIdNumber}&lvs=${
                  settings.isTestEnvironment === 'true' ? 1 : 0
              }`;
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleOnLoad = (evt) => {
        iFrameRef.current.contentWindow.postMessage({ message: 'setTheme', value: appTheme }, '*');
    };

    return (
        <>
            {activeStep ? (
                <>
                    {isLoading && (
                        <div
                            style={{
                                position: "fixed",
                                background: `rgba(0,0,0,.4)`,
                                zIndex: 100,
                                width: "100vw",
                                height: "100vh",
                                top: 0,
                            }}
                        />
                    )}
                    <Container>
                        <Stepper
                            className={classes.stepper}
                            alternativeLabel
                            activeStep={
                                type === "doc"
                                    ? activeStep === 1
                                        ? activeStep - 1
                                        : activeStep === 5
                                        ? activeStep - 1
                                        : activeStep
                                    : 4
                            }
                            connector={<DotConnector />}
                        >
                            {Array(steps)
                                .fill("")
                                .map((label, index) => (
                                    <Step key={label + index}>
                                        <StepLabel
                                            StepIconComponent={DotStepIcon}
                                        />
                                    </Step>
                                ))}
                        </Stepper>

                        <iframe
                            src={url}
                            width="100%"
                            height="100%"
                            allow="camera"
                            onLoad={handleOnLoad}
                            ref={iFrameRef}
                            style={{
                                border: "none",
                                minHeight: !isMobile ? "100vh" : 1000,
                                position: "relative",
                                zIndex: 201,
                            }}
                            scrolling="no"
                            key={iframeKey}
                        ></iframe>
                    </Container>
                </>
            ) : null}

            {!activeStep && (
                <Grid container direction="column">
                    <Stepper
                        className={classes.stepper}
                        alternativeLabel
                        activeStep={type === "doc" ? activeStep : 5}
                        connector={<DotConnector />}
                    >
                        {Array(steps)
                            .fill("")
                            .map((label, index) => (
                                <Step key={label + index}>
                                    <StepLabel
                                        StepIconComponent={DotStepIcon}
                                    />
                                </Step>
                            ))}
                    </Stepper>
                    <Container>
                        <Grid className={classes.box}>
                            <Typography variant="h4" className={classes.title}>
                                {type === "doc"
                                    ? `Validación de Documento de Identidad`
                                    : `Validación de Reconocimiento Facial`}
                            </Typography>
                            {type === "doc" ? (
                                <>
                                    <Typography
                                        variant="h6"
                                        className={clsx(
                                            classes.description,
                                            classes.instruction
                                        )}
                                    >
                                        Puedes hacerlo de dos formas:
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={clsx(classes.lista)}
                                    >
                                        <span className={classes.listNumber}>
                                            1
                                        </span>
                                        Toma la fotografía al documento desde tu
                                        dispositivo, haciendo click en el ícono
                                        de la cámara en la pantalla siguiente
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={clsx(classes.lista)}
                                    >
                                        <span className={classes.listNumber}>
                                            2
                                        </span>
                                        Sube una imagen en JPG o PNG de tu
                                        documento de identidad, frente y
                                        reverso, que esté almacenada en tu
                                        dispositivo, dando click en “Seleccionar
                                        Archivo”
                                    </Typography>
                                </>
                            ) : (
                                <Typography
                                    variant="h6"
                                    className={clsx(
                                        classes.description,
                                        classes.instruction
                                    )}
                                >
                                    Recuerda que la persona que realice este
                                    reconocimiento facial debe ser la misma que
                                    cargó su documento de identidad para esta
                                    contratación.
                                    <br />
                                    Te compartimos algunas indicaciones:
                                </Typography>
                            )}
                            {/* <Grid container spacing={2}> */}
                            <Carousel
                                slidesPerPage={isMobile ? 1 : 3}
                                itemWidth={isMobile ? undefined : 265}
                                offset={isMobile ? undefined : 15}
                                dots={isMobile}
                            >
                                <Card
                                    className={clsx(classescard.root, {
                                        [classescard.lightPhoto]:
                                            type === "doc",
                                        [classescard.recognizePhoto]:
                                            type === "face",
                                    })}
                                >
                                    <CardContent
                                        className={classescard.content}
                                    >
                                        <Typography
                                            variant="h3"
                                            className={classescard.title}
                                        >
                                            {type === "doc"
                                                ? `Buena Iluminación`
                                                : `UBICA TU ROSTRO`}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            className={classescard.description}
                                        >
                                            {type === "doc"
                                                ? ` Para fotografiar el documento, o
                                                verifica que la imagen que
                                                subirás sea legible`
                                                : ` Y muévelo de lado a lado cuando se te indique`}
                                        </Typography>
                                    </CardContent>
                                </Card>

                                <Card
                                    className={clsx(classescard.root, {
                                        [classescard.cleanPhoto]:
                                            type === "doc",
                                        [classescard.accessoryPhoto]:
                                            type === "face",
                                    })}
                                >
                                    <CardContent
                                        className={classescard.content}
                                    >
                                        <Typography
                                            variant="h3"
                                            className={classescard.title}
                                        >
                                            {type == "doc"
                                                ? `Espacio limpio`
                                                : `NO USES ACCESORIOS`}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            className={classescard.description}
                                        >
                                            {type == "doc"
                                                ? `Coloca el documento sobre una
                                                superficie limpia y sin otros
                                                objetos cerca.`
                                                : `Evita accesorios como gorra, lentes de sol y mascarilla`}
                                        </Typography>
                                    </CardContent>
                                </Card>

                                <Card
                                    className={clsx(classescard.root, {
                                        [classescard.lightPhoto]:
                                            type === "face",
                                        [classescard.scanPhoto]: type === "doc",
                                    })}
                                >
                                    <CardContent
                                        className={classescard.content}
                                    >
                                        <Typography
                                            variant="h3"
                                            className={classescard.title}
                                        >
                                            {type === "doc"
                                                ? `Enfoca el documento`
                                                : `BUENA ILUMINACIÓN`}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            className={classescard.description}
                                        >
                                            {type === "doc"
                                                ? `La imagen del documento debe
                                                estar bien enfocada (con texto
                                                legible)`
                                                : `Verifica que tu dispositivo capte bien tu rostro`}
                                        </Typography>
                                    </CardContent>
                                </Card>
                                {/* </Grid> */}
                            </Carousel>
                            {/* </Grid> */}
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                            >
                                {activeStep === steps.length - 1
                                    ? "Guardar"
                                    : "Siguiente"}
                            </Button>
                        </Grid>
                    </Container>
                </Grid>
            )}
            <ModalMessage
                openModal={openViewProblem}
                title={"Problema para vizualizar esta vista?"}
                text={
                    "Es posible que que necesites permisos para almacenar cookies, por favor activa el alamacenamiento de cookies de terceros y proba nuevamente."
                }
                children={
                    <div style={{ textAlign: "center" }}>
                        <img
                            src="/incognitoMode.png"
                            style={{ width: "100%", height: "auto" }}
                        />
                        <Button onClick={() => setOpenViewProblem(false)}>
                            cerrar
                        </Button>
                    </div>
                }
            />
        </>
    );
}
