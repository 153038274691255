import React from "react";
import { Router, Location } from "@reach/router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import './transitions.css';

const TransitionRouter = props => (
    <Location>
      {({ location }) => (
        <TransitionGroup>
          <CSSTransition key={location.key} classNames="my-node" timeout={550}>
            <Router location={location}>
              {props.children}
            </Router>
          </CSSTransition>
        </TransitionGroup>
      )}
    </Location>
  );


  export default TransitionRouter;
