import { useState, useEffect } from 'react';

const DataToSendHook = function (groups, riskType, _id) {
  const [dataToSend, setDataToSend] = useState(false);

  useEffect(() => {
    if (!dataToSend && groups) {
      const obje = {
        riskType,
        _id,
        groups: groups.map((group, index) => {
          return ({
            fields: group.fields.map((field) => ({
              order: field.order,
              name: field.name,
              _id: field._id,
              value: field.value,
              remote: field.remote
            })),
            _id: group._id,
            groupName: group.groupName,
            groupLabel: group.groupLabel
          });
        }),
      };

      setDataToSend(obje);
    }
  }, [groups]);

  const handleChangeDataToSend = (fieldIndex, groupIndex, value) => {
    setDataToSend({
      ...dataToSend,
      groups: [
        ...dataToSend.groups.slice(0, groupIndex),
        {
          ...dataToSend.groups[groupIndex],
          fields: [
            ...dataToSend.groups[groupIndex].fields.slice(0, fieldIndex),
            {
              ...dataToSend.groups[groupIndex].fields[fieldIndex],
              value
            },
            ...dataToSend.groups[groupIndex].fields.slice(fieldIndex + 1),
          ]
        },
        ...dataToSend.groups.slice(groupIndex + 1),
      ],
    })
  };

  return { dataToSend, handleChangeDataToSend };
}

export default DataToSendHook;