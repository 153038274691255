import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import LayoutError from '../../../components/layout/layoutError';
import LayoutLoading from '../../../components/layout/layoutLoading';
import QuoteTitle from '../../../components/quote/quoteTitle';
import QuoteList from '../../../components/quote/quoteList';
import QuoteSelect from '../../../components/quote/quoteSelect';
import QuoteInputs from '../../../components/quote/quoteInputs';
import { addTagCar, addTagContact, patchDataList, removeTagCar, removeTagContact, addTagAcc, removeTagAcc } from '../../../actions/quoteActions';
import { getBrands, getModels, getVersions, getYears, getAccesories } from '../../../actions/serviceActions';
import { dataAcc, dataAges, dataRegions } from '../../../utils/dataSet';

const useStyles = makeStyles((theme) => ({
    appContainer: {
        paddingLeft: 8,
        paddingRight: 8,
        width: '100%'
    }
}));

const CarInfoComponent = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { quote, uiData } = useSelector(state => state);
    const [currentAcc, setCurrentAcc] = useState({});
    const [showAcc, setShowAcc] = useState(false);
    let data = {};

    useEffect(() => {
        executeInitQuote();
    }, []);

    const executeInitQuote = async () => {
        await dispatch(getYears());
        await dispatch(getAccesories());
        if (!quote.dataList.val) {
            await dispatch(patchDataList(uiData.year.data));
        }
    };

    const handleSelect = async (val, item, type) => {
        const itemName = item.name ? item.name : item;
        const newTag = { method: val, label: itemName, type };
        if (type === 'car') {
            dispatch(addTagCar(newTag));
        } else {
            dispatch(addTagContact(newTag));
        }
        switch (val) {
            case 'dataYears':
                await dispatch(getBrands(item));
                dispatch(patchDataList(uiData.brand.data));
                break;
            case 'dataBrand':
                await dispatch(getModels(item._id, quote.tagsCar[0].label));
                dispatch(patchDataList(uiData.model.data));
                break;
            case 'dataModel':
                await dispatch(getVersions(item._id, quote.tagsCar[0].label));
                dispatch(patchDataList(uiData.version.data));
                break;
            case 'dataVersion':
                dispatch(patchDataList(dataAges));
                setCurrentAcc(uiData.accesorie.data[0]);
                setShowAcc(true);
                break;
            case 'dataAges':
                dispatch(patchDataList(dataRegions));
                break;
            case 'dataRegions':
                break;
            default:
                break;
        }
    };

    const handleCloseTag = ({ method, type }) => {
        if (type === 'car') {
            dispatch(removeTagCar(method));
        } else {
            dispatch(removeTagContact(method));
        }

        switch (method) {
            case 'dataYears':
                dispatch(patchDataList(uiData.year.data));
                setShowAcc(false);
                dataAcc.countAcc = 0;
                break;
            case 'dataBrand':
                dispatch(patchDataList(uiData.brand.data));
                setShowAcc(false);
                dataAcc.countAcc = 0;
                break;
            case 'dataModel':
                dispatch(patchDataList(uiData.model.data));
                setShowAcc(false);
                dataAcc.countAcc = 0;
                break;
            case 'dataVersion':
                dispatch(patchDataList(uiData.version.data));
                setShowAcc(false);
                dataAcc.countAcc = 0;
                break;
            case 'dataAges':
                dispatch(patchDataList(dataAges));
                break;
            case 'dataRegions':
                dispatch(patchDataList(dataRegions));
                break;
            default:
                break;
        }
    };

    const handleSelectAcc = (acc, item) => {
        const qtyAcc = dataAcc.countAcc;
        dataAcc.countAcc++;
        if (acc) {
            const newTag = { method: 'dataVersion', label: item.code, type: 'Acc', qtyAcc };
            dispatch(addTagAcc(newTag));
        }
        if (dataAcc.countAcc === uiData.accesorie.data.length) {
            setShowAcc(false);
        }
        setCurrentAcc(uiData.accesorie.data[dataAcc.countAcc]);
    };

    const handleCloseTagAcc = ({ qtyAcc }) => {
        dispatch(removeTagAcc(qtyAcc));
        dispatch(patchDataList(dataAges));
        setCurrentAcc(uiData.accesorie.data[qtyAcc]);
        setShowAcc(true);
        dataAcc.countAcc = qtyAcc;
    };

    /* const discartQuote = () => {
        dispatch(patchDataList(uiData.year.data));
        dispatch(removeQuote());
    }; */

    switch (quote.dataList.val) {
        case 'dataYears':
            data = uiData.year;
            break;
        case 'dataBrand':
            data = uiData.brand;
            break;
        case 'dataModel':
            data = uiData.model;
            break;
        case 'dataVersion':
            data = uiData.version;
            break;
        default:
            data = quote.dataList;
            break;
    }

    return (
        <Grid container justify="center" className={`${classes.appContainer}`}>
            { data.loading && <LayoutLoading /> }
            { data.error && <LayoutError /> }
            {
                !data.loading &&
          <Grid item xs={12} sm={9} md={7} className="cardAppContainer">
              <QuoteTitle title="Auto" tags={quote.tagsCar} handleCloseTag={handleCloseTag} />
              {
                  quote.tagsCar.length === 4 &&
              <QuoteTitle title="Accesorios" tags={quote.tagsAcc} handleCloseTag={handleCloseTagAcc} />
              }
              {
                  quote.tagsCar.length === 4 && !showAcc &&
              <QuoteTitle title="Sobre Vos" tags={quote.tagsContact} handleCloseTag={handleCloseTag} />
              }
              {
                  quote.tagsContact.length <= 1 && !showAcc &&
              <QuoteList data={data.data ? data.data : data} handleSelect={handleSelect} />
              }
              {
                  showAcc &&
              <QuoteSelect item={currentAcc} handleSelect={handleSelectAcc} />
              }
              {
                  quote.tagsContact.length === 2 &&
              <QuoteInputs />
              }
          </Grid>
            }
        </Grid>
    );
};

export default CarInfoComponent;
