import React, { useEffect} from "react";
import { Grid } from "@material-ui/core";
import { updateStatus } from "../../actions/paymentsLinksActions"

function SuccessPaymentLink({ external_reference }) {
    useEffect(() => {
      executeInit();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const executeInit = async () => {
        updateStatus(external_reference)
    };

    return ( 
        <Grid item xs={12}>
            <Grid style={{ textAlign: "center" }} >
                <h1 style={{ color: "green" }} >PAGO EXITOSO</h1>
                <h3 style={{ color: "grey" }} >Numero de referencia: {external_reference}</h3>
            </Grid>
        </Grid>
    )
}

export default SuccessPaymentLink;