import { createAsyncAction } from 'redux-promise-middleware-actions';
import settings from '../settings';
import { exeptionCodeResponse, validateResponse } from '../utils';

export const getUserConfigAPI = createAsyncAction('GET_USER_CONFIG', async () => {
  const res = await fetch(`${settings.authUrlBase}config/`)
    .then((response) => validateResponse(response))
    .catch((error) => {
      throw exeptionCodeResponse(error);
    });
  return res;
});