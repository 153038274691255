export const dataStructure = [
  {
    title: 'Tu precio al instante.',
    questions: ['dataAge', 'dataBrand', 'dataVersion']
  },
  {
    title: 'Sobre Vos...',
    questions: ['dataYears', 'dataRegions', 'dataPhone']
  }
];

export const dataYears = {
  title: 'Seleccionar año del vehiculo',
  content: [],
  type: 'car',
  val: 'dataYears'
};

export const dataBrand = {
  title: 'Seleccionar marca del vehiculo',
  content: [],
  type: 'car',
  val: 'dataBrand'
};

export const dataModel = {
  title: 'Seleccionar modelo del vehiculo',
  content: [],
  type: 'car',
  val: 'dataModel'
};

export const dataVersion = {
  title: 'Seleccionar version del vehiculo',
  content: [],
  type: 'car',
  val: 'dataVersion'
};

export const dataAges = {
  title: 'Que edad tienes?',
  content: [ 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, '30+' ],
  type: 'contact',
  val: 'dataAges'
};

export const dataRegions = {
  title: 'En que zona vivis?',
  content: ['Capital', 'Norte', 'Sure', 'Este', 'Oeste', 'Otra'],
  type: 'contact',
  val: 'dataRegions'
};

export const dataAcc = {
  countAcc: 0
};
