import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
} from "@material-ui/core";
import { navigate } from "@reach/router";

const useStyles = makeStyles((theme) => ({
    dialog: {
        borderRadius: 20,
        textAlign: "center",
    },
    content: {
        padding: "1em 5em 3em",
        fontSize: 18,
    },
    title: {
        paddingTop: "3rem",
        fontSize: 24,
        textTransform: "uppercase",
    },
    action: {
        padding: "8px 5em 3em",
    },
}));

const AlertMessage = ({
    type,
    message,
    open,
    handleClose,
    redirect = null,
}) => {
    const classes = useStyles({ type });
    const colors = {
        error: "#EB5757",
        info: "#64b5f6",
        warning: "#F2994A",
        success: "#6FCF97",
    };
    return (
        <>
            {message && (
                <Dialog
                    open={open}
                    onClose={() => {
                        handleClose();
                        if (redirect !== null) {
                            navigate(redirect);
                        }
                    }}
                    classes={{ paper: classes.dialog }}
                    maxWidth={"xs"}
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        style={{ color: colors[type] }}
                        classes={{ root: classes.title }}
                    >
                        {type}!
                    </DialogTitle>
                    <DialogContent classes={{ root: classes.content }}>
                        <Typography gutterBottom>{message}</Typography>
                    </DialogContent>
                    <DialogActions className={{ root: classes.action }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                handleClose();
                                if (redirect === "refresh") {
                                    window.history.go(0);
                                }
                                if (redirect !== null) {
                                    navigate(redirect);
                                }
                            }}
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default AlertMessage;
