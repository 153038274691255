import el from "date-fns/esm/locale/el/index.js";

const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

const isValidEmail = (value) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

const doesLeadAndQuoatationMatch = (lead, quotation, leadId) => {
    try {
        return (
            !!lead.data._id &&
            leadId == lead.data._id &&
            lead.data.quotation._id == quotation._id
        );
    } catch (error) {
        return false;
    }
};

const validateResponse = (response) => {
    if (response.ok) {
        // return response.json();
        return response.text().then(function (text) {
            return text ? JSON.parse(text) : {};
        });
    } else {
        throw exeptionCodeResponse();
    }
};

const validateListResponse = async (response) => {
    if (response.ok) {
        return response.json().then(function (data) {
            console.log('Data', data);
            if (data.EsValido) {
                return data.DatosAdicionales.map(d => {
                    const obj = {};
                    Object.keys(d).forEach(key => {
                        if (key.toLowerCase() === 'description' || key.toLowerCase() === 'descripcion') {
                            obj.name = d[key];
                        }

                        if (key.toLowerCase().includes('cod')) {
                            obj._id = d[key];
                        }
                    });

                    return obj;
                });
            } else {
                if (data.DatosAdicionales) {
                    throw exeptionCodeResponse();
                } else {
                    return data.map(d => {
                        const obj = {};
                        Object.keys(d).forEach(key => {
                            if (key.toLowerCase() === 'description' || key.toLowerCase() === 'descripcion') {
                                obj.name = d[key];
                            }

                            if (key.toLowerCase().includes('cod')) {
                                obj._id = d[key];
                            }
                        });

                        return obj;
                    });
                }
            }
        });
    } else {
        throw exeptionCodeResponse();
    }
};

const exeptionCodeResponse = (error = 'error') => {
    return error;
};

const validateResponseWithError = async (response) => {
    if (response.ok) {
        return response.json();
    } else {
        const error = await response.json().then((text) => text.error);
        throw exeptionCodeResponse(error);
    }
};

export {
    toTitleCase,
    isValidEmail,
    doesLeadAndQuoatationMatch,
    validateResponse,
    exeptionCodeResponse,
    validateResponseWithError,
    validateListResponse
};
