import React, { useEffect } from "react";
import moment from "moment";
import {
    Box,
    Button,
    Container,
    Grid,
    Step,
    StepLabel,
    Stepper,
    SvgIcon,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "../../../styles/done.css";
import {
    DotConnector,
    DotStepIcon,
} from "../../../components/styled-stepper/styleDotStepper";
import { generalsIcons } from "../../../utils/icons";
import { Home } from "@material-ui/icons";
import settings from "../../../settings";
import { navigate } from "@reach/router";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: theme.palette.primary.main,
        height: "calc(100vh - 57px)",
    },
    container: {
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
        justifyContent: "center",
        textAlign: "center",
    },
    iconContainer: {
        width: "100%",
    },
    title: {
        fontSize: 34,
        lineHeight: "36px",
        color: theme.palette.primary.main,
        fontWeight: 400,
        marginBottom: 32,
    },
    description: {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: "24px",
        color: theme.palette.secondary.main,
        marginBottom: 28,
    },
    button: {
        width: "100%",
        padding: "19px 15px",
        maxWidth: 231,
    },
    box: {
        borderRadius: 20,
        backgroundColor: "white",
        padding: "78px 47px",
        marginBottom: 50,
        [theme.breakpoints.down("xs")]: {
            padding: "30px 20px",
        },
    },
    icon: {
        width: 108,
        height: 102,
        fill: "transparent",
        marginBottom: 26,
    },
    //
    iconButton: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 18,
        padding: 10,
        color: theme.palette.secondary.main,
        width: "100%",
        display: "flex",
        justifyContent: "revert",
        "& span": {
            fontSize: 16,
            '& p': {
                color: '#fff',
            }
        },
        "&:hover": {
            backgroundColor:
                theme.palette.variants && theme.palette.variants.variant3,
            "& > span > span": {
                backgroundColor:
                    theme.palette.variants && theme.palette.variants.variant1,
            },
        },
    },
    buttonTitle: {
        fontSize: 13,
        paddingLeft: 10,
        width: "70%",
        textAlign: "initial",
    },
    contact: {
        fontSize: 12,
        lineHeight: "20px",
        fontWeight: 300,
        letterSpacing: "0.25px",
    },
    iconBox: {
        backgroundColor: theme.palette.background.paper,
        padding: "9px 11px",
        borderRadius: 30,
        display: "flex",
    },
}));

const CongratsComponentApproved = () => {
    const classes = useStyles();
    const steps = 7;

    useEffect(() => {
        // clean all
        return () => {
            localStorage.clear();
            sessionStorage.clear();
        };
    }, []);

    const goToHome = () => {
        navigate('/')
    }

    return (
        <Container>
            <Stepper
                style={{ backgroundColor: "transparent" }}
                alternativeLabel
                activeStep={6}
                connector={<DotConnector />}
            >
                {Array(steps)
                    .fill("")
                    .map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={DotStepIcon} />
                        </Step>
                    ))}
            </Stepper>
            <Grid className={classes.box}>
                <SvgIcon className={classes.icon} viewBox="0 0 108 102">
                    {generalsIcons["quoteCompleted"]}
                </SvgIcon>
                <Typography variant="h4" className={classes.title}>
                    ¡Felicitaciones!
                </Typography>
                <Typography variant="h6" className={classes.description}>
                    Su SOAT se ha generado exitosamente
                    <br />
                    En las próximas horas le compartiremos su SOAT por correo electrónico.
                </Typography>
                <Grid item sm={4} xs={12}>
                    <Button
                        variant="contained"
                        className={classes.iconButton}
                        onClick={goToHome}
                    >
                        <Box className={classes.iconBox}>
                            <Home />
                        </Box>
                        <Typography className={classes.buttonTitle}>
                            Volver
                        </Typography>
                    </Button>
                </Grid>
                {/*<Grid container spacing={3} style={{ marginTop: 49 }}>
                    <Grid item sm={4} xs={12}>
                        <Button
                            variant="contained"
                            className={classes.iconButton}
                        >
                            <Box className={classes.iconBox}>
                                <Mail />
                            </Box>
                            <Typography className={classes.buttonTitle}>
                                info@abank.com
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Button
                            variant="contained"
                            className={classes.iconButton}
                        >
                            <Box className={classes.iconBox}>
                                <Phone />
                            </Box>
                            <Typography className={classes.buttonTitle}>
                                0800 5548-2639
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Button
                            variant="contained"
                            className={classes.iconButton}
                        >
                            <Box className={classes.iconBox}>
                                <WhatsApp />
                            </Box>
                            <Typography className={classes.buttonTitle}>
                                +54 11 6598 7435
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>*/}
            </Grid>
        </Container>
    );
};

export default CongratsComponentApproved;
