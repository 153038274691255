import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderTextField, renderCheckbox } from './renderInputsForm';
import QuoteBottomButtons from '../quote/quoteBottomButtons';

const validate = values => {
  const errors = {};
  const requiredFields = [ 'patent' ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Requerido';
    }
  });
  return errors;
}

const FormCar = ({ handleSubmit, pristine, submitting, handleContinue }) => {

  return (
    <form onSubmit={handleSubmit(handleContinue)}>
      {/* <Field name="patent" label="Patente" component={renderTextField} />
      <Field name="numberchasis" label="Número de chasis (opcional)" component={renderTextField} />
      <Field name="numbermotor" label="Número de motor (opcional)" component={renderTextField} /> */}
      {/* <Field name="personal" component={renderCheckbox} label="Uso personal" /> */}
      <p className="form__description">Los datos del auto será validado durante la inspección previa.</p>
      <QuoteBottomButtons label="Siguiente" type="submit" disabled={submitting} />
    </form>
  );
};

export default reduxForm({ form: 'CarForm', destroyOnUnmount: false, validate })(FormCar);
