import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  subTitle: {
    color: '#4B4B4B',
    fontSize: '20px',
    lineHeight: '24px',
    margin: '15px 0px'
  },
}));

const SubTitle = ({ label }) => {
    const classes = useStyles();
    return (
        <h3 className={classes.subTitle} >{label}</h3>
    );
}

export default SubTitle;