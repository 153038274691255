export const brandQuestions = [
  {
    question: '¿Qué es iConnectance?',
    answer: 'iConnectance es una plataforma de venta de seguros multicanal que permite incrementar las ventas de una compañia de seguros'
  },
  {
    question: '¿Qué hace único a iConnectance?',
    answer: ''
  },
  {
    question: '¿Es seguro comprar por Internet?',
    answer: ''
  },
  {
    question: '¿Donde se guardan mis datos?',
    answer: ''
  }
];

export const buyQuestions = [
  {
    question: '¿Cómo puedo contratar un seguro?',
    answer: 'Solo tienes que seleccionar el producto que desees, una vez que hayas cotizado, e ingresar algunos datos mínimos para contratar. Puedes pagar con cualquier tarjeta de crédito, transferencia bancaria y hasta en efectivo.'
  },
  {
    question: '¿Qué documentos necesito para contratar un seguro?',
    answer: 'Solamente necesitas tu número de documento y completar las preguntas que se presentan en cada producto.'
  },
  {
    question: '¿Necesito estar registrado o tener cuenta para poder contratar un seguro?',
    answer: 'No, en cualquier momento puedes contratar. Al finalizar tu compra recibirás un email con un link para activar tu cuenta poder ingresar en tu sesión. De esta forma, podrás consultar el estado de tus productos entre otras acciones.'
  },
  {
    question: 'El sistema no me permite continuar con mi compra. ¿Qué puede estar sucediendo?',
    answer: 'Revisa si en la parte superior de la pantalla se muestra algún mensaje, algunos productos no cumplen las políticas de contratación. Si el problema persiste, puedes comunicarte con nosotros a través de nuestra sección Contáctanos.'
  },
  {
    question: '¿Qué pasa si ingresé mis datos, pero no completé la compra?',
    answer: 'Para tu comodidad, cuando abandonas una cotización te enviaremos automáticamente un correo con un link que te permitirá regresar al sitio web y retomar tu cotización. Por esta razón, debes ingresar un correo válido.'
  },
  {
    question: 'Se me negó la contratación del seguro. ¿Qué puede estar sucediendo?',
    answer: 'Pueden existir varias razones por las cuales no hayas podido contratar, como por ejemplo: <ul><li>Excediste el límite de suma asegurada de algún producto.</li><li>Excediste el límite de pólizas por producto.</li><li>Tienes una reclamación existente o en proceso.</li><li>El producto no se encuentra dentro de un producto asegurable.</li></ul>Puedes comunicarte con nosotros a través de la sección Contactanos para más información.'
  },
  {
    question: '¿A partir de cuándo estaré cubierto?',
    answer: 'Estarás cubierto a partir del momento en que recibamos el pago, se haya realizado la inspección y hayas recibido tu póliza.'
  },
  {
    question: '¿Cómo puedo realizar un pago?',
    answer: 'Todos los pagos los puedes realizar directamente en iConnectance.com.'
  },
  {
    question: '¿Cuál es mi fecha límite de pago?',
    answer: 'Tu fecha límite de pago dependerá del día en el que hayas contratado la póliza y la frecuencia de pago que hayas seleccionado. Consulta el plan de pagos de tu póliza para más información.'
  },
  {
    question: '¿Cómo puedo actualizar mi método de pago?',
    answer: 'Para cambiar tu método de pago o cambiar a otra tarjeta puedes comunicarte con nosotros a través de la sección Contáctanos.'
  }
];

export const coverQuestions = [
  {
    question: '¿Qué pasa si puse mal la información en mi póliza?',
    answer: 'Si necesitas corregir un dato de tu póliza, inicia sesión y selecciona editar la póliza que contrataste. Podrás modificar tu información de contacto, así como modificar beneficiarios.'
  },
  {
    question: '¿Tiene algún costo hacer modificaciones en mi póliza?',
    answer: 'No, pero si realizas cambios a la Suma Asegurada o al Plazo de Cobertura puede existir un ajuste en la nueva prima a pagar.'
  },
  {
    question: '¿Qué tengo que hacer para renovar mi póliza?',
    answer: 'Todas las pólizas se renuevan automáticamente, a menos que decidas cancelar tu cobertura.'
  },
  {
    question: '¿Qué pasa si no quiero renovar mi póliza?',
    answer: 'Si no quieres renovar tu póliza debes cancelarla iniciando sesión. Selecciona la póliza correspondiente y da clic en Cancelar.'
  },
  {
    question: '¿Hay alguna penalización por cancelar mi póliza?',
    answer: 'No existe ninguna multa o penalización por cancelación y puedes hacerlo en cualquier momento.'
  },
];

export const claimQuestions = [
  {
    question: '¿Qué hago si tengo un reclamo?',
    answer: 'Antes de presentar un reclamo, verifica que cumples con los requisitos y documentos que se indican en tu póliza. Si cumples con todo, inicia sesión y busca la opción de Reclamos.'
  },
  {
    question: '¿Qué diferencia hay entre beneficiario y asegurado?',
    answer: 'El asegurado o titular es el cliente que ha adquirido una póliza. En algunos productos, el beneficiario puede ser designado por el asegurado y es quien podrá cobrar en casos de siniestros.'
  },
  {
    question: '¿Cómo presento una reclamación si no he activado mi cuenta?',
    answer: 'Toda reclamación debe hacerse a través del portal iniciando sesión, si necesitas que un oficial de servicio te ayude con la activación de tu cuenta, comunícate con nosotros a través de la sección Contáctanos.'
  }
];

export const userQuestions = [
  {
    question: '¿Cómo puedo consultar / descargar documentos relacionados a mi póliza?',
    answer: 'Al iniciar sesión podrás ver todas tus pólizas activas y antiguas. Al seleccionar una póliza podrás ver su información, descargar e imprimir la póliza y facturas.'
  }
];
