import {
  GET_USER_CONFIG_PENDING,
  GET_USER_CONFIG_FULFILLED,
  GET_USER_CONFIG_REJECTED,
} from "../actions/types";

const initialState = {
  data: {},
  loading: true,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
      case GET_USER_CONFIG_PENDING:
          return {
              ...state,
              error: false,
          };
      case GET_USER_CONFIG_FULFILLED:
          return {
              ...state,
              data: action.payload,
              loading: false,
              error: false,
          };
      case GET_USER_CONFIG_REJECTED:
          return {
              ...state,
              data: {},
              loading: false,
              error: true,
          };
      default:
          return state;
  }
}