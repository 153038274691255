import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Button, Card, CardContent, Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import LayoutFooter from '../components/layout/layoutFooter';
import {
    HomeIlustration,
    IconOne,
    IconTwo,
    IconThree,
    IconFour,
    Doubts,
    Referrals
} from '../assets/customIcons';
import HomeImageBottom from '../assets/images/headers/headerBottom.svg';
import CardGrid from '../components/card-grid/cardGrid';
import '../styles/home.css';
import News from './news/news';

export const useStyles = makeStyles(theme => ({
    arrowNews: {
        height: 40,
        paddingLeft: 16,
        paddingRight: 16,
        width: 40
    },
    cardNews: {
        margin: '2px 30px 20px 0px',
        height: 350
    /* [theme.breakpoints.down('lg')]: {
      minHeight: 300
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 410
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 315
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 'auto'
    } */
    },
    cardNewsContent: {
        height: 220,
        paddingTop: '40px'
    /* [theme.breakpoints.down('lg')]: {
      minHeight: 180
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 295
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 195
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 'auto'
    } */
    },
    cardService: {
        height: '336px',
        width: '322px',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: '360px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '360px'
        }
    },
    cardServiceContent: {
        [theme.breakpoints.down('sm')]: {
            minHeight: 185
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: 'auto'
        }
    },
    cardText: {
        fontFamily: '\'Roboto\', sans-serif',
        color: '#4B4B4B',
        padding: 8,
        margin: 0,
        fontSize: 16,
        letterSpacing: '0.25px',
        lineHeight: '20px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            height: '50px',
            margin: '0',
            padding: '0px 8px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '50px',
            margin: '0',
            padding: '0px 8px'
        }
    },
    cardContainerTitle: {
        color: theme.palette.primary.dark,
        textAlign: 'center',
        padding: '0px 16px'
    },
    cardTitle: {
        color: theme.palette.secondary.main,
        textTransform: 'capitalize',
        textAlign: 'center',
        fontFamily: 'Museo Sans , Fallback, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '24px',
        lineHeight: '24px',
        margin: '0px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '48px'
        }
    },
    subCardTitle: {
        color: '#4b4b4b',
        textAlign: 'center',
        fontFamily: 'Museo Sans , Fallback, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '34px',
        lineHeight: '36px',
        margin: '0px',
        [theme.breakpoints.down('xs')]: {
            height: '48px'
        }
    },
    columnNews: {
        alignItems: 'center',
        display: 'flex',
        padding: 16,
        paddingTop: 0,
        ['@media (max-width:400px)']: { // eslint-disable-line no-useless-computed-key
            padding: 4
        }
    },
    containerCards: {
        paddingLeft: 128,
        paddingRight: 128,
        paddingBottom: 70,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 16,
            paddingRight: 16
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 8,
            paddingRight: 8,
            flexWrap: 'nowrap',
            justifyContent: 'inherit',
            overflow: 'scroll'
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 8,
            paddingRight: 8,
            flexWrap: 'nowrap',
            justifyContent: 'inherit',
            overflow: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none'
            }
        },
        '& .BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected': {
            backgroundColor: `${theme.palette.primary.main} !important`
        }
    },
    mainOverview: {
        [theme.breakpoints.up('md')]: {
            padding: '1% 3% 5% 3%'
        },
        [theme.breakpoints.down('md')]: {
            padding: 10
        }
    },
    containerChips: {
        marginTop: '2%',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    sideOverview: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    containerCardsSection1: {
        paddingLeft: 128,
        paddingRight: 128,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 16,
            paddingRight: 16
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 8,
            paddingRight: 8
        }
    },
    containerCarousel: {
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            overflowX: 'scroll'
        }
    },
    containerCardButton: {
        textAlign: 'center',
        width: '100%'
    },
    containerHome: {
        // position: 'absolute',
        top: 700,
        width: '100%',
        [theme.breakpoints.down('md')]: {
            top: 600
        },
        [theme.breakpoints.down('xs')]: {
            top: 250
        }
    },
    dot: {
        margin: 8
    },
    dotContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 32,
        padding: 16
    },
    headerHome: {
        background: '#F7F4FD',
        display: 'flex',
        height: 880,
        justifyContent: 'flex-end',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: -1,
        [theme.breakpoints.down('md')]: {
            height: 800
        },
        [theme.breakpoints.down('md')]: {
            height: 860
        },
        [theme.breakpoints.down('xs')]: {
            height: 630
        }
    },
    headerImageBottomHome: {
        backgroundImage: `url(${HomeImageBottom})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    homeSection1: {
        color: '#4B4B4B',
        textAlign: 'center',
        fontFamily: 'Museo Sans , Fallback, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '24px',
        lineHeight: '24px',
        marginTop: '24px',
        marginBottom: '6px'
    },
    homeSectionDescription: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#4B4B4B',
        textAlign: 'center',
        letterSpacing: '0.25px',
        padding: '0px 50px'
    },
    homeSection1Box: {
        padding: 8
    },
    homeSection1Image: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    homeSectionTitle: {
        color: '#4B4B4B',
        fontSize: 48,
        fontFamily: 'WorkSans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        marginBlockStart: 0,
        marginBlockEnd: 0,
        paddingTop: 100,
        paddingBottom: 100,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 64,
            paddingBottom: 64,
            fontSize: 34
        },
        maxWidth: '1440px'
    },
    homeSection2Button: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 30
    },
    homeSection2Card: {
        borderBottom: theme.palette.primary.main,
        // borderBottomStyle: 'solid',
        // borderBottomWidth: 14,
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            minHeight: 190
        },
        [theme.breakpoints.down('md')]: {
            minHeight: 185
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: 250,
            width: 'auto'
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: 'auto'
        }
    },
    homeSection2Container: {
        paddingTop: 100,
        paddingLeft: 128,
        paddingRight: 128,
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 64,
            paddingRight: 64
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: 16,
            paddingRight: 16
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 16,
            paddingLeft: 8,
            paddingRight: 8
        }
    },
    media: {
        width: '195px',
        objectFit: 'fill',
        padding: 7,
        height: '146px'
    },
    mediaCard: {
        display: 'flex',
        justifyContent: 'center'
    },
    titleHome: {
        fontFamily: 'Museo Sans , Fallback, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '60px',
        lineHeight: '72px',
        letterSpacing: '-0.5px',
        margin: '0',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    subtitleHome: {
        fontSize: 20,
        fontFamily: 'Roboto, sans-serif',
        color: theme.palette.primary.main,
        left: '107px',
        position: 'absolute',
        top: 290,
        width: 300,
        [theme.breakpoints.down('sm')]: {
            left: 30
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    cardGrid: {
        padding: '16px 13px 0px 13px',
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block',
            position: 'relative'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'inline-block',
            position: 'relative'
        }
    },
    containerMobileTitle: {
        marginTop: '100px',
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    titleMobile: {
        fontFamily: 'Museo Sans , Fallback, sans-serif',
        fontSize: 34,
        lineHeight: '36px',
        textAlign: 'center',
        color: '#4C1DB7',
        fontWeight: 'normal',
        fontStyle: 'normal',
        margin: 0,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    descriptionMobile: {
        letterSpacing: '0.15px',
        color: '#4B4B4B',
        fontSize: 16,
        lineHeight: '24px',
        padding: '0px 15vh',
        textAlign: 'center',
        margin: 0,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    svgIcon: {
        color: theme.palette.primary.main,
        width: 'auto',
        height: 'auto'
    },
    headerPage: {
        backgroundColor: '#F6F6F6',
        display: 'flex',
        height: '450px',
        justifyContent: 'flex-end',
        width: '100%',
        zIndex: -1,
        [theme.breakpoints.down('xs')]: {
            height: 280
        },
        maxWidth: '1440px',
        paddingTop: '20px'
    },
    titlePage: {
        color: '#141B6A',
        fontSize: 50,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontFamily: 'Museo Sans , Fallback, sans-serif',
        width: 'auto',
        letterSpacing: '-0.5px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
            top: 170,
            width: 330
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 25,
            left: 24,
            top: 80,
            width: 260
        }
    },
    subtitlePage: {
        fontSize: 18,
        fontFamily: 'Roboto, sans-serif',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            left: 30
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
}));

const Home = ({ location }) => {
    const classes = useStyles();

    const { data } = useSelector((state) => state.config);
    let colorPrimary = '';
    let colorSecondary = '';
    let colorSecondaryVariant = '';

    if (data.theme) {
        colorPrimary = data.theme.primary.main;
        colorSecondary = data.theme.secondary.main;
        colorSecondaryVariant = data.theme.secondary.variant5;
    }

    useEffect(() => {
        if (location.hash) {
            const el = document.getElementById(location.hash.substring(1));
            el.scrollIntoView();
        }
    }, [location]);

    return (
        <Grid container className="page">

            <Grid item xs={12} sm={6} >
                <Grid container className="titleContainer">
                    <div >
                        <div className={classes.titlePage}>El mejor seguro</div>
                        <div className={classes.titlePage}>del mercado</div>
                        <div className={classes.subtitlePage}>
                Contratá en 1 minuto el mejor
                        </div>
                        <div className={classes.subtitlePage}>
                seguro con la mejor asistencia.
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid container>
                    <div className={classes.headerPage}>
                        <HomeIlustration
                            colorPrimary={colorPrimary}
                            colorSecondary={colorSecondary}
                            colorSecondaryVariant={colorSecondaryVariant}
                            classes={{ root: classes.svgIcon }}
                        />
                    </div>
                </Grid>
            </Grid>

            <div className={classes.containerHome}>
                {/* BEGIN QUOTES TYPES SECTION */}
                <div className={classes.containerMobileTitle}>
                    <h2 className={classes.titleMobile}>100% en línea</h2>
                    <p className={classes.descriptionMobile}>Difiere tu seguro hasta 12 meses sin intereses</p>
                </div>
                <CardGrid />
                {/* END QUOTES TYPE SECTION */}
                {/* BEGIN ABOUT SECTION */}
                <h3 className={classes.homeSectionTitle} id="why-are-unique" name="why-are-unique">
                    ¿Por qué somos únicos?
                </h3>
                <Grid container justify="space-around" alignItems="center" className={classes.containerCardsSection1}>
                    <Grid item xs={12} sm={6} md={3} className={classes.homeSection1Box}>
                        <div className={classes.homeSection1Image}>
                            <IconOne
                                colorPrimary={colorPrimary}
                                colorSecondaryVariant={colorSecondaryVariant}
                                classes={{ root: classes.svgIcon }}
                            />
                        </div>
                        <h5 className={classes.homeSection1}>Claros</h5>
                        <p className={classes.homeSectionDescription}>Te decimos lo que te cubre y lo que no</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} className={classes.homeSection1Box}>
                        <div className={classes.homeSection1Image}>
                            <IconTwo
                                colorPrimary={colorPrimary}
                                colorSecondaryVariant={colorSecondaryVariant}
                                classes={{ root: classes.svgIcon }}
                            />
                        </div>
                        <h5 className={classes.homeSection1}>Flexibles</h5>
                        <p className={classes.homeSectionDescription}>Seguro adaptado a tus necesidades</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} className={classes.homeSection1Box}>
                        <div className={classes.homeSection1Image}>
                            <IconThree
                                colorPrimary={colorPrimary}
                                colorSecondaryVariant={colorSecondaryVariant}
                                classes={{ root: classes.svgIcon }}
                            />
                        </div>
                        <h5 className={classes.homeSection1}>Simples</h5>
                        <p className={classes.homeSectionDescription}>Requisitos sencillos lorem ipsum</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} className={classes.homeSection1Box}>
                        <div className={classes.homeSection1Image}>
                            <IconFour
                                colorPrimary={colorPrimary}
                                colorSecondaryVariant={colorSecondaryVariant}
                                classes={{ root: classes.svgIcon }}
                            />
                        </div>
                        <h5 className={classes.homeSection1}>Bonificaciones</h5>
                        <p className={classes.homeSectionDescription}>Te decimos lo que cubre y lo que no</p>
                    </Grid>
                </Grid>
                {/* END ABOUT US SECTION */}
                {/* BEGIN CONTACT AND REFER SECTION */}
                <Grid container justify="space-around" alignItems="center" className={classes.homeSection2Container}>
                    <Grid item xs={12} sm={6} className={classes.cardGrid}>
                        <Card className={classes.homeSection2Card}>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <div className={classes.homeSection1Image}>
                                            <Doubts
                                                colorPrimary={colorPrimary}
                                                colorSecondary={colorSecondary}
                                                colorSecondaryVariant={colorSecondaryVariant}
                                                classes={{ root: classes.svgIcon }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} >
                                        <Typography variant="h5" className={classes.subCardTitle}>
                                            ¿Dudas?
                                        </Typography>
                                        <p className={classes.cardText}>
                                            Si tenés consultas sobre nuestros servicios no dudes en comunicarte.
                                        </p>
                                        <div className={classes.homeSection2Button}>
                                            <Link to="/faq">
                                                <Button variant="contained" color="primary">
                                                    comunicarse
                                                </Button>
                                            </Link>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.cardGrid}>
                        <Card className={classes.homeSection2Card}>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <div className={classes.homeSection1Image}>
                                            <Referrals
                                                colorPrimary={colorPrimary}
                                                colorSecondary={colorSecondary}
                                                colorSecondaryVariant={colorSecondaryVariant}
                                                classes={{ root: classes.svgIcon }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Typography variant="h5" className={classes.subCardTitle}>
                                            Referidos
                                        </Typography>
                                        <p className={classes.cardText}>
                                            Referí a un amigo y te regalamos una cuota.
                                        </p>
                                        <div className={classes.homeSection2Button}>
                                            <Link to="/refer">
                                                <Button variant="contained" color="primary">
                                                    conocer más
                                                </Button>
                                            </Link>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {/* END CONTACT AND REFER SECTION */}
                {/* BEGIN NEWS SECTION */}
                <h3 className={classes.homeSectionTitle} id="newsletter" name="newsletter">Noticias</h3>
                <News />
                {/* END NEWS SECTION */}
                <LayoutFooter />
            </div>
        </Grid>
    );
};

export default Home;
