import { GET_WITH_REMOTE } from '../actions/dynamicActions';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  data: [],
  error: null
};

const providers = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case `${GET_WITH_REMOTE}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.payload
      };
    case `${GET_WITH_REMOTE}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        error: null
      };
    case `${GET_WITH_REMOTE}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        data: action.payload
      };

    default:
      return state;
  }
};

export default providers;
