import React, { useState } from 'react';
import { Grid, Button, Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useInputValue } from '../../hooks/useInputValue';
import { sendLinkQuotationMail } from '../../actions/quoteActions';
import ClearIcon from '@material-ui/icons/Clear';
import Email from '../inputs/email';
import { useQuote } from '../../hooks/useQuote';

const useStyles = makeStyles(theme => ({
    cancel: {
        display: 'grid',
        justifyContent: 'end'
    },
    cancelButton: {
        background: theme.palette.primary.main,
        borderRadius: '50%',
        color: theme.palette.primary.contrastText,
        cursor: 'pointer',
        height: 24,
        width: 24
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    button: {
        width: '100%',
        border: 'none',
        backgroundColor: '#ffffff',
        boxShadow: 'none',
        color: 'rgba(0, 0, 0, 0.38)'
    }
}));

const ModalRecord = ({ riskType }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { quotation } = useQuote(riskType.toUpperCase());
    const mail = useInputValue(quotation.mail);

    const handleModal = () => {
        setOpen(!open);
    };

    
    const handleSave = async () => {
        let form = {};

        const formInputs = document.querySelectorAll('input');
        const lengthForm = document.querySelectorAll('input').length;

        for(let i = 0; i < lengthForm; i++) {
            if (formInputs[i].type !== 'checkbox') {
                if (formInputs[i].name !== '' && formInputs[i].value !== '') {
                    form = Object.assign({}, form, {[formInputs[i].name]: formInputs[i].value});
                }
            } else {
                form = Object.assign({}, form, {[formInputs[i].name]: formInputs[i].checked}); 
            }
        };

        sendLinkQuotationMail(quotation._id, mail.value, form);
        setOpen(!open);
    };

    return (
        <Grid item xs={12}>
            <Button variant="contained" className={classes.button} onClick={() => handleModal()}>
        Guardar
            </Button>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.cancel}>
                            <div className={classes.cancelButton} onClick={handleModal}>
                                <ClearIcon />
                            </div>
                        </div>
                        <h2 className="textTag">Guardar cotización</h2>
                        <p className="textTag">La cotización te la enviaremos a tu mail.</p>
                        <p className="textTag">Puedes recuperar la misma, para continuar,
            haciendo clic en el link que te llega a tu mail</p>
                        <Email {...mail} />
                        <Grid container justify="flex-end">
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!(mail.value && !mail.error)}
                                onClick={() => handleSave()}>
                Enviar
                            </Button>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </Grid>
    );
};

export default ModalRecord;
