import React from 'react';
import QuoteTitle from '../../quote/quoteTitle';
import Handlebars from 'handlebars';
import moment from 'moment';

const getLabelByType = (field) => {
  switch (field.dataType) {
    case 'string': {
      const fieldValue = field.remote && field.options.length > 0 ? field.options.find(opt => opt._id === field.value).name : field.value;
      return fieldValue;
    }
    case 'bool':
      return field.value ? field.options[0] : field.options[1];
    case 'numeric':
      return field.mask !== 'Moneda' ? field.value : `$${new Intl.NumberFormat("en-US").format(field.value.includes(',') ? field.value.replaceAll(',', '') : field.value)}`;
    case 'date':
    case 'birthdate':
      return field.controlType.includes('birthdate') ? moment().diff(field.value, 'years') : moment(field.value).format('DD/MM/YYYY');
    case 'object':
      return field.mask !== 'Moneda' ? field.value : `$${new Intl.NumberFormat("en-US").format(field.value)}`;
    case 'array':
      return field.value.join(' | ');
    default:
      console.log('Invalid type!');
      break;
  }
}

const TagsNavigator = ({
  index,
  groups,
  handleCloseTag,
  handleFillExtraFields,
  fillingExtraFields,
  extraFields,
  handleDeleteExtraField,
  currentGroupIndex,
  wizard,
  readOnly,
  isLast,
  quotationScreenOptions,
  handleDeleteExtraFieldTag
}) => {
  const multipleGroups = [];
  let fieldsToTemplate = [];
  const steps = groups.map((group) => {
    const fields = group.fields.filter(field => field.value !== null);
    fieldsToTemplate = fieldsToTemplate.concat(fields);
    const obj = {
      ...group,
      fields
    };
    if (group.multiple) {
      multipleGroups.push(group);
      return {
        ...obj,
        groupIndex: multipleGroups.length - 1
      }
    }
    return obj;
  });

  const getFinishedGroupsByIndex = () => {
    return extraFields.length > 0 ? extraFields[currentGroupIndex].filter(f => f.every(_f => _f.value !== null)) : 0;
  }

  const checkGroupFinished = (index, groupIndex) => {
    const finishedGroups = getFinishedGroupsByIndex();
    //console.log('Finished?', finishedGroups);
    console.log(groups);
    console.log('-------- Grupo --------', groups[index].groupName);
    console.log('Grupo anterior simple y sus valores no son null', !groups[index - 1].multiple && groups[index - 1].fields.every(field => field.value !== null));
    console.log('Grupo anterior multiple y ya termino el grupo', groups[index - 1].multiple && finishedGroups.length >= groups[index - 1].min);
    console.log(`Current group is ${currentGroupIndex} and is before other group ${groupIndex} and is filling extra fields`, !fillingExtraFields && groupIndex && currentGroupIndex < groupIndex + 2);
    return (
      !groups[index - 1].multiple && groups[index - 1].fields.every(field => field.value !== null) ||
      !wizard && (groups[index - 1].multiple && finishedGroups.length >= groups[index - 1].min && !fillingExtraFields && groupIndex && currentGroupIndex < groupIndex + 2) ||
      !wizard && fillingExtraFields && currentGroupIndex === groupIndex ||
      wizard && (groups[index - 1].multiple && finishedGroups.length >= groups[index - 1].min)
    );
    /* if ()
    if (groups[index].multiple && finishedGroups.length >= groups[index].min && ) {
      return true;
    } else {
      console.log('Im here? With index', groups[index - 1].groupLabel);
      console.log('Group is finished?', groups[index - 1].fields.every(field => field.value !== null));
      return groups[index - 1].fields.every(field => field.value !== null);
    } */
  }

  const getFilteredFieldsByIndex = (index) => {
    return extraFields[index].filter(f => f.some(_f => _f.value !== null));
  };

  const getFormat = () => {
    const template = Handlebars.compile(quotationScreenOptions.summaryFormat.replaceAll('$', ''));
    console.log(fieldsToTemplate);
    const formattedFields = fieldsToTemplate.map(f => ({ [f.name]: getLabelByType(f) })).reduce((prev, curr) => curr = { ...curr, ...prev }, {});
    console.log('Formatted fields', formattedFields);
    return template(formattedFields);
  };

  console.log('Index', index);
  console.log('Is Wizard?', wizard);
  return (
    <div>
      {(isLast && quotationScreenOptions.summaryFormat) && <div dangerouslySetInnerHTML={{ __html: getFormat() }} />}
      {steps.map((step, i) => (
        !step.multiple ? ((isLast && !quotationScreenOptions.summaryFormat || !wizard) &&
          <QuoteTitle
            key={step._id}
            title={step.groupLabel}
            tags={step.fields}
            handleCloseTag={handleCloseTag}
            show={i === 0 || checkGroupFinished(i)}
            stepIndex={i}
            wizard={wizard}
            readOnly={readOnly}
            itemTitle={step.itemTitle}
            fillingExtraFields={fillingExtraFields} />) : (extraFields.length > 0 ?
              <QuoteTitle
                key={step._id}
                title={step.groupLabel}
                tags={getFilteredFieldsByIndex(step.groupIndex)}
                handleCloseTag={handleCloseTag}
                show={i === 0 || (!wizard ? (i <= index && checkGroupFinished(i, step.groupIndex)) : checkGroupFinished(i, step.groupIndex))}
                multiple={step.multiple}
                handleFillExtraFields={() => handleFillExtraFields(step.groupIndex)}
                fillingExtraFields={fillingExtraFields}
                handleDeleteExtraField={handleDeleteExtraField}
                groupIndex={step.groupIndex}
                max={step.max}
                wizard={wizard}
                readOnly={readOnly}
                itemTitle={step.itemTitle}
                handleDeleteExtraFieldTag={handleDeleteExtraFieldTag}
                stepIndex={i}
                groupPlaceholder={step.groupPlaceholder} /> : null
        )
      ))}
    </div>
  );
};

export default TagsNavigator;
