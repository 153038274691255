import {
  GET_TEXT_COVERAGE_PENDING,
  GET_TEXT_COVERAGE_FULFILLED,
  GET_TEXT_COVERAGE_REJECTED,
  ADD_TEXT_COVERAGE_PENDING,
  ADD_TEXT_COVERAGE_FULFILLED,
  ADD_TEXT_COVERAGE_REJECTED,
  UPDATE_TEXT_COVERAGE_PENDING,
  UPDATE_TEXT_COVERAGE_FULFILLED,
  UPDATE_TEXT_COVERAGE_REJECTED,
  DELETE_TEXT_COVERAGE_PENDING,
  DELETE_TEXT_COVERAGE_FULFILLED,
  DELETE_TEXT_COVERAGE_REJECTED,
} from "../actions/types";

const initialState = {
  data: [],
  filteredData: [],
  loading: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TEXT_COVERAGE_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_TEXT_COVERAGE_FULFILLED:
      return {
        ...state,
        data: action.payload,
        filteredData: action.payload,
        loading: false,
        error: false,
      };
    case GET_TEXT_COVERAGE_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
    case ADD_TEXT_COVERAGE_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ADD_TEXT_COVERAGE_FULFILLED:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false,
        error: false,
      };
    case ADD_TEXT_COVERAGE_REJECTED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case UPDATE_TEXT_COVERAGE_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case UPDATE_TEXT_COVERAGE_FULFILLED:
      const newData = state.data.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload;
        }

        return item;
      });

      return {
        ...state,
        data: newData,
        loading: false,
        error: false,
      };
    case UPDATE_TEXT_COVERAGE_REJECTED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DELETE_TEXT_COVERAGE_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETE_TEXT_COVERAGE_FULFILLED:
      return {
        ...state,
        data: state.data.filter((d) => d._id !== action.payload.id),
        loading: false,
        error: false,
      };
    case DELETE_TEXT_COVERAGE_REJECTED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
