import { createAsyncAction } from 'redux-promise-middleware-actions';
import { GET_LEAD_BY_QUOTATION } from '../actions/types';
import settings from '../settings';

export const getLeadAndQuotationAPI = createAsyncAction(
    'GET_LEAD_AND_QUOTATION',
    async (leadId) => {
        const res = await fetch(`${settings.quotationUrlApi}lead/withQuotation/${leadId}`)
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });

        return res;
    }
);

export const getLeadByQuotationIdAPI = createAsyncAction(
    GET_LEAD_BY_QUOTATION,
    async (quotationId) => {
        const res = await fetch(`${settings.quotationUrlApi}lead/quotation/${quotationId}`)
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });

        return res;
    }
);

export const postLeadAPI = createAsyncAction(
    'POST_LEAD',
    async (bodyReq) => {
        const headers = { 'Content-Type': 'application/json' };
        const body = JSON.stringify(bodyReq);
        const res = await fetch(`${settings.quotationUrlApi}lead/create`,
            { method: 'POST', headers, body })
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });

        return res;
    }
);

export const updateLeadAPI = createAsyncAction(
    'UPDATE_LEAD',
    async (bodyReq) => {
        const headers = { 'Content-Type': 'application/json' };
        const body = JSON.stringify(bodyReq);
        const res = await fetch(`${settings.quotationUrlApi}lead/`,
            { method: 'PUT', headers, body })
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
            console.log("UPDATE_LEAD ",res)
        return res;
    }
);

export const addLeadAPI = async (bodyReq) => {
    const headers = { 'Content-Type': 'application/json' };
    const body = JSON.stringify(bodyReq);
    await fetch(`${settings.quotationUrlApi}lead/create`, { method: 'POST', headers, body })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
};

const validateResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        throw exeptionCodeResponse();
    }
};

const exeptionCodeResponse = (error = 'error') => {
    return error;
};
