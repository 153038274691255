import {
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
} from "../actions/types";

const initialState = {
  error: false,
  message: "",
  type: "",
  open: false,
  duration: 2000,
  variant: "",
  redirect: null,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ERROR_MESSAGE:
      return {
        error: action.payload.show,
        message: action.payload.message,
      };
    case HIDE_ERROR_MESSAGE:
      return {
        error: false,
      };
    // NEW BEHAVIOR REUSABLE
    case SHOW_MESSAGE:
      const { message, type, variant, redirect } = action.payload;
      return {
        ...state,
        open: true,
        message,
        type,
        variant,
        redirect,
      };
    case HIDE_MESSAGE:
      return initialState;
    default:
      return state;
  }
};

export default notificationReducer;
