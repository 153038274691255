import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Snackbar, SnackbarContent, Slide, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { amber } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import { PATCH_YEARS_ERROR, PATCH_BRANDS_ERROR, PATCH_MODELS_ERROR, PATCH_VERSIONS_ERROR, PATCH_QUOTATION_ERROR } 
from '../../actions/types';

const useStyles = makeStyles(theme => ({
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
}

const LayoutError = () => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const [errorMes, setErrorMes] = useState(true);

  const handleClose = () => {
    setErrorMes(false);
    dispatch({ type: PATCH_YEARS_ERROR });
    dispatch({ type: PATCH_BRANDS_ERROR });
    dispatch({ type: PATCH_MODELS_ERROR });
    dispatch({ type: PATCH_VERSIONS_ERROR });
    dispatch({ type: PATCH_QUOTATION_ERROR });
  };

  return (
    <Snackbar
      open={errorMes}
      onClose={handleClose}
      autoHideDuration={5000}
      TransitionComponent={SlideTransition}
      >
        <SnackbarContent
          className={classes.warning}
          message={
            <span className={classes.message}>
              <WarningIcon className={`${classes.icon} ${classes.iconVariant}`} />
              Ha ocurrido un error en la conexión!
            </span>
          }
          action={[
            <IconButton key="close" color="inherit" onClick={handleClose}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        />
    </Snackbar>
  );
};

export default LayoutError;
