import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Card, CardActions, CardActionArea, CardContent, Typography } from '@material-ui/core';
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';
import moment from 'moment';

import { getInsurance } from '../../actions/insuranceActions';
import { CarImage, HouseImage, FamilyImage } from '../../assets/customIcons';

import Carousel from '@brainhubeu/react-carousel';

const useStyles = makeStyles(theme => ({
    cardService: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '336px',
        width: '310px',
        margin: '10px 20px',
        padding: '10px 5px',
        [theme.breakpoints.down('sm')]: {
            height: '360px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '360px'
        }
    },
    cardActionArea: {
        height: '265px'
    },
    cardText: {
        fontFamily: '\'Roboto\', sans-serif',
        color: '#4B4B4B',
        padding: 8,
        marginTop: 2,
        marginBottom: 12,
        fontSize: 16,
        letterSpacing: '0.25px',
        lineHeight: '20px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            height: '50px',
            margin: '0',
            padding: '0px 8px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '50px',
            margin: '0',
            padding: '0px 8px'
        }
    },
    cardContainerTitle: {
        color: theme.palette.primary.dark,
        textAlign: 'center',
        padding: '0px 16px'
    },
    cardTitle: {
        color: theme.palette.secondary.main,
        textAlign: 'center',
        fontFamily: 'Museo Sans , Fallback, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '24px',
        lineHeight: '24px',
        margin: '0px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '48px'
        }
    },
    containerCards: {
        paddingLeft: 128,
        paddingRight: 128,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 16,
            paddingRight: 16
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 8,
            paddingRight: 8,
            flexWrap: 'nowrap',
            justifyContent: 'inherit',
            overflow: 'scroll'
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 8,
            paddingRight: 8,
            flexWrap: 'nowrap',
            justifyContent: 'inherit',
            overflow: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none'
            }
        }
    },
    containerCarousel: {
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            overflowX: 'scroll'
        },
        '& .BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected': {
            backgroundColor: `${theme.palette.primary.main} !important`
        }
    },
    containerCardButton: {
        textAlign: 'center',
        width: '100%'
    },
    media: {
        width: '195px',
        objectFit: 'fill',
        padding: 7,
        height: '146px'
    },
    mediaCard: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px'
    },
    cardGrid: {
        padding: '16px',
        paddingBottom: '0px',
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block',
            position: 'relative'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'inline-block',
            position: 'relative'
        }
    },
    svgIcon: {
        color: theme.palette.primary.main,
        width: 'auto',
        height: 'auto'
    }
}));

const CardGrid = () => {
    const classes = useStyles();
    const insurances = useSelector(state => state.insurance);

    const { data } = useSelector((state) => state.config);
    let colorPrimary = '';
    let colorSecondary = '';
    let colorSecondaryVariant = '';

    if (data.theme) {
        colorPrimary = data.theme.primary.main;
        colorSecondary = data.theme.secondary.main;
        colorSecondaryVariant = data.theme.secondary.variant5;
    }

    const dispatch = useDispatch();
    useEffect(() => {
        initInsuranceGrid();
    }, []);
    const initInsuranceGrid = () => {
        dispatch(getInsurance());
    };

    const getImageByType = (type) => {
        switch (type) {
            case 'AUTOS':
                return <CarImage
                    colorPrimary={colorPrimary}
                    colorSecondary={colorSecondary}
                    colorSecondaryVariant={colorSecondaryVariant}
                    classes={{ root: classes.svgIcon }}
                />;
            case 'HOGAR':
                return <HouseImage
                    colorPrimary={colorPrimary}
                    colorSecondary={colorSecondary}
                    colorSecondaryVariant={colorSecondaryVariant}
                    classes={{ root: classes.svgIcon }}
                />;
            default:
                return <FamilyImage
                    colorPrimary={colorPrimary}
                    colorSecondary={colorSecondary}
                    colorSecondaryVariant={colorSecondaryVariant}
                    classes={{ root: classes.svgIcon }}
                />;
        }
    };

    const qtyItemsByScreenWidth = () => {
        const width = window.innerWidth;
        let qty = 3;
        if (width < 1000 && width > 780) {
            qty = 2;
        }
        if (width < 779) {
            qty = 1;
        }
        return qty;
    };

    const validInsurances = insurances.data.filter(
        (insurance) => {
            const wasReleased = moment(insurance.startDate).isBefore(moment());
            let isExpired;
            if (insurance.cancellationDate) {
                isExpired = moment(insurance.cancellationDate).isBefore(moment());
            } else {
                isExpired = false;
            }
            return wasReleased && !isExpired;
        }
    );
    return (
        <div className={classes.containerCarousel}>
            <Grid container justify="space-around" alignItems="center" className={classes.containerCards}>
                {insurances.data.length > 0 &&
                    <Carousel
                        addArrowClickHandler={true}
                        arrowRight={validInsurances.length > qtyItemsByScreenWidth() ? (<div className="arrowRight"><ArrowForwardIos /></div>) : null}
                        arrowLeft={validInsurances.length > qtyItemsByScreenWidth() ? (<div className="arrowLeft"><ArrowBackIos /></div>) : null}
                        infinite={validInsurances.length > qtyItemsByScreenWidth()}
                        dots
                        animationSpeed={2000}
                        slidesPerPage={validInsurances.length > qtyItemsByScreenWidth() ? qtyItemsByScreenWidth() : validInsurances.length}
                    >
                        {validInsurances.map(insurance => (
                            <Card key={insurance._id} className={classes.cardService}>
                                <CardActionArea className={classes.cardActionArea}>
                                    <Link to={`/quote/${insurance.riskType.toLowerCase()}/${insurance.quotationScreenId}/${insurance._id}/${insurance.formBuilderId}/info`}>
                                        <div className={classes.mediaCard}>
                                            {getImageByType(insurance.riskType)}
                                        </div>
                                    </Link>
                                    <CardContent justify="center" className={classes.cardContainerTitle}>
                                        <Typography variant="h5" className={classes.cardTitle}>
                                            {insurance.businessTitle}
                                        </Typography>
                                        <p className={classes.cardText}>
                                            {insurance.businessName}
                                        </p>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions style={{ padding: 0 }} >
                                    <div className={classes.containerCardButton}>
                                        <Link to={`/quote/${insurance.riskType.toLowerCase()}/${insurance.quotationScreenId}/${insurance._id}/${insurance.formBuilderId}/info`}>
                                            <Button variant="contained" className="cardButton" color="primary">
                                                Cotizar
                                            </Button>
                                        </Link>
                                    </div>
                                </CardActions>
                            </Card>
                        ))}
                    </Carousel>}
            </Grid>
        </div>
    );
};

export default CardGrid;
