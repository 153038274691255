import {
    POST_LEAD_PENDING,
    POST_LEAD_FULFILLED,
    POST_LEAD_REJECTED,
    UPDATE_LEAD_PENDING,
    UPDATE_LEAD_FULFILLED,
    UPDATE_LEAD_REJECTED,
    CLEAN_LEAD,
    GET_LEAD_AND_QUOTATION_PENDING,
    GET_LEAD_AND_QUOTATION_FULFILLED,
    GET_LEAD_AND_QUOTATION_REJECTED,
    ADD_TRACKING_LEAD,
    GET_LEAD_BY_QUOTATION,
} from "../actions/types";
import { toTitleCase } from "../utils";

const initialState = {
    data: {},
    loading: false,
    error: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LEAD_AND_QUOTATION_FULFILLED:
            const { lead } = action.payload;
            return {
                ...state,
                data: lead,
                loading: false,
                error: false,
            };
        case GET_LEAD_AND_QUOTATION_PENDING:
        case POST_LEAD_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case POST_LEAD_FULFILLED:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
            };
        case GET_LEAD_AND_QUOTATION_REJECTED:
        case POST_LEAD_REJECTED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case UPDATE_LEAD_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case UPDATE_LEAD_FULFILLED:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
            };
        case UPDATE_LEAD_REJECTED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case ADD_TRACKING_LEAD:
            const { riskType, message } = action.payload;
            const trackingData = `[${new Date()
                .toTimeString()
                .slice(0, 5)}] - ${toTitleCase(
                riskType || state.data.riskType
            )}: ${message}`;

            let finalTrack = [];

            const { tracking } = state.data;

            if (tracking && tracking.length) {
                const values = tracking
                    .split(",")
                    .filter((track) => track && track);
                finalTrack = [...(values && values.length && values)];
                finalTrack.push(trackingData);
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    tracking: finalTrack.toString(),
                },
            };

        case `${GET_LEAD_BY_QUOTATION}_PENDING`:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case `${GET_LEAD_BY_QUOTATION}_FULFILLED`:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
            };
        case `${GET_LEAD_BY_QUOTATION}_REJECTED`:
            return {
                ...state,
                loading: false,
                error: true,
            };

        case CLEAN_LEAD:
            return {
                ...state,
                data: {},
            };
        default:
            return state;
    }
}
