const Utils = {
    pagaditoPaymentStatus:{
        REGISTERED: "La transacción ha sido registrada correctamente en Pagadito, pero aún se encuentra en proceso. En este punto, el cobro aún no ha sido realizado.",
        COMPLETED: "El pago ha sido procesado correctamente en Pagadito.",
        VERIFYING: "La transacción ha sido procesada en Pagadito, pero ha quedado en verificación. En este punto el cobro ha quedado en validación administrativa. Posteriormente, la transacción puede marcarse como válida o denegada; por lo que se debe monitorear hasta que su estado cambie a COMPLETADO o REVOCADO.",
        REVOKED: "La transacción en estado VERIFICACION ha sido denegada por Pagadito. En este punto el cobro ya ha sido cancelado.",
        CANCELED: "La transacción ha sido cancelada por el usuario en Pagadito.",
        EXPIRED: "La transacción ha expirado en Pagadito, la transacción tiene este estado cuando se termina el tiempo para completar la transacción por parte del usuario en Pagadito."
    },
    pagaditoPaymentPassedStatus:{
        REGISTERED: false,
        COMPLETED: true,
        VERIFYING: true,
        REVOKED: false,
        CANCELED: false,
        EXPIRED: false
    }  
};

export default Utils;
