import {
    postLeadAPI,
    updateLeadAPI,
    addLeadAPI,
    getLeadAndQuotationAPI,
    getLeadByQuotationIdAPI
} from '../sevices/leadService';
import { ADD_TRACKING_LEAD, CLEAN_LEAD, REMOVE_ALL_QUOTE } from './types';

export const getLeadAndQuotation = (leadId) => async (dispatch) => {
    dispatch({ type: CLEAN_LEAD });
    dispatch({ type: REMOVE_ALL_QUOTE });
    await dispatch(getLeadAndQuotationAPI(leadId));
};

export const getLeadByQuotationId = (quotationId) => async (dispatch) => {
    await dispatch(getLeadByQuotationIdAPI(quotationId));
};

export const postLead = (lead) => async (dispatch) => {
    dispatch({ type: CLEAN_LEAD });
    await dispatch(postLeadAPI(lead));
};

export const updateLead = (body) => async (dispatch) => {
    await dispatch(updateLeadAPI(body));
};

export const addTrackingLead =
    (message, riskType, statusLead, lead, newUrl = '') =>
        async (dispatch, getState) => {
            dispatch({
                type: ADD_TRACKING_LEAD,
                payload: { riskType, message }
            });
            const dataTracking = getState().lead.data.tracking || '';
            dispatch(
                updateLead({
                    ...lead,
                    url: newUrl || lead.url,
                    status: statusLead,
                    tracking: dataTracking
                        .split(',')
                        .filter((track) => track && track)
                        .toString()
                })
            );
        };

export const addLead = (body) => {
    addLeadAPI(body);
};

export const resetLead = (lead) => async (dispatch) => {
    dispatch({ type: CLEAN_LEAD });
};
