import {
    GET_CLIENT_INFO_PENDING,
    GET_CLIENT_INFO_FULFILLED,
    GET_CLIENT_INFO_REJECTED,
    SAVE_CLIENT_RECOGNITION_INFO,
    RESET_CLIENT_INFO
} from '../actions/types';

const initialState = {
    data: {},
    loading: false,
    error: false,
    recognitionData: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CLIENT_INFO_PENDING:
            return {
                ...state,
                loading: true,
                error: false
            };
        case GET_CLIENT_INFO_FULFILLED: {
            const getFormattedData = (data) => {
                const $data = {};
                if (data) {
                    for (const i of data) {
                        for (const key in i) $data[key] = i[key];
                    }
                }

                return $data;
            }
            return {
                ...state,
                data: getFormattedData(action.payload.data),
                loading: false,
                error: false
            };
        }
        case GET_CLIENT_INFO_REJECTED:
            return {
                ...state,
                data: {},
                loading: false,
                error: true
            };
        case SAVE_CLIENT_RECOGNITION_INFO:
            return {
                ...state,
                recognitionData: action.payload
            };
        case RESET_CLIENT_INFO:
            return initialState;
        default:
            return state;
    }
}
