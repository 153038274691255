import { createAsyncAction } from 'redux-promise-middleware-actions';
import settings from '../settings';

export const getInsuranceAPI = createAsyncAction(
    'GET_INSURANCE',
    async (token) => {
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
 
        const res = await fetch(`${settings.quotationUrlApi}insurance/?channel=channelPublic`, { headers })
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

export const getInsuranceByIdAPI = createAsyncAction(
    'GET_INSURANCE_ID',
    async (Id, token) => {
        const headers = { Authorization: `Bearer ${token}` };

        const res = await fetch(`${settings.quotationUrlApi}insurance/${Id}`, {
            headers
        })
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return [res];
    }
);

const validateResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        throw exeptionCodeResponse();
    }
};

const exeptionCodeResponse = (error = 'error') => {
    return error;
};
