import { getClientInfoAPI } from '../sevices/clientInfoService';
import { RESET_CLIENT_INFO, SAVE_CLIENT_RECOGNITION_INFO } from './types';

export const getClientInfo = (quotationId) => async (dispatch) => {
    await dispatch(getClientInfoAPI(quotationId));
};

export const saveClientRecognitionInfo = (info) => ({
    type: SAVE_CLIENT_RECOGNITION_INFO,
    payload: info
});

export const resetClientInfo = () => ({
    type: RESET_CLIENT_INFO
});
