import { useState } from 'react';

export default function () {
  const [groupIndex, setGroupIndex] = useState(0);
  const [fieldIndex, setFieldIndex] = useState(0);

  const changeIndex = (group) => {
    if (group.fields.length - 1 === fieldIndex) {
      setGroupIndex(groupIndex + 1);
      setFieldIndex(0);
    } else setFieldIndex(fieldIndex + 1);
  }

  const changeIndexByTag = (fieldIndexTag, groupIndexTag) => {
    setGroupIndex(groupIndexTag);
    setFieldIndex(fieldIndexTag);
  }

  return ({
    groupIndex,
    fieldIndex,
    changeIndex,
    changeIndexByTag
  })
};
