import React, { useEffect, useState } from 'react';
import { List, ListItem, ListSubheader, ListItemText, TextField, InputAdornment, Tooltip, IconButton, InputLabel } from '@material-ui/core';
import { useInputValue } from '../../../hooks/useInputValue';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ArrowForwardIos, Input } from '@material-ui/icons';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  listItem: {
    color: theme.palette.primary.dark,
    '&:hover': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down("sm")]: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark
      }
    }
  },
  search: {
    marginBottom: '35px'
  },
  input: {
    marginTop: '2rem !important'
  },
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: 16,
    marginBottom: 18,
    '& svg': {
      marginRight: 6,
      fontSize: 16
    },
    color: theme.palette.typography.main,
    '& label': {
      overflow: 'unset',
      maxWidth: '100%',
      whiteSpace: 'break-spaces'
    }
  }
}));

const QuoteListString = ({
  data,
  handleSelect,
  label,
  isOptional,
  isSearchable,
  quotationScreenOptions,
  handlePreSelect,
  preSelectedValue,
  handleNextButtonValid
}) => {
  const classes = useStyles();
  const [value, setValue] = useState('')
  const config = useSelector(state => state.config.data);

  useEffect(() => {
    if (isOptional) {
      if (quotationScreenOptions.nextButtonType === 'ext') {
        handleNextButtonValid(true);
      }
    }
  }, []);

  const dataList = () => {
    let _data = data;
    if (_data.length > 2) {
      _data = _data.sort(compare);
    }
    if (value !== '') {
      return _data.filter(item => {
        if (item.name) {
          return item.name.toString().toUpperCase().indexOf(value.toUpperCase()) > -1;
        }
        return item.toString().toUpperCase().indexOf(value.toUpperCase()) > -1;
      })
    }

    return _data.some(item => item.main) ? _data.filter(item => item.main) : _data;
  }

  const compare = (a, b) => {
    if (a.name) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    } else {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      // a must be equal to b
      return 0;
    }
  }

  const handleChange = (item) => {
    if (item.name) {
      quotationScreenOptions.nextButtonType === 'int' ? handleSelect(item._id) : handlePreSelect(item._id);
    } else {
      quotationScreenOptions.nextButtonType === 'int' ? handleSelect(item) : handlePreSelect(item);
    }
    setValue('');
    if (quotationScreenOptions.nextButtonType === 'ext') {
      handleNextButtonValid(true);
    }
  }

  const handleChangeValue = (evt) => {
    setValue(evt.target.value);
  }

  console.log('DataList length', dataList().length);

  return (
    <List
      component="nav"
      className="quoteList"
      subheader={
        <ListSubheader className={classes.search} component="h2">
          {(config.theme && !config.theme.labelPosition) && (
            <div className={classes.label}>
              <ArrowForwardIos color="primary" />
              <InputLabel id="external-input-label">{label}</InputLabel>
            </div>)}
          <TextField
            label={(config.theme && config.theme.labelPosition) ? label : null}
            fullWidth
            color="primary"
            value={value}
            onChange={handleChangeValue}
            InputProps={{
              endAdornment: (isOptional && quotationScreenOptions.nextButtonType === 'int') && (
                <InputAdornment position="end">
                  <Tooltip title={'Siguiente'} arrow open placement="top">
                    <IconButton
                      aria-label="check input value"
                      onClick={() => handleSelect('0')}
                      style={{
                        color: config.theme.primary.main
                      }}
                    >
                      <Input />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              readOnly: !isSearchable,
              disabled: !isSearchable,
              classes: {
                root: (config.theme && config.theme.labelPosition) ? classes.input : null
              },
              margin: 'dense'
            }}
          />
        </ListSubheader>
      }
    >
      {
        dataList().map((item, index) => {
          return (
            <ListItem selected={item.name ? item._id === preSelectedValue : item === preSelectedValue} className={classes.listItem} button key={index} onClick={() => handleChange(item)}>
              <ListItemText primary={item.name ? item.name : item} />
            </ListItem>
          )
        })
      }
    </List>
  );
};

export default QuoteListString;
