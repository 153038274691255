import {
  GET_PAYMENT_LINK_PENDING,
  GET_PAYMENT_LINK_FULFILLED,
  GET_PAYMENT_LINK_REJECTED,
  GET_SINGLE_PAYMENT_LINK_REJECTED,
  GET_SINGLE_PAYMENT_LINK_PENDING,
  GET_SINGLE_PAYMENT_LINK_FULFILLED
} from "../actions/types";

const initialState = {
  data: [],
  dataById: [],
  filteredData: [],
  loading: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENT_LINK_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_PAYMENT_LINK_FULFILLED:
      return {
        ...state,
        data: action.payload,//state.data.length > 0 ? [...state.data, ...JSON.parse(action.payload)] : JSON.parse(action.payload),
        loading: false,
        error: false,
      };
    case GET_PAYMENT_LINK_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };

    case GET_SINGLE_PAYMENT_LINK_REJECTED:
      return {
        ...state,
        dataById: [],
        loading: false,
        error: true,
      };

    case GET_SINGLE_PAYMENT_LINK_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_SINGLE_PAYMENT_LINK_FULFILLED:
      return {
        ...state,
        dataById: [action.payload],
        loading: false,
        error: false,
      };

    default:
      return state;
  }
}
