import React, { useEffect } from 'react'
import usePaymentInputs from 'react-payment-inputs/es/usePaymentInputs';
import PaymentInputsWrapper from 'react-payment-inputs/es/PaymentInputsWrapper';
import images from 'react-payment-inputs/images';

const CreditCardField = ({
  creditError,
  creditCardNumber,
  creditCardExpiryDate,
  creditCardCVC,
  handleChange
}) => {
  const ERROR_MESSAGES = {
    emptyCardNumber: 'El número de la tarjeta es inválido',
    invalidCardNumber: 'El número de la tarjeta es inválido',
    emptyExpiryDate: 'La fecha de expiración es inválida',
    monthOutOfRange: 'El mes de expiración debe estar entre 01 y 12',
    yearOutOfRange: 'El año de expiración no puede estar en el pasado',
    dateOutOfRange: 'La fecha de expiración no puede estar en el pasado',
    invalidExpiryDate: 'La fecha de expiración es inválida',
    emptyCVC: 'El código de seguridad es inválido',
    invalidCVC: 'El código de seguridad es inválido'
  };

  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    meta
  } = usePaymentInputs({ errorMessages: ERROR_MESSAGES });

  useEffect(() => {
    creditError(meta.error);
  }, [meta.error])
  return (
    <PaymentInputsWrapper {...wrapperProps}>
      <svg {...getCardImageProps({ images })} />
      <input {...getCardNumberProps({ onChange: handleChange })} value={creditCardNumber} />
      <input {...getExpiryDateProps({ onChange: handleChange })} value={creditCardExpiryDate} />
      <input {...getCVCProps({ onChange: handleChange })} value={creditCardCVC} />
    </PaymentInputsWrapper>
  );
};

export default CreditCardField;