import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Modal, Backdrop, Fade, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactHtmlParser from "react-html-parser";
import IconAsk from "../icons/Ask";
import { getTextCoverage } from "../../actions/textCoverageActions";
import LoadingModal from "../../components/layout/layoutLoading";

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    fontWeight: "500",
    cursor: "pointer",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "90vh",
    width: "80vh",
    overflow: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "35vh",
    },
  },
  button: {
    width: "100%",
  },
}));

const ModalCoverage = ({ coverage }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const textCoverage = useSelector((state) => state.textCoverage);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    executeTextCoverage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const executeTextCoverage = () => {
    dispatch(getTextCoverage({ coverageId: coverage.coverageId }));
  };

  const handleModal = () => {
    setOpen(!open);
  };

  const handleSave = async () => {
    setOpen(!open);
  };

  return (
    <div>
      <IconAsk onClick={() => handleModal()} />
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 className="textTag">{coverage.name}</h2>
            {textCoverage.loading ? (
              <LoadingModal />
            ) : (
              ReactHtmlParser(textCoverage.data.content)
            )}

            <Grid container justify="flex-end" style={{ marginTop: "30px" }}>
              <label
                variant="contained"
                className={classes.label}
                onClick={() => handleSave()}
              >
                ACEPTAR
              </label>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalCoverage;
