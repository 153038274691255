import React, { useState } from 'react';
import { Link } from '@reach/router';
import {
    Grid,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Hidden
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as TwitterIcon } from '../../assets/images/icons/twitter_icon_white.svg';
import { ReactComponent as FacebookIcon } from '../../assets/images/icons/facebook_icon_white.svg';
import { ReactComponent as InstagramIcon } from '../../assets/images/icons/instagram_icon_white.svg';
import { ReactComponent as MiniMailIcon } from '../../assets/images/icons/mini_mail_icon.svg';
import ModalSchedule from './modalSchedule';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingRight: 20,
        textAlign: 'end'
    },
    drawer: {
        backgroundColor: `${theme.palette.secondary.main} !important`
    },
    icon: {
        marginRight: 16,
        '& path': {
            fill: theme.palette.primary.main
        }
    },
    iconContainer: {
        marginLeft: 64,
        marginTop: 50
    },
    linkContact: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontSize: 14,
        fontWeight: 500,
        position: 'relative'
    },
    linkContactMobile: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontSize: 14,
        fontWeight: 500,
        position: 'relative',
        right: 17
    },
    iconBurguer: {
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        fontWeight: 500,
        '& svg': {
            fontSize: 30,
            position: 'relative',
            right: 10
        }
    },
    listContainer: {
        backgroundColor: theme.palette.secondary.main,
        paddingTop: 32,
        marginBottom: 6
    },
    list: {
        color: '#FFFFFF',
        textTransform: 'uppercase'
    },
    listItem: {
        border: '0 !important',
        color: '#FFFFFF',
        fontSize: '0.9rem !important',
        marginRight: 64,
        paddingLeft: 64
    },
    title: {
        color: '#FFFFFF',
        fontSize: '1.8rem',
        marginBottom: 50,
        marginLeft: 64
    }
}));

const ModalContact = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openSchedule, setOpenSchedule] = useState(false);

    const handleContact = () => {
        setOpen(!open);
    };

    const hanldeSchedule = () => {
        setOpenSchedule(!openSchedule);
    };

    return (
        <Grid container alignItems="center">
            <Hidden smDown>
                <Grid
                    item
                    md={4}
                    className={classes.linkContact}
                    style={{ marginTop: -2 }}
                >
                    <div onClick={hanldeSchedule}>
                        <MiniMailIcon />
                    </div>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Grid item xs={6} className={classes.linkContactMobile}>
                    <div onClick={hanldeSchedule}>
                        <MiniMailIcon />
                    </div>
                </Grid>
            </Hidden>
            <Grid
                item
                xs={6}
                md={4}
                onClick={handleContact}
                className={classes.iconBurguer}
            >
                <Menu />
            </Grid>
            <Drawer anchor="right" open={open} onClose={handleContact} classes={{
                paper: classes.drawer
            }}>
                <div
                    className={classes.listContainer}
                    role="presentation"
                    onClick={handleContact}
                    onKeyDown={handleContact}
                >
                    <Link to="/">
                        <div className={classes.title}>iConnectance</div>
                    </Link>
                    <List className={classes.list}>
                        <Link to="/#why-are-unique">
                            <ListItem className={classes.listItem} button>
                                <ListItemText primary="¿Porque somos únicos?" />
                            </ListItem>
                        </Link>
                        <Link to="/faq">
                            <ListItem className={classes.listItem} button>
                                <ListItemText primary="Respuesta a las dudas" />
                            </ListItem>
                        </Link>
                        <Link to="/#newsletter">
                            <ListItem className={classes.listItem} button>
                                <ListItemText primary="Noticias" />
                            </ListItem>
                        </Link>
                        <Link to="/refer">
                            <ListItem className={classes.listItem} button>
                                <ListItemText primary="Conocenos" />
                            </ListItem>
                        </Link>
                        <ListItem
                            className={classes.listItem}
                            onClick={hanldeSchedule}
                            button
                        >
                            <ListItemText primary="Contactanos" />
                        </ListItem>
                        <ListItem className={classes.listItem} button>
                            <ListItemText primary="Iniciar sesión" />
                        </ListItem>
                    </List>
                    <div className={classes.iconContainer}>
                        <a
                            href="https://twitter.com/iconnectance"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <TwitterIcon className={classes.icon} />
                        </a>
                        <a
                            href="https://www.facebook.com/iconnectance"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <FacebookIcon className={classes.icon} />
                        </a>
                        <a
                            href="https://instagram.com/iconnectance"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <InstagramIcon className={classes.icon} />
                        </a>
                    </div>
                </div>
            </Drawer>
            <ModalSchedule open={openSchedule} handleSchedule={hanldeSchedule} />
        </Grid>
    );
};

export default ModalContact;
