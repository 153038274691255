import { AUTOS, GASTOS_MEDICOS, HOGAR, SALUD, VIDA } from '../utils/risksTypes';
import { useSelector } from 'react-redux';

export const useQuote = (riskType) => {
    /* const quoteCar = useSelector(state => state.quote);
    const quoteHome = useSelector(state => state.quoteHome);
    const quoteLife = useSelector(state => state.quoteLife);
    const quoteHealth = useSelector(state => state.quoteHealth);
    const quoteMedicalExpenses = useSelector(state => state.quoteMedicalExpenses); */
    const quote = useSelector(state => state.quoteAllPlans);

    /* switch (riskType) {
        case AUTOS:
            return quoteCar;
        case HOGAR:
            return quoteHome;
        case VIDA:
            return quoteLife;
        case SALUD:
            return quoteHealth;
        case GASTOS_MEDICOS:
            return quoteMedicalExpenses;
        default:
            return quote;
    } */
    return quote;
};
